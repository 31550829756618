import { createModel } from '@rematch/core';

export interface IUiState {
  snackbar: {
    open: boolean;
    message: string;
    variant: 'info' | 'error' | 'warning' | 'success';
  };
  isLoading: boolean;
}

export const uiState = createModel<IUiState>({
  state: {
    snackbar: {
      open: false,
      message: '',
      variant: 'info',
    },
    isLoading: false,
  },

  reducers: {
    setSnackbar: (state, payload = {}) => ({
      ...state,
      snackbar: { ...state.snackbar, ...payload },
    }),
    setLoading: (state, payload = {}) => ({
      ...state,
      isLoading: payload,
    }),
  },
});
