import * as React from 'react';
import {
  ComparisonPage,
  ComparisonPageProps,
} from '../../../newReport/components/ComparisonPage/ComparisonPage';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface WebComparisonSectionProps extends ComparisonPageProps {}

const useStyle = makeStyles((theme: Theme) => ({
  description: {
    fontSize: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
  circleTitle: {
    fontSize: 18,
  },
}));

export const WebComparisonSection: React.FC<WebComparisonSectionProps> = props => {
  const style = useStyle();
  const { ...otherProps } = props;
  return <ComparisonPage {...otherProps} showWebHeader={true} />;
};
