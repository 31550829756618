import { useState, useRef } from 'react';
import { useFirestoreConnect, isLoaded, useFirebase } from 'react-redux-firebase';
import { useSelector, shallowEqual } from 'react-redux';
import {
  INominees,
  IProject,
  IProjectPerson,
  IProjectRating,
  IProjectQuestion,
  IProjectCompetency,
  IQuestionResponse,
  ICompetencyScore,
  IProjectPersonInfo,
  IGroupResult,
} from '@feedr/types';
import { IStore } from 'apps/feedr-react/src/app/store';
import { Timestamp } from '@google-cloud/firestore';
import { competencyScoreCalculator, i18n } from '@feedr/helpers';
import { useEffectAsync } from '../../../../../../utils/utilities';
import { StringParam, useQueryParam } from 'use-query-params';

const useReportState = (rateeEmail: string, projectId: string) => {
  const [language] = useQueryParam('language', StringParam);
  const firebase = useFirebase();
  useFirestoreConnect(() => [
    {
      collection: 'projects',
      doc: projectId,
      storeAs: `projects/${projectId}`,
    },
    {
      collection: 'nominees',
      where: [
        ['projectId', '==', projectId],
        ['ratee', '==', rateeEmail],
      ],
      storeAs: `projects/${projectId}/ratee/${rateeEmail}/nominees`,
    },
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'competencies' }],
      storeAs: `projects/${projectId}/competencies`,
    },
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'questions' }],
      storeAs: `projects/${projectId}/questions`,
    },
    {
      collection: 'descriptions',
      where: ['projectIds', 'array-contains', projectId],
      storeAs: `descriptions/${projectId}`,
    },
    {
      collection: 'feedbacks',
      where: [
        ['projectId', '==', projectId],
        ['ratee', '==', rateeEmail],
      ],
      storeAs: `projects/${projectId}/ratee/${rateeEmail}/feedbacks`,
    },
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'results', doc: '--group--' }],
      storeAs: `projects/${projectId}/results/group`,
    },
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'people-info' }],
      where: ['status', '==', 'CLOSED'],
      storeAs: `projects/${projectId}/people-info`,
    },
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'people-info', doc: rateeEmail }],
      storeAs: `projects/${projectId}/people-info/${rateeEmail}`,
    },
  ]);

  const project = useSelector<IStore, IProject>(
    state => state.firestore.ordered[`projects/${projectId}`]?.[0],
  );
  // need collection to check isLoaded
  const groupResults = useSelector<IStore, IGroupResult[]>(
    state => state.firestore.ordered[`projects/${projectId}/results/group`],
  );
  const groupResult = groupResults?.[0];

  const { person, closedPeopleEmails, ratings, ratingScale, peopleInfo } = useSelector<
    IStore,
    {
      person: IProjectPerson;
      closedPeopleEmails: string[];
      ratings: { [key: string]: IProjectRating };
      ratingScale: number;
      peopleInfo: IProjectPersonInfo[];
    }
  >(state => {
    const project = state.firestore.ordered[`projects/${projectId}`]?.[0] as IProject;
    const peopleInfo = state.firestore.ordered[
      `projects/${projectId}/people-info`
    ] as IProjectPersonInfo[];

    const people = (project?.people || []).map(person => ({
      ...person,
      status: peopleInfo?.find(x => x.id === person.email)?.status || person.status,
    }));
    const person = people.find(person => person.email === rateeEmail);
    const closedPeopleEmails = people
      .filter(person => person.status === 'CLOSED')
      .map(person => person.email);

    const ratings = project?.ratings || {};
    const ratingScale = Math.max(...Object.keys(ratings).map(rating => Number(rating)));

    return { person, closedPeopleEmails, ratings, ratingScale, peopleInfo };
  }, shallowEqual);

  const questions = useSelector<IStore, IQuestionResponse[]>(state => {
    const flatFeedbacks = state.firestore.ordered[
      `projects/${projectId}/ratee/${rateeEmail}/feedbacks`
    ]?.filter(
      feedback => feedback.questionId && closedPeopleEmails.includes(feedback.rater),
    );
    return (
      (state.firestore.ordered[`projects/${projectId}/questions`] || [])
        .sort((a: IProjectQuestion, b: IProjectQuestion) => a.orderIndex - b.orderIndex)
        .map((question: IProjectQuestion) => ({
          ...question,
          feedbacks:
            flatFeedbacks?.filter(x => x.questionId === question.id && x.answer) ?? [],
        })) ?? []
    );
  }, shallowEqual);

  const {
    competencies,
    scores,
    startDate,
    lastUpdate,
    feedbackProviderCount,
    isScoreLoaded,
    nomineesByRole,
  } = useSelector<
    IStore,
    {
      competencies: IProjectCompetency[];
      scores: ICompetencyScore[];
      startDate: Timestamp;
      lastUpdate: Timestamp;
      feedbackProviderCount: number;
      isScoreLoaded: boolean;
      nomineesByRole: {
        [role: string]: string[];
      };
    }
  >(state => {
    // filtering out non-completed memebers ratings
    const flatFeedbacks = state.firestore.ordered[
      `projects/${projectId}/ratee/${rateeEmail}/feedbacks`
    ]?.filter(
      feedback => feedback.competencyId && closedPeopleEmails.includes(feedback.rater),
    );

    const competencies = state.firestore.ordered[`projects/${projectId}/competencies`];
    const descriptions = state.firestore.ordered[`descriptions/${projectId}`];
    const nominees =
      state.firestore.ordered[`projects/${projectId}/ratee/${rateeEmail}/nominees`];
    const feedbackProviderCount = nominees?.length || 0;
    const nomineesByRole = nominees?.reduce(
      (acc, cur: INominees) => ({
        ...acc,
        [cur.role]: [...(acc[cur.role] ?? []), cur.email],
      }),
      {},
    );
    const startDate =
      flatFeedbacks
        ?.map(x => x.createdAt)
        .filter(x => x)
        .sort()[0] || null;
    const lastUpdate =
      flatFeedbacks
        ?.map(x => x.updatedAt)
        .filter(x => x)
        .sort()
        .reverse()[0] || null;

    const scores = competencyScoreCalculator(
      flatFeedbacks,
      rateeEmail,
      competencies,
      descriptions,
      nomineesByRole,
      groupResult,
      project?.minReqRaters ?? null,
    );

    const isScoreLoaded = isLoaded(flatFeedbacks);

    return {
      competencies,
      scores,
      startDate,
      lastUpdate,
      feedbackProviderCount,
      isScoreLoaded,
      nomineesByRole,
    };
  }, shallowEqual);

  const isAllLoaded =
    isLoaded(person) && isLoaded(peopleInfo) && isLoaded(groupResults) && isScoreLoaded;
  const [projectScoresRefreshed, setProjectScoresRefreshed] = useState(false);
  useEffectAsync(async () => {
    // set language
    await i18n.changeLanguage(language);

    if (!projectScoresRefreshed && isAllLoaded) {
      if (groupResults.length === 0) {
        // @ts-ignore
        await firebase.functions().httpsCallable('calculateProjectScores')({
          projectId: projectId,
        });
      }
      setProjectScoresRefreshed(true);
    }
  }, [isAllLoaded]);

  const { current } = useRef({});
  const appBarRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (ev, value) => {
    setSelectedTab(value);
    window.scrollTo({
      top:
        current[value === competencies.length ? 'questions' : competencies[value].id]
          ?.offsetTop -
        appBarRef.current?.offsetHeight -
        14,
      behavior: 'smooth',
    });
  };

  return {
    person,
    closedPeopleEmails,
    ratings,
    ratingScale,
    questions,
    competencies,
    scores,
    startDate,
    lastUpdate,
    feedbackProviderCount,
    current,
    appBarRef,
    selectedTab,
    setSelectedTab,
    handleChangeTab,
    project,
    isAllLoaded,
    nomineesByRole,
  };
};
export default useReportState;
