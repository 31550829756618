import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

interface WebReportContainerProps {}

const useStyle = makeStyles((theme: Theme) => ({
  webReportContainer: {
    width: `100%`,
    height: `100%`,
  },
}));

export const WebReportContainer: React.FC<WebReportContainerProps> = props => {
  const style = useStyle();

  return (
    <div className={`${style.webReportContainer}  w-100 px-4 py-4`}>{props.children}</div>
  );
};
