import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { memo } from 'react';
import { CompareScaleLine } from '../CompareScaleLine/CompareScaleLine';
import { roundToFixedDecimal } from '../../../../../../../../../utils/utilities';
import { i18n } from '@feedr/helpers';
import { StringParam, useQueryParam } from 'use-query-params';

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #E8E8E8',
    padding: `15px 0`,
    minHeight: 140,
    alignItems: `center`,
    fontSize: 18,
  },
  descriptionSection: {
    width: '40%',
    paddingRight: 10,
  },
  scaleSection: {
    width: '40%',
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selfSection: {
    padding: '0 10px',
    width: '10%',
    textAlign: 'center',
  },
  removeSectionWidth: {
    width: '15%',
  },
  heading: {
    marginBottom: '2px',
    fontSize: 20,
    fontWeight: 700,
  },
  self: {
    color: '#4468AD',
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
  },
  other: {
    color: '#D69023',
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 700,
    textAlign: 'center',
  },
  title: {
    fontSize: 16,
  },
}));

interface CompareListItemProps {
  projectId: string;
  selfScore: number;
  otherScore: number;
  otherScoreName: string;
  heading: string;
  description: string;
  className?: string;
  classNameText?: string;
}

export const CompareListItem: React.FC<CompareListItemProps> = memo(props => {
  const style = useStyle({});
  const [language] = useQueryParam('language', StringParam);

  return (
    <div className={`${style.container} ${props.className}`}>
      <div className={style.descriptionSection}>
        <div className={style.heading}>{props.heading}</div>
        <div>{props.description}</div>
      </div>
      <div className={style.scaleSection}>
        <CompareScaleLine
          projectId={props.projectId}
          selfScore={roundToFixedDecimal(props.selfScore)}
          otherScore={roundToFixedDecimal(props.otherScore)}
        />
      </div>
      <div
        className={`${style.selfSection} ${language !== 'en' &&
          style.removeSectionWidth} d-flex flex-column align-items-center justify-content-center`}
      >
        <div
          className={`${style.self} ${props.classNameText} ${language !== 'en' &&
            style.title} text-center`}
        >
          {i18n.t('report.self')}
        </div>
        <div className={`${style.self} ${props.classNameText}`}>
          {!props.selfScore || isNaN(props.selfScore)
            ? '-'
            : roundToFixedDecimal(props.selfScore)}
        </div>
      </div>
      <div
        className={`${style.selfSection} ${language !== 'en' &&
          style.removeSectionWidth} d-flex flex-column align-items-center justify-content-center`}
      >
        <div
          className={`${style.other} ${props.classNameText} ${language !== 'en' &&
            style.title}`}
        >
          {props.otherScoreName}
        </div>
        <div className={`${style.other} ${props.classNameText}`}>
          {!props.otherScore || isNaN(props.otherScore)
            ? '-'
            : roundToFixedDecimal(props.otherScore)}
        </div>
      </div>
    </div>
  );
});
