import * as React from 'react';

interface BulbSvgProps {}

export const BulbSvg: React.FC<BulbSvgProps> = props => {
  return (
    <div>
      <svg
        id="Group_6621"
        data-name="Group 6621"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 37.369 47.203"
      >
        <rect
          id="Rectangle_4830"
          data-name="Rectangle 4830"
          width="22.051"
          height="4.055"
          transform="translate(7.897 43.148)"
          fill="#d69021"
        />
        <path
          id="Path_4715"
          data-name="Path 4715"
          d="M1285.38,300.851v6.006h16.608v-6.006a18.276,18.276,0,0,0,10.38-16.465,18.687,18.687,0,0,0-37.369,0A18.277,18.277,0,0,0,1285.38,300.851Zm8.3-30.766a14.434,14.434,0,0,1,14.532,14.3,14.247,14.247,0,0,1-9.085,13.25l-1.3.517v4.619h-2.076V290.514h2.076v-4.086h-8.3v4.086h2.076v12.257h-2.076v-4.619l-1.3-.517a14.247,14.247,0,0,1-9.085-13.25A14.435,14.435,0,0,1,1293.684,270.086Z"
          transform="translate(-1275 -266)"
          fill="#d69021"
        />
      </svg>
    </div>
  );
};
