import * as React from 'react';
import { CoverPage } from './components/CoverPage/CoverPage';
import { CircularProgress, Button } from '@material-ui/core';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../../../store';
import { IUser } from '@feedr/types';
import useReportState from '../useReportState';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps } from '@reach/router';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import { IntroductionPage } from './components/IntroductionPage/IntroductionPage';
import { ReportHighlightPage } from './components/ReportHighlightPage/ReportHighlightPage';
import { getReportHighlightData } from './components/ReportHighlightPage/stores/reportHighlightDataStore';
import { CompetencyBreakdownPage } from './components/CompetencyBreakdownPage/CompetencyBreakdownPage';
import { HighestLowestCompetencyPage } from './components/HighestLowestCompetencyPage/HighestLowestCompetencyPage';
import { ComparisonPage } from './components/ComparisonPage/ComparisonPage';

interface PrintPersonNewReportProps {
  projectId: string;
  rateeId: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  '@page': {
    size: 'A4',
    margin: '0 0',
    fontFamily: `'Quicksand', sans-serif !important`,
  },
  unbreakable: {
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
  },
  progress: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
  },
  pageContainer: {
    padding: '0.6in 0.8in',
  },
}));

export const PrintPersonNewReport: React.FC<RouteComponentProps<
  PrintPersonNewReportProps
>> = props => {
  const style = useStyle();
  const printPageRef = useRef<HTMLDivElement>();
  useFirestoreConnect(() => [{ collection: 'users', doc: props.rateeId }]);
  const {
    person,
    scores,
    ratings,
    ratingScale,
    questions,
    competencies,
    project,
    isAllLoaded,
  } = useSelector<IStore, any>(state => {
    const user = (state.firestore.ordered.users as IUser[])?.find(
      x => x.id === props.rateeId,
    );

    return useReportState(user?.email ?? '', props.projectId);
  });

  if (!isAllLoaded) {
    return <CircularProgress size={80} className={style.progress} />;
  }

  const data = getReportHighlightData(scores, ratingScale);
  return (
    <div style={{ display: isAllLoaded ? 'initial' : 'none' }}>
      {/*For Development Purpose Enable This*/}
      {/*<ReactToPrint*/}
      {/*  trigger={() => (*/}
      {/*    <Button*/}
      {/*      color={'primary'}*/}
      {/*      variant={'outlined'}*/}
      {/*      size={'small'}*/}
      {/*      style={{*/}
      {/*        position: 'fixed',*/}
      {/*        top: 0,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Print*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*  content={() => printPageRef.current}*/}
      {/*  pageStyle={*/}
      {/*    '@page {size: A4; margin: 0in 0;} @page :first {size: auto; margin: 0} @media print {body { -webkit-print-color-adjust: exact; }}'*/}
      {/*  }*/}
      {/*/>*/}
      {/**** Print page ****/}
      <div ref={printPageRef} id={'printDiv'}>
        <CoverPage title={project.name} person={person} />
        <div className={style.pageContainer}>
          <IntroductionPage ratings={ratings} />
          <ReportHighlightPage data={data} competenciesWithScore={scores} />
          <CompetencyBreakdownPage
            competenciesWithScore={scores}
            ratings={ratings}
            questions={questions}
          />
          <HighestLowestCompetencyPage scores={scores} projectId={props.projectId} />
          <ComparisonPage scores={scores} projectId={props.projectId} />
        </div>
      </div>
    </div>
  );
};
