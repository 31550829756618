import * as React from 'react';
import {
  HighestLowestCompetencyPage,
  HighestLowestCompetencyPageProps,
} from '../../../newReport/components/HighestLowestCompetencyPage/HighestLowestCompetencyPage';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface WebHighestLowestCompetencySectionProps
  extends HighestLowestCompetencyPageProps {}

const useStyle = makeStyles((theme: Theme) => ({
  table: {
    fontSize: 16,
  },
}));

export const WebHighestLowestCompetencySection: React.FC<WebHighestLowestCompetencySectionProps> = props => {
  const { ...otherProps } = props;
  const style = useStyle();
  return (
    <HighestLowestCompetencyPage
      {...otherProps}
      showWebHeader={true}
      tableClassName={style.table}
    />
  );
};
