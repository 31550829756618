import moment from 'moment';
import firebaseRef from 'firebase/app';
import { IFeedbackRating, RatingOrAnswer } from '@feedr/types';
import * as colors from '@material-ui/core/colors';
import * as bcrypt from 'react-native-bcrypt';
import { useEffect } from 'react';
import * as _ from 'lodash';

const colorsArray = Object.keys(colors);

/**
 * Hooks for usage of async/await in useEffect
 * @param effect The functional way
 * @param deps Dependency for the useEffect
 */
export const useEffectAsync = (effect, deps) => {
  useEffect(() => {
    effect();
  }, deps);
};

/**
 * Creates a new timestamp from the given date.
 * @param value The date in string.
 * @param fmt The format of given value.
 * @return A Timestamp to store in firestore.
 */
export const stringToTimeStamp = (value: string, fmt: string) =>
  firebaseRef.firestore.Timestamp.fromDate(moment(value, fmt)?.toDate());

/**
 * Generates a hash from string.
 * @param value
 */
export const hashString = (value: string) => bcrypt.hashSync(value, 10);

/**
 * Compares a hash value with plain text string.
 * @param plain
 * @param hashed
 */
export const compareHashedString = (plain: string, hashed: string) =>
  bcrypt.compareSync(plain, hashed);

/**
 * Generates a hash from string.
 * @param toBeDetermined The Feedback document stored in database.
 */
export function isFeedbackWithRating(
  toBeDetermined: RatingOrAnswer,
): toBeDetermined is IFeedbackRating {
  return !!(toBeDetermined as IFeedbackRating).rating;
}

/**
 * Determine if two string is case-insensitive identical.
 */
export const compareStringValue = (str1: string, str2: string) =>
  str1.toLowerCase().trim() === str2.toLowerCase().trim();

/**
 * Returns a random material color.
 * @param shade shades of material color. defaults to 500
 */
export const getRandomMaterialColor = (shade = 500) => {
  const random = Math.floor(Math.random() * Math.floor(20));

  return colors[colorsArray[random]][shade];
};

/**
 * Return a material color by index.
 * @param index 0 - 19
 * @param shade shades of material color. defaults to 500
 */
export const getMaterialColor = (index: number, shade = 500) => {
  if (index < 20) {
    return colors[colorsArray[index]][shade];
  }
};

export const roundToFixedDecimal = (num: number) => {
  if (isNaN(num)) {
    return 0;
  }
  const rounded = _.round(num, 1).toFixed(1);
  return Number(rounded) === 0 ? 0 : rounded;
};
