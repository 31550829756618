import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';

export const OpinionHeader: React.FC = props => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <ForumOutlinedIcon fontSize={'large'} />
      <Typography variant={'h6'}>Opinions</Typography>
    </Box>
  );
};
