import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Header } from '../Header/Header';
import { ListSvg } from '../../assets/ListSvg';
import { CompetencyTable, CompetencyTableData } from '../CompetencyTable/CompetencyTable';
import { ICompetencyScore, IProject, IProjectRating } from '@feedr/types';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../../../../../store';
import { roundToFixedDecimal } from '../../../../../../../../../utils/utilities';
import { WebHeader } from '../../../newReportWeb/components/WebHeader/WebHeader';
import { i18n } from '@feedr/helpers';

export interface HighestLowestCompetencyPageProps {
  projectId: string;
  scores: ICompetencyScore[];
  showWebHeader?: boolean;
  tableClassName?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  highestLowestCompetencyPage: {
    pageBreakAfter: 'always',
  },
}));

export const HighestLowestCompetencyPage: React.FC<HighestLowestCompetencyPageProps> = props => {
  const style = useStyle();
  const { projectId, scores, showWebHeader, tableClassName } = props;

  const project = useSelector<IStore, IProject | undefined>(
    state => state.firestore.ordered[`projects/${projectId}`]?.[0],
  );

  const ratings: IProjectRating[] = Object.values(project?.ratings ?? {});

  const massagedScored: CompetencyTableData[] = [];
  scores.map(score => {
    const item = { id: score.id, competency: score.name };
    score.descriptionScores.map((description, idx) => {
      const mean = description.score.raterAverage;
      const raterAverage = isNaN(mean) ? '-' : mean;
      const selfScore = isNaN(description.score.selfScore)
        ? '-'
        : description.score.selfScore;
      massagedScored.push({
        ...item,
        selfScore: selfScore,
        raterAverage: raterAverage,
        raterAverageRounded: _.isNumber(raterAverage)
          ? roundToFixedDecimal(raterAverage)
          : raterAverage,
        raterAverageColor:
          _.isNumber(raterAverage) && ratings[Math.round(raterAverage - 1)]?.color?.hex,
        description: JSON.parse(description.detail)?.blocks[0].text.trim(),
        descriptionOrderIndex: idx,
        competencyOrderIndex: score.orderIndex,
      });
    });
  });

  const sortedScores: CompetencyTableData[] = _.orderBy(
    massagedScored,
    ['raterAverage', 'competencyOrderIndex', 'descriptionOrderIndex'],
    ['desc', 'asc', 'asc'],
  ).map((item, key) => {
    return {
      rank: key + 1,
      ...item,
    };
  });

  return (
    <div className={`${style.highestLowestCompetencyPage}`}>
      {showWebHeader ? (
        <WebHeader
          icon={<ListSvg size={30} />}
          title={i18n.t('report.highest_to_lowest_competency_item_ratings')}
          description={i18n.t(
            'report.highest_to_lowest_competency_item_ratings_description',
          )}
        />
      ) : (
        <Header
          icon={<ListSvg />}
          title={i18n.t('report.highest_to_lowest_competency_item_ratings')}
          description={i18n.t(
            'report.highest_to_lowest_competency_item_ratings_description',
          )}
        />
      )}
      <div>
        <CompetencyTable data={sortedScores} className={tableClassName} />
      </div>
    </div>
  );
};
