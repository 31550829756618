export const ProductionEnvironment = {
  production: true,
  appURL: 'https://feedr.accendo.com.my',
  webAppURL: 'https://accendo-feedr.web.app',
  apiURL: 'https://us-central1-accendo-feedr.cloudfunctions.net',
  apiKey: 'AIzaSyBmY0e3VVPjJ4fZbO1gO7poJwJjeomnwOY',
  authDomain: 'accendo-feedr.firebaseapp.com',
  databaseURL: 'https://accendo-feedr.firebaseio.com',
  projectId: 'accendo-feedr',
  storageBucket: 'accendo-feedr.appspot.com',
  messagingSenderId: '321713748575',
  appId: '1:321713748575:web:8b2cad411d3d59f17779ea',
  measurementId: 'G-L4ZWPN96JZ',
  mailerPort: 587,
  mailerHost: 'smtp.mailgun.org',
};
