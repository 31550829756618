import * as React from 'react';

interface MindMapSvgProps {}

export const MindMapSvg: React.FC<MindMapSvgProps> = props => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 47.203 47.203"
      >
        <g id="mind-map" transform="translate(0)">
          <path
            id="Path_8333"
            data-name="Path 8333"
            d="M42.363,18.761a4.848,4.848,0,0,0-4.638,3.457H33.881a10.394,10.394,0,0,0-8.9-8.9V9.478a4.84,4.84,0,1,0-2.766,0v3.844a10.322,10.322,0,0,0-4.9,2.036l-2.72-2.72a4.841,4.841,0,1,0-1.957,1.954l2.722,2.722a10.321,10.321,0,0,0-2.036,4.9H9.478a4.84,4.84,0,1,0,0,2.766h3.844a10.394,10.394,0,0,0,8.9,8.9v3.844a4.84,4.84,0,1,0,2.766,0V33.881a10.394,10.394,0,0,0,8.9-8.9h3.844a4.84,4.84,0,1,0,4.638-6.223ZM4.84,25.676A2.074,2.074,0,1,1,6.915,23.6,2.077,2.077,0,0,1,4.84,25.676ZM21.527,4.84A2.074,2.074,0,1,1,23.6,6.915,2.077,2.077,0,0,1,21.527,4.84ZM8.868,11.8a2.074,2.074,0,1,1,2.934,0A2.061,2.061,0,0,1,8.868,11.8ZM25.676,42.363A2.074,2.074,0,1,1,23.6,40.289,2.077,2.077,0,0,1,25.676,42.363ZM23.6,31.207A7.606,7.606,0,1,1,31.207,23.6,7.615,7.615,0,0,1,23.6,31.207Zm18.761-5.532A2.074,2.074,0,1,1,44.437,23.6,2.077,2.077,0,0,1,42.363,25.676Z"
            fill="#d69023"
          />
        </g>
      </svg>
    </div>
  );
};
