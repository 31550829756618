import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ProfileLogo } from '../svg/ProfileLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '30px',
      marginBottom: 10,
    },
    nameContainer: {
      display: 'flex',
      gap: '6px',
      width: 390,
      '& .nameValue': {
        width: 318,
      },
      '& .nameTitle': {
        padding: '3px 0 0 0',
        color: '#525252',
        font: 'normal normal normal 14px/20px Muli',
      },
    },
    positionContainer: {
      width: 500,
    },
    opcoContainer: {
      width: 170,
    },
    dateContainer: {
      width: 170,
    },
    topHeaderTitle: {
      color: '#525252',
      font: 'normal normal normal 14px/20px Muli',
      paddingTop: 3,
    },
    topHeaderValue: {
      color: '#164396',
      font: 'normal normal bold 18px/23px Muli',
      borderBottom: '1px solid #164396',
      paddingBottom: 3,
      height: 27,
    },
  }),
);
const TopHeaderInfo: React.FC<{
  name: string;
  position: string;
  opco: string;
  date: string;
}> = props => {
  const classes = useStyles({});

  return (
    <div className={`${classes.topHeaderContainer}`}>
      <div className={`${classes.nameContainer}`}>
        <div style={{ display: 'flex', alignItems: 'center', padding: 3 }}>
          <ProfileLogo />
        </div>
        <NameContainer name={props.name} />
      </div>
      <PositionContainer position={props.position} />
      <OpcoContainer opco={props.opco} />
      <div className={`${classes.dateContainer}`}>
        <div className={`${classes.topHeaderTitle}`}>Date:</div>
        <div className={`${classes.topHeaderValue}`}>{props.date}</div>
      </div>
    </div>
  );
};

export default TopHeaderInfo;

const OpcoContainer: React.FC<{
  opco: string;
}> = props => {
  const classes = useStyles({});

  return (
    <div className={`${classes.opcoContainer}`}>
      <div className={`${classes.topHeaderTitle}`}>OpCo:</div>
      <div className={`${classes.topHeaderValue}`} style={{ fontSize: 15 }}>
        {props.opco}
      </div>
    </div>
  );
};
const NameContainer: React.FC<{
  name: string;
}> = props => {
  const classes = useStyles({});

  return (
    <div>
      <div className={`${classes.topHeaderTitle}`}>Name:</div>
      <div className={`${classes.topHeaderValue} nameValue`} style={{ fontSize: 15 }}>
        {props.name}
      </div>
    </div>
  );
};

const PositionContainer: React.FC<{
  position: string;
}> = props => {
  const classes = useStyles({});
  const len = props.position.length;

  return (
    <div className={`${classes.positionContainer}`}>
      <div className={`${classes.topHeaderTitle}`}>Position Title:</div>
      <div
        className={`${classes.topHeaderValue}`}
        style={{ fontSize: len < 65 ? 15 : len < 75 ? 14 : 12 }}
      >
        {props.position}
      </div>
    </div>
  );
};
