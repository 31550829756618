import * as React from 'react';
import { Header, HeaderProps } from '../../../newReport/components/Header/Header';
import { BulbSvg } from '../../../newReport/assets/BulbSvg';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

interface WebHeaderProps extends HeaderProps {}

const useStyle = makeStyles((theme: Theme) => ({
  icon: {
    paddingRight: 10,
    height: 18,
    '& > div': {
      display: 'flex',
      marginTop: 2,
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    marginBottom: -5,
  },
  description: {
    fontWeight: 400,
    fontSize: 16,
    paddingLeft: 40,
  },
}));

export const WebHeader: React.FC<WebHeaderProps> = props => {
  const { ...otherProps } = props;
  const style = useStyle();

  return (
    <Header
      {...otherProps}
      titleClassName={style.title}
      iconClassName={style.icon}
      descriptionClassName={style.description}
    />
  );
};
