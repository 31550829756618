export const TRANSLATIONS_EN = {
  answer: 'Answer',
  average: 'Average',
  breakdown_based_on_ratee: 'Breakdown Based on Ratee',
  breakdown_based_on_rater: 'Breakdown Based on Rater',
  candidate: 'Candidate',
  comment: 'Comment',
  competency: 'Competency',
  count: 'Count',
  definitions: {
    ratees: 'Individuals that receive ratings',
    raters: 'Individuals that provide ratings',
    not_started: 'Invited and has not started',
    in_progress: 'In progress of completing the survey',
    completed: 'Has completed but yet to submit the survey',
    submitted: 'Has completed and submitted the survey',
    not_applicable: 'Not applicable',
    unknown: 'Relationship is not close enough to give ratings',
    remark: '*This can be applied across this excel',
    self: 'Self ratings',
    manager: 'Ratings provided by the Manager',
    peer: 'Ratings provided by the Peer',
    direct_report: 'Ratings provided by the Direct Report',
    others: 'Ratings provided by the Others',
    rater_average:
      'The ratings of all your feedback providers (Excluding your self ratings) averaged into a single score',
    group_rater_average:
      'The ratings of all feedback providers within the project (Excluding your self ratings) averaged into a single score',
    group_average:
      'The ratings of all feedback providers within the project (Including your self ratings) averaged into a single score',
  },
  description: 'Description',
  details: 'Details',
  email: 'Email',
  end_date: 'End Date',
  individual: 'Individual',
  last_updated: 'Last Updated',
  legends: 'Legends',
  name: 'Name',
  no: 'No',
  number_of_ratees: 'Number of Ratees',
  number_of_raters: 'Number of Raters',
  number_of_submitted_raters: 'Number of Submitted Raters',
  opinions: 'Opinions',
  order: 'Order',
  overall_rating_completion: 'Overall Rating Completion',
  percentage: 'Percentage',
  project: 'Project',
  project_statuses: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  },
  question: 'Question',
  ratee: 'Ratee',
  ratee_of: "Ratee's",
  ratees: 'Ratees',
  rater: 'Rater',
  rater_of: "Rater's",
  raters: 'Raters',
  rating: 'Rating',
  report: {
    about_the_report: 'About the report',
    about_the_report_p1:
      'The feedback in this 360 report is derived from various parties with different roles. It provides a valuable opportunity to reflect on the multiple sources of feedback as well as recognise your strengths and gaps. To fully benefit from this report, you are advised to identify the themes and trends that are represented in this report as you read through the following sections. The “Report Highlights” section summarises your strengths, opportunities to improve, and other insights in a quick glance. You can dive into the details for a deeper understanding of your scores.',
    about_the_report_p2: 'Remember',
    about_the_report_p3:
      'every individual has their unique strengths and development opportunities. Use this report to increase your self-awareness on areas you should focus your efforts on to improve your work effectiveness.',
    all_raters: 'All Raters',
    comparison: 'Comparison',
    comparison_description: 'Compare differences in ratings between the self and others',
    competency: 'Competency',
    competency_breakdown: 'Competency Breakdown',
    competency_breakdown_description:
      'This section breaks down the competency ratings by raters. It allows to quickly identify differences between your ratings and others',
    competency_rating_by_item: 'Competency Rating by Item',
    description: 'Description',
    direct_report: 'Direct Report',
    direct_reports: 'Direct Reports',
    group: 'Group',
    group_average: 'Group Average',
    group_rater_average: 'Group Rater Average',
    highest_to_lowest_competency_item_ratings:
      'Highest to Lowest Competency Item Ratings',
    highest_to_lowest_competency_item_ratings_description:
      'This section ranks your scores from highest to lowest based on how other people have rated you on each statement. You can compare your self rated score to what others have rated you.',
    how_to_read_this_report: 'How to read this report',
    how_to_read_this_report_all_raters:
      'The ratings of all feedback providers (Including your self ratings)',
    how_to_read_this_report_group_average:
      'The ratings of all feedback providers within the project (Including your self ratings) averaged into a single score',
    how_to_read_this_report_group_rater_average:
      'The ratings of all feedback providers within the project (Excluding your self ratings) averaged into a single score',
    how_to_read_this_report_p1:
      'The ratings displayed in this report range from 1 (lowest) to 5 (highest). All ratings display up to 1 decimal place, as they can be averaged from various raters.',
    how_to_read_this_report_p2: 'Below are terms that are used in this report:',
    how_to_read_this_report_rater_average:
      'The ratings of all your feedback providers (Excluding your self ratings) averaged into a single score',
    how_to_read_this_report_self: 'Your ratings',
    introduction: 'Introduction',
    manager: 'Manager',
    managers: 'Managers',
    others: 'Others',
    overall_competency_rating: 'Overall Competency Rating',
    peers: 'Peers',
    rank: 'Rank',
    rating_guide: 'Rating Guide',
    no_feedback_was_given: 'No feedback was given.',
    rater_average: 'Rater Average',
    raters_want_you_to_know: 'Raters want you to know',
    raters_want_you_to_know_description:
      'This section provides you with qualitative feedback and insights from raters',
    rating_overview: 'Rating Overview',
    report_highlights: 'Report Highlights',
    report_highlights_catch_up_on: 'Catch Up On',
    report_highlights_catch_up_on_description:
      'Competencies that you are rated lower than the group average (e.g. group average is 4, your average is 3.6)',
    report_highlights_description:
      'This section shows the key takeaways from the feedback gathered',
    report_highlights_hidden_potential: 'Hidden Potential',
    report_highlights_hidden_potential_description:
      'Competencies that you have rated much lower than others (e.g. you rated 3.3, others rated you 4)',
    report_highlights_highest_rated_competency: 'Highest Rated Competency',
    report_highlights_highest_rated_competency_description:
      'Competencies that are highly rated by all raters (≥4 out of 5)',
    report_highlights_lowest_rated_competency: 'Lowest Rated Competency',
    report_highlights_lowest_rated_competency_description:
      'Competencies that are rated low by all raters (≤2 out of 5)',
    report_highlights_outperforms_others: 'Outperforms Others',
    report_highlights_outperforms_others_description:
      'Competencies that you are rated higher than the group average (e.g. group average is 3, your average is 3.4)',
    report_highlights_oversight: 'Oversight',
    report_highlights_oversight_description:
      'Competencies that you have rated much higher than others (e.g. you rated 4, others rated you 3.3)',
    results_valid_for_12_months_p1: 'The results in this report is estimated ',
    results_valid_for_12_months_p2: 'to be valid for 12 months.',
    self: 'Self',
    self_score: 'Self Score',
    summary: 'Summary',
    summary_description: 'Summarises the overall ratings across all competencies',
  },
  role: 'Role',
  roles: {
    Self: 'Self',
    Manager: 'Manager',
    Peer: 'Peer',
    'Direct Report': 'Direct Report',
    Others: 'Others',
    Unknown: 'Unknown',
    'Rater Average': 'Rater Average',
    'Group Rater Average': 'Group Rater Average',
    'Group Average': 'Group Average',
  },
  scores: 'Scores',
  start_date: 'Start Date',
  status: 'Status',
  submission_rate: 'Submission Rate',
  submission_summary: 'Submission Summary',
  summary_based_on_ratee: 'Summary Based on Ratee',
  summary_based_on_rater: 'Summary Based on Rater',
  survey: 'Survey',
  survey_statuses: {
    Submitted: 'Submitted',
    Completed: 'Completed',
    'In Progress': 'In Progress',
    'Not Started': 'Not Started',
    'N/A': 'N/A',
    Unknown: 'Unknown',
  },
  total_participants: 'Total Participants',
};
