import * as React from 'react';

interface ChatBubbleSvgProps {}

export const ChatBubbleSvg: React.FC<ChatBubbleSvgProps> = props => {
  return (
    <div>
      <svg
        id="Group_7191"
        data-name="Group 7191"
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 36.498 35.607"
      >
        <path
          id="Path_4270"
          data-name="Path 4270"
          d="M1687.978,896.1v-1.521a4.568,4.568,0,0,0-4.562-4.562H1660.6a4.568,4.568,0,0,0-4.562,4.562V909.79a4.568,4.568,0,0,0,4.562,4.562h1.521v8.233l8.234-8.233h.891V911.31h-2.15l-3.933,3.933V911.31H1660.6a1.522,1.522,0,0,1-1.521-1.521V894.582a1.522,1.522,0,0,1,1.521-1.521h22.811a1.522,1.522,0,0,1,1.521,1.521V896.1Z"
          transform="translate(-1656.042 -890.02)"
          fill="#d69021"
        />
        <path
          id="Path_4271"
          data-name="Path 4271"
          d="M1682.249,895.02H1671.6a4.568,4.568,0,0,0-4.562,4.562v12.166a4.568,4.568,0,0,0,4.562,4.562h3.933l6.712,6.713V916.31a4.568,4.568,0,0,0,4.562-4.562V899.582A4.568,4.568,0,0,0,1682.249,895.02Zm1.521,16.728a1.522,1.522,0,0,1-1.521,1.521h-3.042v2.412l-2.412-2.412H1671.6a1.522,1.522,0,0,1-1.521-1.521V899.582a1.523,1.523,0,0,1,1.521-1.521h10.645a1.522,1.522,0,0,1,1.521,1.521Z"
          transform="translate(-1650.313 -887.416)"
          fill="#d69021"
        />
      </svg>
    </div>
  );
};
