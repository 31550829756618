import React from 'react';
import { RouteComponentProps } from '@reach/router';
import ErrorBoundary from '../../components/ErrorBoundary';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { IStore } from '../../store';
import { IProject } from '@feedr/types';
import { Typography } from '@material-ui/core';
import LoadingPage from '../../components/Loader/LoadingPage';

const ProjectRouteForClientAdmin: React.FC<RouteComponentProps<{
  projectId: string;
  disableLoading?: boolean;
}>> = props => {
  const auth = useSelector<any, any>(state => state.firebase.auth);
  const profile = useSelector<any, any>(state => state.firebase.profile);
  useFirestoreConnect(() => [
    {
      collection: 'projects',
      doc: props.projectId,
      storeAs: `projects/${props.projectId}`,
    },
  ]);
  const project = useSelector<IStore, IProject>(
    state => state.firestore.ordered[`projects/${props.projectId}`]?.[0],
  );

  const isSuperAdmin = auth?.providerData?.some(x => x.providerId === 'password');
  const isAdmin =
    auth?.uid &&
    (isSuperAdmin ||
      (profile?.token?.claims?.admin &&
        profile?.token?.claims?.client === project?.clientId));

  return isAdmin ? (
    <ErrorBoundary>{props.children}</ErrorBoundary>
  ) : isLoaded(profile) ? (
    <div>
      <Typography variant={'h4'}>Unauthorized.</Typography>
    </div>
  ) : props.disableLoading ? null : (
    <LoadingPage msg={'Loading...'} />
  );
};

export default ProjectRouteForClientAdmin;
