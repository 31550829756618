import * as React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Header } from '../Header/Header';
import { BulbSvg } from '../../assets/BulbSvg';
import { MindMapSvg } from '../../assets/MindMapSvg';
import { CompetencyItem, ProgressData } from '../CompetencyItem/CompetencyItem';
import { ICompetencyScore, IQuestionResponse } from '@feedr/types';
import * as _ from 'lodash';
import { roundToFixedDecimal } from '../../../../../../../../../utils/utilities';
import { IRatingOptionsItem } from '../../../../../../../../raterHome/raterView/components/RatingOptions';
import ScoreCircle from '../../../components/CompetencyOverview/ScoreCircle';
import { CompetencyCircleItemProps } from '../CompetencyItem/CompetencyCircleItem';
import { ChatBubbleSvg } from '../../assets/ChatBubbleSvg';
import { HeaderLabel } from '../Header/HeaderLabel';
import { CircleNumber } from '../CircleNumber/CircleNumber';
import Opinions from '../../../components/Opinions';
import { OpinionBox } from '../OpinionBox/OpinionBox';
import { WebHeader } from '../../../newReportWeb/components/WebHeader/WebHeader';
import { i18n } from '@feedr/helpers';

export interface CompetencyBreakdownPageProps {
  competenciesWithScore: ICompetencyScore[];
  ratings: IRatingOptionsItem;
  questions: IQuestionResponse;
  showWebHeader?: boolean;
  titleClassName?: string;
  descriptionClassName?: string;
  circleTitleClassName?: string;
}

interface Opinions {
  title: string;
  feedbacks: string[];
}

const useStyle = makeStyles((theme: Theme) => ({
  competencyBreakdownPage: {
    pageBreakAfter: 'always',
  },
  opinionHeader: {
    paddingBottom: 10,
  },
  opinionTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  noOpinion: {
    padding: `10px 35px`,
  },
  opinionContainer: {
    // paddingLeft: 40
  },
  avoidPageBreak: {
    pageBreakInside: 'avoid',
  },
  feedbackContainer: {
    paddingTop: 5,
    paddingBottom: 5,
  },
}));

const roleLabelTrans = {
  selfScore: 'self_score',
  managersAverage: 'managers',
  peersAverage: 'peers',
  directReportsAverage: 'direct_reports',
  othersAverage: 'others',
  groupAverage: 'group_average',
};

export const CompetencyBreakdownPage: React.FC<CompetencyBreakdownPageProps> = props => {
  const style = useStyle();
  const theme = useTheme();
  const {
    showWebHeader = false,
    competenciesWithScore,
    ratings,
    questions,
    titleClassName,
    descriptionClassName,
    circleTitleClassName,
  } = props;

  const opinions: Opinions[] =
    Array.isArray(questions) &&
    questions.length > 0 &&
    questions?.map((question: IQuestionResponse, idx) => {
      return {
        title: question.title,
        feedbacks: question.feedbacks.map(item => item.answer),
      };
    });

  return (
    <div className={`${style.competencyBreakdownPage}`}>
      {showWebHeader ? (
        <WebHeader
          icon={<MindMapSvg />}
          title={i18n.t('report.competency_breakdown')}
          description={i18n.t('report.competency_breakdown_description')}
        />
      ) : (
        <Header
          icon={<MindMapSvg />}
          title={i18n.t('report.competency_breakdown')}
          description={i18n.t('report.competency_breakdown_description')}
        />
      )}
      <div>
        {competenciesWithScore.map((competencyScores, idx) => {
          const graphData: ProgressData[] = _.compact(
            Object.entries(competencyScores.score || {}).map(([scoreType, score]) => {
              return scoreType !== 'raterAverage' &&
                scoreType !== 'rateeAverage' &&
                scoreType !== 'groupRaterAverage'
                ? {
                    value: roundToFixedDecimal(score) as number,
                    leftText: i18n.t(`report.${roleLabelTrans[scoreType]}`),
                    color:
                      ratings &&
                      ((ratings[Math.round(score)]?.color.hex ||
                        theme.palette.grey[400]) as string),
                  }
                : null;
            }),
          );

          const circleCompetencyData: CompetencyCircleItemProps[] = competencyScores.descriptionScores?.map(
            (description, idx) => {
              const circleCompetencyData = Object.entries(description.score)
                .map(([scoreType, score], key) => {
                  const roundedScore = roundToFixedDecimal(Number(score));
                  return (
                    scoreType !== 'raterAverage' &&
                    scoreType !== 'rateeAverage' &&
                    scoreType !== 'groupRaterAverage' && {
                      title: i18n.t(`report.${roleLabelTrans[scoreType]}`),
                      color: ratings[Math.round(Number(roundedScore))]?.color?.hex,
                      value: roundedScore,
                    }
                  );
                })
                .filter(i => i);

              return {
                circleData: circleCompetencyData,
                title: JSON.parse(description.detail)?.blocks[0].text.trim(),
                numberIndex: idx + 1,
              };
            },
          );

          return (
            <CompetencyItem
              key={`competency-item-${idx}`}
              title={competencyScores.name}
              description={competencyScores.definition}
              progressData={graphData}
              circleData={circleCompetencyData}
              descriptionClassName={descriptionClassName}
              titleClassName={titleClassName}
              circleTitleClassName={circleTitleClassName}
            />
          );
        })}
        {!_.isEmpty(opinions) && (
          <div className={style.opinionContainer}>
            {showWebHeader ? (
              <WebHeader
                icon={<ChatBubbleSvg />}
                title={i18n.t('report.raters_want_you_to_know')}
                description={i18n.t('report.raters_want_you_to_know_description')}
              />
            ) : (
              <HeaderLabel
                icon={<ChatBubbleSvg />}
                title={i18n.t('report.raters_want_you_to_know')}
                description={i18n.t('report.raters_want_you_to_know_description')}
              />
            )}

            {opinions.map((item, key) => {
              return (
                <div
                  className={`d-flex flex-column position-relative ${key !== 0 &&
                    `pt-3`}`}
                  key={`opinions-${key}`}
                >
                  <div
                    className={`position-relative d-flex flex-row align-items-baseline py-3 ${style.avoidPageBreak}`}
                  >
                    <div className={`pr-2`}>
                      <CircleNumber>{key + 1}</CircleNumber>
                    </div>
                    <HeaderLabel
                      title={item.title}
                      titleClassName={`${style.opinionTitle} ${circleTitleClassName}`}
                      className={`${style.opinionHeader} pt-0 pb-0`}
                    />
                  </div>
                  <div className={`w-100 h-100`}>
                    {_.isEmpty(item.feedbacks) ? (
                      <div className={`${style.noOpinion} ${style.avoidPageBreak}`}>
                        {i18n.t('report.no_feedback_was_given')}
                      </div>
                    ) : (
                      item.feedbacks
                        .filter(i => i && i.trim())
                        .map((item, key) => {
                          return (
                            <div
                              className={`${style.avoidPageBreak} ${style.feedbackContainer}`}
                              key={`feedback-${key}`}
                            >
                              <OpinionBox className={descriptionClassName}>
                                {item}
                              </OpinionBox>
                            </div>
                          );
                        })
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
