import * as React from 'react';

interface GraphSvgProps {}

export const GraphSvg: React.FC<GraphSvgProps> = props => {
  return (
    <div>
      <svg
        id="Group_7192"
        data-name="Group 7192"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 35.687 34.748"
      >
        <rect
          id="Rectangle_4708"
          data-name="Rectangle 4708"
          width="35.687"
          height="3.346"
          transform="translate(0 31.402)"
          fill="#d69021"
        />
        <rect
          id="Rectangle_4709"
          data-name="Rectangle 4709"
          width="4.461"
          height="11.152"
          transform="translate(3.346 18.02)"
          fill="#d69021"
        />
        <rect
          id="Rectangle_4710"
          data-name="Rectangle 4710"
          width="4.461"
          height="5.576"
          transform="translate(9.184 23.596)"
          fill="#d69021"
        />
        <rect
          id="Rectangle_4711"
          data-name="Rectangle 4711"
          width="3.346"
          height="27.88"
          transform="translate(15.709 1.291)"
          fill="#d69021"
        />
        <rect
          id="Rectangle_4712"
          data-name="Rectangle 4712"
          width="3.346"
          height="21.189"
          transform="translate(22.177 7.983)"
          fill="#d69021"
        />
        <rect
          id="Rectangle_4713"
          data-name="Rectangle 4713"
          width="3.346"
          height="29.172"
          transform="translate(27.88)"
          fill="#d69021"
        />
      </svg>
    </div>
  );
};
