import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { LinearProgress, Typography } from '@material-ui/core';
import { FeedrLogo } from '../../../../../../../../../components/SvgIcons/FeedrLogo';

interface WebReportLoadingProps {}
const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: 30,
    animation: `$fadeIn 500ms ${theme.transitions.easing.easeInOut}`,
  },
  loadingText: {
    fontSize: 14,
    fontWeight: 400,
  },
  loader: {
    width: 200,
    height: 2,
    margin: 5,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.7)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
}));

export const WebReportLoading: React.FC<WebReportLoadingProps> = props => {
  const style = useStyles({});

  return (
    <div className={style.loading}>
      <div
        className={`d-flex flex-column justify-content-center w-100 h-100 align-items-center`}
      >
        <div className={`pb-1`}>
          <FeedrLogo width={80} />
        </div>
        <LinearProgress className={style.loader} />
        <div className={`${style.loadingText}`}>Loading Report...</div>
      </div>
    </div>
  );
};
