import * as React from 'react';
import {
  SummarySection,
  SummarySectionProps,
} from '../../../newReport/components/SummarySection/SummarySection';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface WebSummarySectionProps extends SummarySectionProps {}

const useStyle = makeStyles((theme: Theme) => ({
  headerText: {
    fontSize: 20,
  },
  legendText: {
    fontSize: 18,
  },
}));

export const WebSummarySection: React.FC<WebSummarySectionProps> = props => {
  const style = useStyle();
  const { ...otherProps } = props;
  return (
    <SummarySection
      showWebHeader={true}
      legendTextClassName={style.legendText}
      headerTextClassName={style.headerText}
      sectionClassName={`pt-0`}
      {...otherProps}
    />
  );
};
