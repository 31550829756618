import React from 'react';
import { navigate, RouteComponentProps } from '@reach/router';

import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles({
  root: {
    borderRadius: '100%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#fff',
  },
});

interface IThumbnail extends RouteComponentProps {
  src: string;
  name: string;
  className?: string;
}

const Thumbnail: React.FC<IThumbnail> = props => {
  const classes = useStyles({});

  const getNameInitial = name => {
    if (name) {
      let initials = name.match(/\b\w/g) || [];
      initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      return initials;
    }
  };
  return (
    <Avatar
      alt="Profile picture"
      className={`${classes.root} ${props?.className || ''}`}
      src={props.src}
    >
      {getNameInitial(props.name)}
    </Avatar>
  );
};

export default Thumbnail;
