import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

interface OpinionBoxProps {
  className?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  opinionBox: {
    position: `relative`,
    background: `#F8F8F8`,
    color: `#212121`,
    borderRadius: `10px`,
    padding: `10px 35px`,
    fontSize: 18,
    border: `1px solid #BCBCBC`,
    pageBreakInside: 'avoid',
    display: 'inline-block',
    width: `100%`,
  },
}));

export const OpinionBox: React.FC<OpinionBoxProps> = props => {
  const { className } = props;
  const style = useStyle();
  return <div className={`${style.opinionBox} ${className}`}>{props.children}</div>;
};
