import * as React from 'react';
import { WebReportContainer } from './components/WebReportContainer/WebReportContainer';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../../../store';
import { IUser } from '@feedr/types';
import useReportState from '../useReportState';
import { WebReportHeader } from './components/WebReportHeader/WebReportHeader';
import { getReportHighlightData } from '../newReport/components/ReportHighlightPage/stores/reportHighlightDataStore';
import { WebReportHighlightsSection } from './components/ReportHighlights/WebReportHighlightsSection';
import { CandidateProfileBox } from './components/CandidateProfileBox/CandidateProfileBox';
import { Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../../../../../../../components/TabPanel';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { WebIntroductionSection } from './components/WebIntroductionSection/WebIntroductionSection';
import { WebSummarySection } from './components/WebSummarySection/WebSummarySection';
import { WebCompetencyBreakdownSection } from './components/WebCompetencyBreakdownSection/WebCompetencyBreakdownSection';
import { WebHighestLowestCompetencySection } from './components/WebHighestLowestCompetencySection/WebHighestLowestCompetencySection';
import { WebComparisonSection } from './components/WebComparisonSection/WebComparisonSection';
import { WebReportLoading } from './components/WebReportLoading/WebReportLoading';
import { downloadPersonReport } from '../../../../../../../utils/downloadPersonReport';
import { useConfirmation } from '../../../../../../../components/Confirmation/service';
import { useRef } from 'react';
import { useEffectAsync } from '../../../../../../../utils/utilities';

interface NewReportWebProps {
  projectId?: string;
  rateeId?: string;
  path?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  newWebReport: {
    fontFamily: `'Quicksand', sans-serif !important`,
    animation: `$fadeIn 500ms ${theme.transitions.easing.easeInOut}`,
  },
  tab: {
    color: '#4468AD !important',
    fontFamily: `'Quicksand', sans-serif !important`,
  },
  tabSelected: {
    fontWeight: 'bold',
    outline: 'none !important',
  },
  tabContent: {
    fontFamily: `'Quicksand', sans-serif !important`,
    minHeight: 600,
  },
  tabMain: {
    position: 'sticky',
    top: 60,
    background: 'white',
    zIndex: 5,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.95)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
}));

export const NewReportWeb: React.FC<NewReportWebProps> = props => {
  const style = useStyle();
  const tabRef = useRef<any>();
  useFirestoreConnect(() => [{ collection: 'users', doc: props.rateeId }]);
  const {
    person,
    scores,
    ratings,
    ratingScale,
    questions,
    competencies,
    startDate,
    lastUpdate,
    feedbackProviderCount,
    project,
    isAllLoaded,
  } = useSelector<IStore, any>(state => {
    const user = (state.firestore.ordered.users as IUser[])?.find(
      x => x.id === props.rateeId,
    );

    return useReportState(user?.email ?? '', props.projectId);
  });

  const profile = useSelector<any, any>(state => state.firebase.profile);
  const confirm = useConfirmation();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tabInitialOffsetTop, setTabInitialOffsetTop] = React.useState<number>();

  const data = getReportHighlightData(scores, ratingScale);

  useEffectAsync(() => {
    if (!tabInitialOffsetTop && tabRef?.current?.offsetTop) {
      setTabInitialOffsetTop(tabRef?.current?.offsetTop);
    }

    if (tabInitialOffsetTop) {
      window.scrollTo({
        top: tabInitialOffsetTop - 60,
        behavior: 'smooth',
      });
    }
  }, [currentTabIndex, tabInitialOffsetTop]);

  const tabs = [
    {
      label: 'Introduction',
      key: 'introduction',
      content: (
        <div>
          <WebIntroductionSection ratings={ratings} />
        </div>
      ),
    },
    {
      label: 'Summary',
      key: 'summary',
      content: (
        <div>
          <WebSummarySection competenciesWithScore={scores} />
        </div>
      ),
    },
    {
      label: 'Competency Breakdown',
      key: 'competency-breakdown',
      content: (
        <div>
          <WebCompetencyBreakdownSection
            competenciesWithScore={scores}
            ratings={ratings}
            questions={questions}
          />
        </div>
      ),
    },
    {
      label: 'Highest to Lowest Competency Item Ratings',
      key: 'high-low',
      content: (
        <div>
          <WebHighestLowestCompetencySection
            scores={scores}
            projectId={props.projectId}
          />
        </div>
      ),
    },
    {
      label: 'Comparison',
      key: 'comparison',
      content: (
        <div>
          <WebComparisonSection scores={scores} projectId={props.projectId} />
        </div>
      ),
    },
  ];

  const onClickPrintButton = async () => {
    setLoading(true);
    try {
      await downloadPersonReport({
        fileName: person?.name,
        rateeId: props.rateeId,
        projectId: props.projectId,
        token: profile.token.token,
      });
      setLoading(false);
    } catch (e) {
      confirm({
        variant: 'error',
        title: 'Download Error',
        description: e.message,
        acceptText: 'Ok',
        noCancel: true,
      });
      setLoading(false);
    }
  };

  return !isAllLoaded ? (
    <WebReportLoading />
  ) : (
    <div className={style.newWebReport}>
      <WebReportHeader
        title={project?.name}
        imageLink={project?.image}
        buttonProps={{
          onClick: onClickPrintButton,
        }}
        buttonLoading={loading}
      />
      <WebReportContainer>
        <div className={`d-flex flex-row`}>
          <div className={`pr-3 d-flex`}>
            <CandidateProfileBox
              name={person?.name}
              image={person?.image}
              email={person?.email}
            />
          </div>
          <div>
            <WebReportHighlightsSection data={data} />
          </div>
        </div>
        <div className={`pt-3`}>
          <Tabs
            value={currentTabIndex}
            onChange={(e, index) => setCurrentTabIndex(index)}
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="simple tabs example"
            className={style.tabMain}
            innerRef={tabRef}
          >
            {tabs.map(tab => (
              <Tab
                label={tab.label}
                key={tab.key}
                classes={{ selected: style.tabSelected }}
                className={style.tab}
              />
            ))}
          </Tabs>
          {tabs.map((tab, i) => (
            <TabPanel value={currentTabIndex} index={i} key={tab.key}>
              <div className={style.tabContent}>{tab.content}</div>
            </TabPanel>
          ))}
        </div>
      </WebReportContainer>
    </div>
  );
};

export default NewReportWeb;
