import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 40,
    },
    tableStyle: {
      display: 'table',
      width: 238,
    },
    raterDetailsHeader: {
      color: '#FFFFFF',
      height: 32,
      '&>div': {
        padding: 10,
        backgroundColor: '#003562',
        font: 'italic normal bold 14px/18px Muli',
      },
      '& div:first-child': {
        borderRadius: '2px 0px 0px 0px !important',
        borderTop: '1px solid black',
        borderLeft: '1px solid black',
      },
      '& div:last-child': {
        textAlign: 'right',
        borderRadius: '0px 2px 0px 0px !important',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
      },
    },
    raterDetailsTR: {
      display: 'table-row',
    },
    raterDetailsBody: {
      backgroundColor: '#D8E3ED',
      font: 'normal normal bold 14px/18px Muli',
      color: '#0B0B0B',
      height: 61,
      '&>div': {
        padding: 10,
        font: 'normal normal bold 14px/18px Muli',
        verticalAlign: 'middle',
      },
      '& div:first-child': {
        borderLeft: '1px solid black',
        borderBottom: '1px solid black',
      },
      '& div:last-child': {
        textAlign: 'right',
        borderRight: '1px solid black',
        borderBottom: '1px solid black',
      },
    },
    raterDetailsTD: {
      display: 'table-cell',
    },

    completedTable: {
      marginTop: 6,
      '& div:last-child': {
        '& div': {
          borderBottom: '1px solid black',
        },
      },
    },
    CompletedHeader: {
      color: '#0B0B0B',
      height: 32,
      '&>div': {
        padding: 10,
        backgroundColor: '#F4F8FC',
        font: 'normal normal bold 14px/18px Muli',
        borderBottom: '1px solid black',
      },
      '& div:first-child': {
        borderRadius: '2px 0px 0px 0px !important',
        borderTop: '1px solid black',
        borderLeft: '1px solid black',
      },
      '& div:last-child': {
        textAlign: 'right',
        borderRadius: '0px 2px 0px 0px !important',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
      },
    },
    completedTR: {
      display: 'table-row',
    },
    completedBody: {
      backgroundColor: '#EFEFEF',
      font: 'normal normal normal 14px/20px Muli',
      color: '#0B0B0B',
      height: 61,
      '&>div': {
        padding: 10,
        font: 'normal normal normal 14px/20px Muli',
        verticalAlign: 'middle',
      },
      '& div:first-child': {
        borderLeft: '1px solid black',
        '&::before': {
          content: '"●"',
          paddingRight: 5,
        },
        // borderBottom: "1px solid black",
      },
      '& div:last-child': {
        textAlign: 'right',
        borderRight: '1px solid black',
        // borderBottom: "1px solid black",
      },
    },
    completedTD: {
      display: 'table-cell',
    },
    topPadding: {
      '& div': {
        paddingTop: 30,
      },
    },
    note: {
      marginTop: 20,
      width: 238,
      font: 'italic normal normal 12px/15px Muli',
      '& span': {
        font: 'italic normal bold 12px/15px Muli',
      },
    },
  }),
);
const SummaryDataContainer: React.FC<{
  data: any;
}> = props => {
  const classes = useStyles({});

  return (
    <>
      <div className={`${classes.container}`}>
        <div className={`${classes.tableStyle}`}>
          <div className={`${classes.raterDetailsTR} ${classes.raterDetailsHeader}`}>
            <div className={`${classes.raterDetailsTD}`}>Rater Details</div>
            <div className={`${classes.raterDetailsTD}`}>Total</div>
          </div>
          <div className={`${classes.raterDetailsTR} ${classes.raterDetailsBody} `}>
            <div className={`${classes.raterDetailsTD}`}>Invited</div>
            <div className={`${classes.raterDetailsTD}`}>{props.data.invited}</div>
          </div>
        </div>
        <div className={`${classes.tableStyle} ${classes.completedTable}`}>
          <div className={`${classes.raterDetailsTR} ${classes.CompletedHeader}`}>
            <div className={`${classes.raterDetailsTD}`}>Completed</div>
            <div className={`${classes.raterDetailsTD}`}>
              {props.data.completed.total}
            </div>
          </div>
          <div
            className={`${classes.completedTR} ${classes.completedBody} ${classes.topPadding}`}
          >
            <div className={`${classes.completedTD}`}>Immediate Supervisor (IS)</div>
            <div className={`${classes.completedTD}`}>
              {props.data.completed.immediateSupervisor}
            </div>
          </div>
          <div className={`${classes.completedTR} ${classes.completedBody} `}>
            <div className={`${classes.completedTD}`}>Colleagues (Peers)</div>
            <div className={`${classes.completedTD}`}>{props.data.completed.peers}</div>
          </div>
          <div className={`${classes.completedTR} ${classes.completedBody} `}>
            <div className={`${classes.completedTD}`}>Direct Reports (DR)</div>
            <div className={`${classes.completedTD}`}>
              {props.data.completed.directReports}
            </div>
          </div>
          <div className={`${classes.completedTR} ${classes.completedBody} `}>
            <div className={`${classes.completedTD}`}>Others</div>
            <div className={`${classes.completedTD}`}>{props.data.completed.others}</div>
          </div>
        </div>
        <div className={`${classes.note}`}>
          <span>Note:</span> Overall rating is the average rating across all rater
          categories excluding self.
        </div>
      </div>
      {/*<div className={`${classes.raterDetailsTD}`}>
            Colleagues (Peers)
          </div>
          <div className={`${classes.raterDetailsTD}`}>
            3
          </div>
          <div className={`${classes.raterDetailsTD}`}>
            Direct Reports (DR)
          </div>
          <div className={`${classes.raterDetailsTD}`}>
            3
          </div>
          <div className={`${classes.raterDetailsTD}`}>
            Others
          </div>
          <div className={`${classes.raterDetailsTD}`}>
            6
          </div>*/}
    </>
  );
};

export default SummaryDataContainer;
