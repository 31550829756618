import {
  IFeedbackRating,
  INominees,
  IProject,
  IProjectPerson,
  IProjectPersonWithNominees,
  TFeedbackProviderViewData,
  TParticipantViewData,
  TChartColor,
  TChartData,
  TDashboardOverviewData,
  TNomineeRole,
  IProjectCompetency,
  IProjectDescription,
  IGroupResult,
  TCompetencyResultData,
  TFeedbackExportData,
  RatingOrAnswer,
  IProjectQuestion,
  INomineeWithStatus,
  TSurveyStatus,
  IMinRecordsRequired,
  IResult,
} from '@feedr/types';
import * as _ from 'lodash';
import * as Excel from 'exceljs';
import moment from 'moment-timezone';
import { competencyScoreCalculator } from '../reports';
import { toSnakeCase } from '../utils';
import { i18n } from '../i18n';

type TNomineeFullData = IProjectPerson &
  INominees & {
    rater_survey_status?: string;
    ratee_survey_status?: string;
  };

export const chartColors: TChartColor[] = [
  { color: '#06bd58', name: 'Submitted' },
  { color: '#0674bd', name: 'Completed' },
  { color: '#E9911A', name: 'In Progress' },
  { color: '#c7c3bd', name: 'Not Started' },
  { color: '#666666', name: 'N/A' },
];

export const DATE_FMT = 'DD/MM/YYYY';
export const DATETIME_FMT = 'DD/MM/YYYY hh:mm A';

export const createOuterBorder = (
  worksheet,
  startRow,
  startCol,
  endRow,
  endCol,
  borderStyle,
) => {
  for (let i = startRow; i <= endRow; i++) {
    const leftCell = worksheet.getCell(i, startCol);
    leftCell.border = { ...leftCell.border, left: borderStyle };
    const rightCell = worksheet.getCell(i, endCol);
    rightCell.border = { ...rightCell.border, right: borderStyle };
  }
  for (let i = startCol; i <= endCol; i++) {
    const topCell = worksheet.getCell(startRow, i);
    topCell.border = { ...topCell.border, top: borderStyle };
    const bottomCell = worksheet.getCell(endRow, i);
    bottomCell.border = { ...bottomCell.border, bottom: borderStyle };
  }
};

export const transformNomineeStatus = (
  nomineeFullData: TNomineeFullData,
): TSurveyStatus =>
  nomineeFullData.isHidden
    ? 'N/A'
    : nomineeFullData.status === 'CLOSED'
    ? 'Submitted'
    : nomineeFullData.isCompleted
    ? 'Completed'
    : nomineeFullData.feedbackCollected > 0
    ? 'In Progress'
    : 'Not Started';

const transformRaterProgress = (
  nominees: INomineeWithStatus[],
  isClosed: boolean,
): TSurveyStatus =>
  // consider submitted as long as status is CLOSED
  isClosed
    ? 'Submitted'
    : nominees.every(elem => elem.rater_status === 'Completed')
    ? 'Completed'
    : nominees.every(elem => elem.rater_status === 'Not Started')
    ? 'Not Started'
    : 'In Progress';

export const transformParticipantViewResult = (
  people: IProjectPerson[],
  nominees: INomineeWithStatus[],
) =>
  people?.reduce((result, { email, userId, name, status }) => {
    const isRatee = nominees?.some(nominee => nominee.ratee == email);
    if (isRatee) {
      // feedback provider that rate this ratee excluding Self
      const personSurveyToDo = nominees.filter(
        nominee => nominee.ratee == email && nominee.role !== 'Self',
      );
      const total = personSurveyToDo.length;
      // consider hidden (N/A) nominee as submitted also because candidate unable to revert once hidden
      const completed = personSurveyToDo.filter(elem =>
        ['Submitted', 'N/A'].includes(elem.rater_status),
      ).length;
      const isClosed = status === 'CLOSED';
      const selfSurveyToDo = nominees.filter(
        nominee => nominee.email == email && !nominee.isHidden,
      );
      const selfProgress = transformRaterProgress(selfSurveyToDo, isClosed);

      result.push({ id: userId, name, email, total, completed, selfProgress });
    }

    return result;
  }, []) ?? [];

export const transformFeedbackProviderViewResult = (
  people: IProjectPerson[],
  nominees: INomineeWithStatus[],
) =>
  people?.reduce((result, { email, name, status }) => {
    const isFeedbackProvider = nominees?.some(
      nominee => nominee.email == email && nominee.role !== 'Self',
    );
    if (isFeedbackProvider) {
      // ratee that this feedback provider needs to rate excluding Self
      const personSurveyToDo = nominees.filter(
        nominee => nominee.email == email && nominee.role !== 'Self',
      );
      const total = personSurveyToDo.length;
      const isClosed = status === 'CLOSED';
      // consider hidden (N/A) nominee as submitted also because candidate unable to revert once hidden
      const completed = personSurveyToDo.filter(elem =>
        ['Submitted', 'N/A'].includes(elem.rater_status),
      ).length;
      const selfSurveyToDo = nominees.filter(
        nominee => nominee.email == email && !nominee.isHidden,
      );
      const raterProgress = transformRaterProgress(selfSurveyToDo, isClosed);

      result.push({ id: email, name, total, completed, raterProgress });
    }

    return result;
  }, []) ?? [];

export const transformDashboardOverviewResult = (
  feedbacks: IFeedbackRating[],
  project: IProject,
  participantViewResult: TParticipantViewData[],
  feedbackProviderViewResult: TFeedbackProviderViewData[],
) => {
  const lastUpdated = feedbacks
    .filter(x => x.updatedAt)
    .map(x => x.updatedAt.toDate())
    .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)) // sort iso dates
    .reverse()[0];

  // count by individuals
  const totalParticipants = participantViewResult.length;
  const totalFeedbackProviders = feedbackProviderViewResult.length;
  const getRateeCountByStatus = (status: TSurveyStatus) =>
    participantViewResult.filter(elem => elem.selfProgress === status).length;
  const getRaterCountByStatus = (status: TSurveyStatus) =>
    feedbackProviderViewResult.filter(elem => elem.raterProgress === status).length;
  const colors = chartColors.map(elem => elem.name);

  const participantProgress = chartColors
    .map(({ name }) => {
      return {
        Submitted: { name, value: getRateeCountByStatus('Submitted') },
        Completed: { name, value: getRateeCountByStatus('Completed') },
        'In Progress': { name, value: getRateeCountByStatus('In Progress') },
        'Not Started': { name, value: getRateeCountByStatus('Not Started') },
        'N/A': { name, value: getRateeCountByStatus('N/A') },
      }[name];
    })
    .sort(
      (a: TChartData, b: TChartData) => colors.indexOf(b.name) - colors.indexOf(a.name),
    );

  const feedbackProviderProgress = chartColors
    .map(({ name }) => {
      return {
        Submitted: { name, value: getRaterCountByStatus('Submitted') },
        Completed: { name, value: getRaterCountByStatus('Completed') },
        'In Progress': { name, value: getRaterCountByStatus('In Progress') },
        'Not Started': { name, value: getRaterCountByStatus('Not Started') },
        'N/A': { name, value: getRaterCountByStatus('N/A') },
      }[name];
    })
    .filter(x => x)
    .sort(
      (a: TChartData, b: TChartData) => colors.indexOf(b.name) - colors.indexOf(a.name),
    );

  const now = new Date();
  const startDate = project?.startDate ? project?.startDate.toDate() : null;
  const endDate = project?.endDate ? project?.endDate.toDate() : null;

  return {
    name: project?.name || '',
    status:
      (!startDate && !endDate) || (startDate <= now && endDate >= now)
        ? 'ACTIVE'
        : 'INACTIVE',
    lastUpdated,
    startDate,
    endDate,
    totalParticipants,
    totalFeedbackProviders,
    participantProgress,
    feedbackProviderProgress,
  };
};

export const transformCompetencyResult = (
  people: IProjectPerson[],
  nomination: INominees[],
  feedbacks: IFeedbackRating[],
  competencies: IProjectCompetency[],
  descriptions: IProjectDescription[],
  groupResult: IGroupResult,
  minReqRaters?: IMinRecordsRequired[],
  results?: IResult[],
) => {
  const categories = _.uniq(
    ['Self', 'Rater Average', 'Group Rater Average', 'Group Average'].concat(
      nomination.map(nominee => nominee.role),
    ),
  );
  const categoryScoreMap = {
    Self: 'selfScore',
    Manager: 'managersAverage',
    Peer: 'peersAverage',
    'Direct Report': 'directReportsAverage',
    Others: 'othersAverage',
    'Rater Average': 'raterAverage',
    'Group Rater Average': 'groupRaterAverage',
    'Group Average': 'groupAverage',
  };
  return people
    .filter(person => nomination.some(nominee => nominee.ratee === person.email))
    .map(person => {
      const rolesExcludingSelfAndGroup = _.uniq(
        Object.keys(categoryScoreMap)
          .map(k => k)
          .filter(role => !['Self', 'Group'].includes(role)),
      );
      const completedNomineesByRole = nomination
        .filter(nominee => nominee.isCompleted && nominee.ratee === person.email)
        .reduce(
          (acc, cur: INominees) => ({
            ...acc,
            [cur.role]: [...(acc[cur.role] ?? []), cur.email],
          }),
          {},
        );

      const counts = rolesExcludingSelfAndGroup.reduce(
        (acc, cur) => ({
          ...acc,
          [`counts_of_${cur}`]: completedNomineesByRole[cur]?.length,
        }),
        {},
      );

      let isSaved = results.find(x => x.id === person.email) ? true : false;
      let competencyScores;
      // let competencyScores = results.find(x => x.id === person.email) as IResult | ICompetencyScore;
      if (!isSaved) {
        const closedPeopleEmails = people
          .filter(person => person.status === 'CLOSED')
          .map(person => person.email);
        const flatFeedbacks = feedbacks.filter(
          feedback =>
            feedback.ratee === person.email &&
            feedback.competencyId &&
            closedPeopleEmails.includes(feedback.rater),
        );
        const nomineesByRole = nomination
          .filter(nominee => nominee.ratee === person.email)
          .reduce(
            (acc, cur: INominees) => ({
              ...acc,
              [cur.role]: [...(acc[cur.role] ?? []), cur.email],
            }),
            {},
          );
        competencyScores = competencyScoreCalculator(
          flatFeedbacks,
          person.email,
          competencies,
          descriptions,
          nomineesByRole,
          groupResult,
          minReqRaters ?? null,
        );
      } else {
        competencyScores = results.find(x => x.id === person.email);
      }
      // map each category to competency and their scores using custom keys
      const result = competencies.reduce((result, competency) => {
        let competencyScore = null;
        if (isSaved) {
          let competencyName = toSnakeCase(competency.name);
          competencyScore = JSON.parse(competencyScores.scores)[competencyName];
        } else {
          competencyScore = competencyScores.find(
            competencyScore => competencyScore.id === competency.id,
          );
        }
        const competencyResult = categories.reduce((acc, category) => {
          let score = competencyScore.score[categoryScoreMap[category]];
          // always get the latest group averages from group result
          // group averages in individual result might not be up-to-date if there's delay from onPeopleInfoWrite trigger
          if (category === 'Group Rater Average') {
            score =
              groupResult?.competencies?.[competency.id]?.groupRaterAverage || score;
          } else if (category === 'Group Average') {
            score = groupResult?.competencies?.[competency.id]?.groupAverage || score;
          }
          return {
            ...acc,
            [`${competency.id}_${category}`]: score > 0 ? score : undefined,
            ...counts,
          };
        }, {});
        return { ...result, ...competencyResult };
      }, {});
      return {
        name: person.name,
        email: person.email,
        ...result,
      };
    });
};

export const transformFeedbackExport = (
  nomination: INominees[],
  feedbacks: RatingOrAnswer[],
  competencies: IProjectCompetency[],
  descriptions: IProjectDescription[],
  questions: IProjectQuestion[],
) => {
  return feedbacks.map(feedback => {
    const isRating = 'rating' in feedback;
    const nominee = nomination.find(
      nominee => nominee.ratee === feedback.ratee && nominee.email === feedback.rater,
    );
    const competency =
      'competencyId' in feedback &&
      competencies.find(competency => competency.id === feedback.competencyId);
    const description =
      'descriptionId' in feedback &&
      descriptions.find(description => description.id === feedback.descriptionId);
    const question =
      'questionId' in feedback &&
      questions.find(question => question.id === feedback.questionId);
    return {
      ratee: feedback.ratee,
      rater: feedback.rater,
      role: nominee?.role,
      competency: competency?.name,
      competency_order: competency && competency.orderIndex + 1,
      question: isRating
        ? JSON.parse(description.detail)?.blocks[0].text.trim()
        : question?.title,
      question_order: isRating
        ? competency?.descriptionIds.indexOf(description.id) + 1
        : question?.orderIndex + 1,
      rating: isRating ? parseInt(feedback['rating']) : undefined,
      comment: feedback['comment'],
      answer: feedback['answer'],
    };
  });
};

export const exportPeopleStatusToExcel = (
  people: IProjectPersonWithNominees[],
  participantViewResult: TParticipantViewData[],
  feedbackProviderViewResult: TFeedbackProviderViewData[],
  dashboardOverviewResult: TDashboardOverviewData,
) => {
  const workbook = new Excel.Workbook();
  const statuses = chartColors.map(x => x.name).reverse();

  // Sheet 1: Adding Dashboard Overview Worksheet
  const worksheetOverall = workbook.addWorksheet(
    `${i18n.t('overall_rating_completion')}`,
  );
  worksheetOverall.columns = [
    {
      header: `${i18n.t('details')}`,
      key: 'details',
      width: 25,
    },
    {
      header: `${i18n.t('project')} ${i18n.t('status')}`,
      key: 'status',
      width: 20,
    },
    { header: '', key: 'percentage', width: 25, style: { numFmt: '0.00%' } },
  ].map(item => {
    return {
      ...item,
      style: {
        font: {
          size: 11,
        },
      },
    } as Partial<Excel.Column>;
  });

  worksheetOverall.lastRow.getCell(2).style.alignment = {
    horizontal: 'right',
  };
  worksheetOverall.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFb9b9b9' },
  };

  worksheetOverall.addRow({
    details: `${i18n.t('status')}`,
    status: `${i18n.t('project_statuses.' + dashboardOverviewResult.status)}`,
  });
  worksheetOverall.lastRow.getCell(2).style.alignment = {
    horizontal: 'right',
  };

  worksheetOverall.addRow({
    details: `${i18n.t('project')} ${i18n.t('start_date')}`,
    status:
      dashboardOverviewResult.startDate &&
      moment(dashboardOverviewResult.startDate)
        .tz('Asia/Kuala_Lumpur')
        .format(DATE_FMT),
  });
  worksheetOverall.lastRow.getCell(2).style.alignment = {
    horizontal: 'right',
  };

  worksheetOverall.addRow({
    details: `${i18n.t('project')} ${i18n.t('end_date')}`,
    status:
      dashboardOverviewResult.endDate &&
      moment(dashboardOverviewResult.endDate)
        .tz('Asia/Kuala_Lumpur')
        .format(DATE_FMT),
  });
  worksheetOverall.lastRow.getCell(2).style.alignment = {
    horizontal: 'right',
  };

  worksheetOverall.addRow({
    details: `${i18n.t('last_updated')}`,
    status:
      dashboardOverviewResult.lastUpdated &&
      moment(dashboardOverviewResult.lastUpdated)
        .tz('Asia/Kuala_Lumpur')
        .format(DATE_FMT),
  });
  worksheetOverall.lastRow.getCell(2).style.alignment = {
    horizontal: 'right',
  };

  worksheetOverall.addRow({
    details: `${i18n.t('total_participants')}`,
    status: people.length,
  });

  worksheetOverall.addRow({
    details: `${i18n.t('number_of_ratees')}`,
    status: dashboardOverviewResult.totalParticipants,
  });

  worksheetOverall.addRow({
    details: `${i18n.t('number_of_raters')}`,
    status: dashboardOverviewResult.totalFeedbackProviders,
  });
  worksheetOverall.addRow({});

  worksheetOverall.addRow({
    details: `${i18n.t('ratees')}`,
    status: `${i18n.t('count')}`,
    percentage: `${i18n.t('percentage')}`,
  });
  worksheetOverall.lastRow.getCell(2).style.alignment = {
    horizontal: 'right',
  };
  worksheetOverall.lastRow.getCell(3).style.alignment = {
    horizontal: 'right',
  };
  worksheetOverall.lastRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFb9b9b9' },
  };

  // dont show N/A if no count, cause this is a rare case
  let hasNA = people.some(person => person.nominees.some(nominee => nominee.isHidden));
  const insertCountData = (progressData: TChartData[], totalCount: number) => {
    progressData
      .filter(item => {
        if (item.name === 'N/A') {
          if (item.value === 0) {
            return false;
          }
          hasNA = true;
        }
        return true;
      })
      .map(item => {
        worksheetOverall.addRow({
          details: `${i18n.t('survey_statuses.' + item.name)}`,
          status: item.value,
          percentage: `${((item.value / totalCount) * 100).toFixed(2)}%`,
        });
        worksheetOverall.lastRow.getCell(3).style.alignment = {
          horizontal: 'right',
        };
      });
  };

  insertCountData(
    dashboardOverviewResult.participantProgress,
    participantViewResult.length,
  );
  worksheetOverall.addRow({});

  worksheetOverall.addRow({
    details: `${i18n.t('raters')}`,
    status: `${i18n.t('count')}`,
    percentage: `${i18n.t('percentage')}`,
  });
  worksheetOverall.lastRow.getCell(2).style.alignment = {
    horizontal: 'right',
  };
  worksheetOverall.lastRow.getCell(3).style.alignment = {
    horizontal: 'right',
  };
  worksheetOverall.lastRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFb9b9b9' },
  };

  insertCountData(
    dashboardOverviewResult.feedbackProviderProgress,
    feedbackProviderViewResult.length,
  );
  worksheetOverall.addRow({});

  worksheetOverall.addRow({
    details: `${i18n.t('legends')}`,
    status: `${i18n.t('description')}`,
  });
  worksheetOverall.lastRow.getCell(2).style.alignment = {
    horizontal: 'right',
  };
  worksheetOverall.lastRow.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFb9b9b9' },
  };

  const legends = [
    {
      details: `${i18n.t('ratees')}`,
      status: `${i18n.t('definitions.ratees')}`,
    },
    {
      details: `${i18n.t('raters')}`,
      status: `${i18n.t('definitions.raters')}`,
    },
    {}, // empty row
    {
      details: `${i18n.t('survey_statuses.Not Started')}`,
      status: `${i18n.t('definitions.not_started')}`,
    },
    {
      details: `${i18n.t('survey_statuses.In Progress')}`,
      status: `${i18n.t('definitions.in_progress')}`,
    },
    {
      details: `${i18n.t('survey_statuses.Completed')}`,
      status: `${i18n.t('definitions.completed')}`,
    },
    {
      details: `${i18n.t('survey_statuses.Submitted')}`,
      status: `${i18n.t('definitions.submitted')}`,
    },
    {
      details: `${i18n.t('survey_statuses.N/A')}`,
      status: `${i18n.t('definitions.not_applicable')}`,
    },
    {
      details: `${i18n.t('survey_statuses.Unknown')}`,
      status: `${i18n.t('definitions.unknown')}`,
    },
    {}, // empty row
    { details: `${i18n.t('definitions.remark')}` },
  ];
  if (!hasNA) {
    legends.splice(7, 2);
  }
  legends.map(item => {
    worksheetOverall.addRow(item);
  });

  // Sheet 2: Adding Summary Ratee Based Worksheet
  const worksheetSummaryRatee = workbook.addWorksheet(
    `${i18n.t('summary_based_on_ratee')}`,
  );
  worksheetSummaryRatee.columns = [
    {
      header: `${i18n.t('no')}`,
      key: 'no',
      width: 5,
    },
    {
      header: `${i18n.t('ratee')}`,
      key: 'ratee',
      width: 35,
    },
    {
      header: `${i18n.t('ratee')} ${i18n.t('email')}`,
      key: 'ratee_email',
      width: 35,
    },
    {
      header: `${i18n.t('ratee')} ${i18n.t('status')}`,
      key: 'ratee_status',
      width: 20,
    },
    {
      header: `${i18n.t('number_of_raters')}`,
      key: 'no_of_raters',
      width: 18,
    },
    {
      header: `${i18n.t('raters')} ${i18n.t('submission_rate')}`,
      key: 'raters_submission_rate',
      width: 25,
      style: { alignment: { horizontal: 'right' } },
    },
  ].map(item => {
    return {
      ...item,
      style: {
        ...item.style,
        font: {
          size: 11,
        },
      },
    } as Partial<Excel.Column>;
  });

  worksheetSummaryRatee.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFb9b9b9' },
  };

  participantViewResult.map((person, key) => {
    worksheetSummaryRatee.addRow({
      no: key + 1,
      ratee: person.name,
      ratee_email: person.email,
      ratee_status: `${i18n.t('survey_statuses.' + person.selfProgress)}`,
      no_of_raters: person.total,
      raters_submission_rate: `${person.completed}/${person.total} (${(
        (person.completed / person.total) *
        100
      ).toFixed(2)}%)`,
    });

    if (key % 2 !== 0) {
      worksheetSummaryRatee.lastRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFf2f2f2' },
      };
    }
  });

  // Sheet 3: Adding Summary Rater Based Worksheet
  const worksheetSummaryRater = workbook.addWorksheet(
    `${i18n.t('summary_based_on_rater')}`,
  );
  worksheetSummaryRater.columns = [
    {
      header: `${i18n.t('no')}`,
      key: 'no',
      width: 5,
    },
    {
      header: `${i18n.t('rater')}`,
      key: 'rater',
      width: 35,
    },
    {
      header: `${i18n.t('rater')} ${i18n.t('email')}`,
      key: 'rater_email',
      width: 35,
    },
    {
      header: `${i18n.t('rater')} ${i18n.t('status')}`,
      key: 'rater_status',
      width: 20,
    },
    {
      header: `${i18n.t('number_of_ratees')}`,
      key: 'no_of_ratees',
      width: 18,
    },
  ].map(item => {
    return {
      ...item,
      style: {
        font: {
          size: 11,
        },
      },
    } as Partial<Excel.Column>;
  });

  worksheetSummaryRater.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFb9b9b9' },
  };

  feedbackProviderViewResult.map((person, key) => {
    worksheetSummaryRater.addRow({
      no: key + 1,
      rater: person.name,
      rater_email: person.id,
      rater_status: `${i18n.t('survey_statuses.' + person.raterProgress)}`,
      no_of_ratees: person.total,
    });

    if (key % 2 !== 0) {
      worksheetSummaryRater.lastRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFf2f2f2' },
      };
    }
  });

  // Sheet 4: Adding Breakdown Ratee Based Worksheet
  const worksheet = workbook.addWorksheet(`${i18n.t('breakdown_based_on_ratee')}`);
  worksheet.columns = [
    {
      header: `${i18n.t('no')}`,
      key: 'no',
      width: 5,
    },
    {
      header: `${i18n.t('ratee')}`,
      key: 'ratee',
      width: 35,
    },
    {
      header: `${i18n.t('ratee')} ${i18n.t('email')}`,
      key: 'ratee_email',
      width: 35,
    },
    {
      header: `${i18n.t('ratee')} ${i18n.t('status')}`,
      key: 'ratee_status',
      width: 20,
    },
    { header: `${i18n.t('rater')}`, key: 'rater', width: 35 },
    {
      header: `${i18n.t('rater')} ${i18n.t('email')}`,
      key: 'rater_email',
      width: 35,
    },
    { header: `${i18n.t('rater_of')} ${i18n.t('role')}`, key: 'role', width: 15 },
    {
      header: `${i18n.t('rater_of')} ${i18n.t('survey')} ${i18n.t('status')}`,
      key: 'rater_survey_status',
      width: 15,
    },
  ].map(item => {
    return {
      ...item,
      style: {
        font: {
          size: 11,
        },
      },
    } as Partial<Excel.Column>;
  });

  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFb9b9b9' },
  };

  let countPeople = 1;

  people?.map((person, key) => {
    if (!_.isEmpty(person.nominees)) {
      worksheet.addRow({
        no: countPeople,
        ratee: person.name,
        ratee_email: person.email,
        ratee_status: `${i18n.t(
          'survey_statuses.' +
            (_.find(participantViewResult, { email: person.email })?.selfProgress ||
              _.find(feedbackProviderViewResult, { id: person.email })?.raterProgress),
        )}`,
      });
      if (countPeople % 2 === 0) {
        worksheet.lastRow.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFf2f2f2' },
        };
        worksheet.lastRow.border = {
          top: {
            style: 'thin',
            color: {
              argb: 'FF626262',
            },
          },
        };
      }

      const nominees = person?.nominees
        ?.map(nominee => {
          const foundItem = _.find(people, { email: nominee.email });
          const nomineeFullData: TNomineeFullData = {
            ...nominee,
            ...foundItem,
          };
          nomineeFullData.rater_survey_status = transformNomineeStatus(nomineeFullData);
          return nomineeFullData;
        })
        // ? sort by status, then role
        .sort((a, b) => {
          return (
            statuses.indexOf(b.rater_survey_status) -
              statuses.indexOf(a.rater_survey_status) || b.role.localeCompare(a.role)
          );
        });
      // ? keep Self nominee at top
      const selfIdx = nominees.findIndex(nominee => nominee.role === 'Self');
      if (selfIdx !== -1) {
        nominees.unshift(nominees[selfIdx]);
        nominees.splice(selfIdx + 1, 1);
      }

      nominees.map((nomineeFullData, nomineeKey) => {
        worksheet.addRow({
          rater: nomineeFullData.name,
          rater_email: nomineeFullData.email,
          role: `${i18n.t(
            'roles.' + (nomineeFullData.isHidden ? 'Unknown' : nomineeFullData.role),
          )}`,
          rater_survey_status: `${i18n.t(
            'survey_statuses.' + nomineeFullData.rater_survey_status,
          )}`,
        });
        if (countPeople % 2 === 0) {
          worksheet.lastRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFf2f2f2' },
          };
        }
        if (nomineeKey === person.nominees.length - 1) {
          worksheet.lastRow.border = {
            bottom: {
              style: 'thin',
              color: {
                argb: 'FF626262',
              },
            },
          };
        }
      });
      countPeople++;
    }

    return person;
  });

  // Sheet 5: Adding Breakdown Rater Based Worksheet
  const worksheet2 = workbook.addWorksheet(`${i18n.t('breakdown_based_on_rater')}`);

  worksheet2.columns = [
    {
      header: `${i18n.t('no')}`,
      key: 'no',
      width: 5,
    },
    {
      header: `${i18n.t('rater')}`,
      key: 'rater',
      width: 35,
    },
    {
      header: `${i18n.t('rater')} ${i18n.t('email')}`,
      key: 'rater_email',
      width: 35,
    },
    {
      header: `${i18n.t('rater')} ${i18n.t('status')}`,
      key: 'rater_status',
      width: 20,
    },
    { header: `${i18n.t('ratee')}`, key: 'ratee', width: 35 },
    {
      header: `${i18n.t('ratee')} ${i18n.t('email')}`,
      key: 'ratee_email',
      width: 35,
    },
    { header: `${i18n.t('rater_of')} ${i18n.t('role')}`, key: 'role', width: 15 },
    {
      header: `${i18n.t('ratee_of')} ${i18n.t('survey')} ${i18n.t('status')}`,
      key: 'ratee_survey_status',
      width: 15,
    },
  ].map(item => {
    return {
      ...item,
      style: {
        font: {
          size: 11,
        },
      },
    } as Partial<Excel.Column>;
  });

  worksheet2.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFb9b9b9' },
  };

  people?.map((person, key) => {
    worksheet2.addRow({
      no: key + 1,
      rater: person.name,
      rater_email: person.email,
      rater_status: `${i18n.t(
        'survey_statuses.' +
          (_.find(feedbackProviderViewResult, { id: person.email })?.raterProgress ||
            _.find(participantViewResult, { email: person.email })?.selfProgress),
      )}`,
    });

    if (key % 2 !== 0) {
      worksheet2.lastRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFf2f2f2' },
      };
      worksheet2.lastRow.border = {
        top: {
          style: 'thin',
          color: {
            argb: 'FF626262',
          },
        },
      };
    }

    const toRate = people
      ?.map((person2, key2) => {
        const foundItem = _.find(person2.nominees, { email: person.email });
        return (
          foundItem && {
            ...foundItem,
            role: foundItem.isHidden ? 'Unknown' : foundItem.role,
            ratee_email: person2.email,
            ratee_name: person2.name,
            ratee_survey_status: transformNomineeStatus({ ...foundItem, ...person }),
          }
        );
      })
      .filter(i => i)
      // ? sort by status, then role
      .sort((a, b) => {
        return (
          statuses.indexOf(b.ratee_survey_status) -
            statuses.indexOf(a.ratee_survey_status) || b.role.localeCompare(a.role)
        );
      });

    if (!_.isEmpty(toRate)) {
      // ? keep Self nominee at top
      const selfIdx = toRate.findIndex(nominee => nominee.role === 'Self');
      if (selfIdx !== -1) {
        toRate.unshift(toRate[selfIdx]);
        toRate.splice(selfIdx + 1, 1);
      }

      toRate.map((toRateItem, toRateKey) => {
        worksheet2.addRow({
          ratee: toRateItem.ratee_name,
          ratee_email: toRateItem.ratee_email,
          role: `${i18n.t('roles.' + toRateItem.role)}`,
          ratee_survey_status: `${i18n.t(
            'survey_statuses.' + toRateItem.ratee_survey_status,
          )}`,
        });
        if (key % 2 !== 0) {
          worksheet2.lastRow.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFf2f2f2' },
          };
        }
        if (toRateKey === toRate.length - 1) {
          worksheet2.lastRow.border = {
            bottom: {
              style: 'thin',
              color: {
                argb: 'FF626262',
              },
            },
          };
        }
      });
    } else {
      worksheet2.lastRow.border = {
        bottom: {
          style: 'thin',
          color: {
            argb: 'FF626262',
          },
        },
      };
    }

    return person;
  });

  return workbook;
};

export const exportProjectScoresToExcel = (
  people: IProjectPersonWithNominees[],
  competencies: IProjectCompetency[],
  competencyResult: TCompetencyResultData[],
  rolesExcludingSelf: TNomineeRole[],
  feedbacks: TFeedbackExportData[],
  isPrivate,
) => {
  const workbook = new Excel.Workbook();

  // ? Adding Legends Worksheet
  const worksheetLegends = workbook.addWorksheet(`${i18n.t('legends')}`);
  worksheetLegends.columns = [
    { header: `${i18n.t('legends')}`, key: 'legend', width: 20 },
    { header: '', key: 'definition', width: 100 },
  ].map(item => {
    return {
      ...item,
      style: {
        font: {
          size: 11,
        },
      },
    } as Partial<Excel.Column>;
  });
  worksheetLegends.addRow({});
  const legends = [
    { legend: `${i18n.t('roles.Self')}`, definition: `${i18n.t('definitions.self')}` },
    {
      legend: `${i18n.t('roles.Manager')}`,
      definition: `${i18n.t('definitions.manager')}`,
    },
    { legend: `${i18n.t('roles.Peer')}`, definition: `${i18n.t('definitions.peer')}` },
    {
      legend: `${i18n.t('roles.Direct Report')}`,
      definition: `${i18n.t('definitions.direct_report')}`,
    },
    {
      legend: `${i18n.t('roles.Others')}`,
      definition: `${i18n.t('definitions.others')}`,
    },
    {
      legend: `${i18n.t('roles.Rater Average')}`,
      definition: `${i18n.t('definitions.rater_average')}`,
    },
    {
      legend: `${i18n.t('roles.Group Rater Average')}`,
      definition: `${i18n.t('definitions.group_rater_average')}`,
    },
    {
      legend: `${i18n.t('roles.Group Average')}`,
      definition: `${i18n.t('definitions.group_average')}`,
    },
  ];
  legends.map(item => {
    worksheetLegends.addRow(item);
  });
  // ? adding summary worksheet
  const worksheetSummary = workbook.addWorksheet(`${i18n.t('submission_summary')}`);
  worksheetSummary.mergeCells('A1', 'B1');
  worksheetSummary.getCell('A1').value = `${i18n.t('ratee')}`;
  worksheetSummary.mergeCells('C1:F1');
  worksheetSummary.getCell('C1').value = `${i18n.t('number_of_submitted_raters')}`;
  worksheetSummary.getCell('C1').alignment = {
    vertical: 'middle',
    horizontal: 'center',
  };
  worksheetSummary.getCell('A2').value = `${i18n.t('name')}`;
  worksheetSummary.getCell('B2').value = `${i18n.t('email')}`;
  const borderStyle0 = { style: 'medium' };
  createOuterBorder(worksheetSummary, 1, 1, 1, 3, borderStyle0);
  createOuterBorder(worksheetSummary, 2, 1, 2, 6, borderStyle0);

  const roles1 = ['Manager', 'Peer', 'Direct Report', 'Others'];
  const categories1 = [].concat(roles1);
  const categoryCount1 = categories1.length;
  const startColIdx = categoryCount1 - 1;
  categories1.map((category, idx) => {
    worksheetSummary.getCell(2, startColIdx + idx).value = `${i18n.t(
      'roles.' + category,
    )}`;
  });
  let countsColumns = [
    { key: 'name', width: 35 },
    { key: 'email', width: 35 },
  ];
  let countsHeaderColumns = roles1.map(category => ({
    key: `counts_of_${category}`,
    width: 12,
  }));

  countsHeaderColumns.forEach(competencyColumn => {
    countsColumns = countsColumns.concat(competencyColumn);
  });
  worksheetSummary.columns = countsColumns.map(item => {
    return {
      ...item,
      style: { font: { size: 11 } },
    } as Partial<Excel.Column>;
  });
  competencyResult.map(result => {
    worksheetSummary.addRow(result);
  });

  // ? Adding Average Worksheet
  const worksheetAverage = workbook.addWorksheet(`${i18n.t('average')}`);
  worksheetAverage.mergeCells('A1', 'B1');
  worksheetAverage.getCell('A1').value = `${i18n.t('ratee')}`;
  worksheetAverage.getCell('A2').value = `${i18n.t('name')}`;
  worksheetAverage.getCell('B2').value = `${i18n.t('email')}`;
  const borderStyle = { style: 'medium' };
  createOuterBorder(worksheetAverage, 1, 1, 1, 2, borderStyle);
  createOuterBorder(worksheetAverage, 2, 1, 2, 2, borderStyle);

  // Self + Group + any roles that are set in project (keep order)
  const roles = [
    'Manager',
    'Peer',
    'Direct Report',
    'Others',
  ].filter((role: TNomineeRole) => rolesExcludingSelf.includes(role));
  const categories = ['Self']
    .concat(roles)
    .concat(['Rater Average', 'Group Rater Average', 'Group Average']);
  const categoryCount = categories.length;

  const competencyColumns =
    competencies
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map(competency => {
        // competency name header
        const startColIdx = 3 + competency.orderIndex * categoryCount;
        const endColIdx = startColIdx + categoryCount - 1;
        worksheetAverage.mergeCells(1, startColIdx, 1, endColIdx);
        worksheetAverage.getCell(1, startColIdx).value = competency.name;
        createOuterBorder(worksheetAverage, 1, startColIdx, 1, endColIdx, borderStyle);
        createOuterBorder(worksheetAverage, 2, startColIdx, 2, endColIdx, borderStyle);

        // category header
        const columns = categories.map(category => ({
          key: `${competency.id}_${category}`,
          width: 12,
          // style: { numFmt: '0.0' },
        }));
        categories.map((category, idx) => {
          worksheetAverage.getCell(2, startColIdx + idx).value = `${i18n.t(
            'roles.' + category,
          )}`;
        });
        return columns;
      }) || [];
  let columns = [
    { key: 'name', width: 35 },
    { key: 'email', width: 35 },
  ];
  competencyColumns.forEach(competencyColumn => {
    columns = columns.concat(competencyColumn);
  });
  worksheetAverage.columns = columns.map(item => {
    return {
      ...item,
      style: { font: { size: 11 } },
    } as Partial<Excel.Column>;
  });

  competencyResult.map(result => {
    worksheetAverage.addRow(result);
  });

  // ? Adding Individual Worksheet
  if (isPrivate === 'yes') {
    const worksheetIndividual = workbook.addWorksheet(`${i18n.t('individual')}`);
    worksheetIndividual.columns = [
      { header: `${i18n.t('ratee')}`, key: 'ratee', width: 35 },
      { header: `${i18n.t('rater')}`, key: 'rater', width: 35 },
      { header: `${i18n.t('role')}`, key: 'role', width: 15 },
      { header: `${i18n.t('competency')}`, key: 'competency', width: 40 },
      { header: `${i18n.t('question')}`, key: 'question', width: 40 },
      { header: `${i18n.t('order')}`, key: 'question_order', width: 6 },
      { header: `${i18n.t('rating')}`, key: 'rating', width: 6 },
      // only add comment if there is
      ...(feedbacks.some(feedback => feedback.comment)
        ? [{ header: `${i18n.t('comment')}`, key: 'comment', width: 40 }]
        : []),
    ].map(item => {
      return {
        ...item,
        style: { font: { size: 11 } },
      } as Partial<Excel.Column>;
    });
    worksheetIndividual.getRow(1).font = { size: 11, bold: true };

    // sort feedback rating by ratee, rater, competency, description
    _.sortBy(
      feedbacks.filter(feedback => feedback.rating !== undefined), // there are scores that is 0
      ['ratee', 'rater', 'competency_order', 'question_order'],
    ).map(feedbackRating => {
      worksheetIndividual.addRow({
        ...feedbackRating,
        role: `${i18n.t('roles.' + feedbackRating.role)}`,
      });
    });
    if (feedbacks.some(feedback => feedback.answer)) {
      const worksheetOpinions = workbook.addWorksheet(`${i18n.t('opinions')}`);
      worksheetOpinions.columns = [
        { header: `${i18n.t('ratee')}`, key: 'ratee', width: 35 },
        { header: `${i18n.t('rater')}`, key: 'rater', width: 35 },
        { header: `${i18n.t('role')}`, key: 'role', width: 15 },
        { header: `${i18n.t('question')}`, key: 'question', width: 40 },
        { header: `${i18n.t('order')}`, key: 'question_order', width: 6 },
        { header: `${i18n.t('answer')}`, key: 'answer', width: 40 },
      ].map(item => {
        return {
          ...item,
          style: { font: { size: 11 } },
        } as Partial<Excel.Column>;
      });
      worksheetOpinions.getRow(1).font = { size: 11, bold: true };

      // sort feedback answer by ratee, rater, question
      _.sortBy(
        feedbacks.filter(feedback => feedback.answer),
        ['ratee', 'rater', 'question_order'],
      ).map(feedbackAnswer => {
        worksheetOpinions.addRow({
          ...feedbackAnswer,
          role: `${i18n.t('roles.' + feedbackAnswer.role)}`,
        });
      });
    }
  }

  return workbook;
};
