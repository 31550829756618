import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  spinnerWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  spinnerText: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const LoadingPage: React.FC<{ msg: string }> = ({ msg }) => {
  const classes = useStyles({});

  return (
    <div className={classes.spinnerWrapper}>
      <LinearProgress />
      <Typography variant={'h4'} className={classes.spinnerText}>
        {msg}
      </Typography>
    </div>
  );
};

export default LoadingPage;
