import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { BulbSvg } from '../../assets/BulbSvg';
import { Header } from '../Header/Header';
import { HighlightBox } from '../HighlightBox/HighlightBox';
import { ReportHighlightData } from './stores/reportHighlightDataStore';
import * as _ from 'lodash';
import { ICompetencyScore } from '@feedr/types';
import { SummarySection } from '../SummarySection/SummarySection';
import { i18n } from '@feedr/helpers';

interface ReportHighlightPageProps {
  data: ReportHighlightData;
  competenciesWithScore: ICompetencyScore[];
}

const useStyle = makeStyles((theme: Theme) => ({
  reportHighlightPage: {
    pageBreakAfter: 'always',
  },
  reportHighlightSection: {},
  summarySection: {},
  gridBox: {
    display: `grid`,
    gridTemplateColumns: `repeat(3, 1fr)`,
    gridTemplateRows: `repeat(2, 1fr)`,
    gridColumnGap: `10px`,
    gridRowGap: `10px`,
  },
  section: {
    paddingTop: 30,
  },
  legendItemsContainer: {
    pageBreakInside: 'avoid',
  },
}));

export const ReportHighlightPage: React.FC<ReportHighlightPageProps> = props => {
  const { data, competenciesWithScore } = props;
  const style = useStyle();

  return (
    <div className={`w-100 h-100 ${style.reportHighlightPage}`}>
      <div className={style.reportHighlightSection}>
        <Header
          icon={<BulbSvg />}
          title={i18n.t('report.report_highlights')}
          description={i18n.t('report.report_highlights_description')}
        />
        <div className={style.gridBox}>
          {data &&
            _.map(data, (data, key) => {
              return (
                <HighlightBox
                  key={`highlight-box-${key}`}
                  title={data.title}
                  description={data.description}
                  data={data.data}
                />
              );
            })}
        </div>
      </div>
      <SummarySection competenciesWithScore={competenciesWithScore} />
    </div>
  );
};
