import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

interface BoxTextProps {
  className?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  boxText: {
    color: `white`,
    padding: `10px 20px`,
    background: '#4468AD',
    margin: `15px 0px`,
    borderRadius: 10,
    fontSize: 20,
  },
}));

export const BoxText: React.FC<BoxTextProps> = props => {
  const style = useStyle();
  const { className } = props;

  return <div className={`${style.boxText} ${className}`}>{props.children}</div>;
};
