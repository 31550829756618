import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFitText } from './useFitText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: 264,
      height: 175,
      background: '#D8E3ED 0% 0% no-repeat padding-box',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    cardHeader: {
      padding: '10px 0',
    },
    cardTitle: {
      color: '#346490',
      font: 'normal normal bold 14px/18px Muli',
      letterSpacing: '0px',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    cardSubTitle: {
      color: '#647F98',
      font: 'italic normal normal 8px/10px Muli',
      letterSpacing: '0px',
      textAlign: 'center',
    },
    cardBody: {
      backgroundColor: '#F4F8FC',
      color: '#164396',
      flexGrow: 1,
      height: 121,
      borderRadius: '10px',
      font: 'normal normal normal 10px/13px Muli',
      // fontFamily: 'Muli',
      textTransform: 'capitalize',
      padding: 6,
      margin: 6,
      '& ul': {
        paddingLeft: 6,
        marginBottom: 0,
        listStyle: 'none',
      },
      '& ul>li': {
        paddingLeft: 15,
        position: 'relative',
        letterSpacing: 0.5,
      },
      '& ul>li::before': {
        left: 0,
        position: 'absolute',
        content: '"●"',
      },
    },
  }),
);
const DataCard: React.FC<{
  cardTitle: string;
  cardSubtitle: string;
  points?: string[];
}> = props => {
  const classes = useStyles({});

  const slize = totalLength => {
    switch (true) {
      case totalLength < 12:
        return 6;
      case totalLength === 15:
        return 8;
      case totalLength >= 12 && totalLength < 16:
        return 7;
      case totalLength >= 16:
        return 8;
    }
  };

  return (
    <div className={classes.card}>
      <div className={`${classes.cardHeader}`}>
        <div className={`${classes.cardTitle}`}>{props.cardTitle}</div>
        <div className={`${classes.cardSubTitle}`}>{props.cardSubtitle}</div>
      </div>
      {(props.points && props.points.length === 0) || props.points == null ? (
        <div className={`${classes.cardBody}`}>
          <ul>
            <li>N/A</li>
          </ul>
        </div>
      ) : props.points && props.points.length < 9 ? (
        <div className={`${classes.cardBody}`}>
          <ul>
            {props.points &&
              props.points.length > 0 &&
              props.points.map((point, index) => (
                <li key={`${point}_${index}`}>{point}</li>
              ))}
          </ul>
        </div>
      ) : (
        <div className={`${classes.cardBody}`} style={{ display: 'flex', fontSize: 9 }}>
          <div>
            <ul>
              {props.points &&
                props.points.length > 0 &&
                props.points
                  .sort((a, b) => a.length - b.length)
                  .slice(0, slize(props.points.length))
                  .map((point, index) => <li key={`${point}_${index}`}>{point}</li>)}
            </ul>
          </div>
          <div>
            <ul>
              {props.points &&
                props.points.length > 0 &&
                props.points
                  .sort((a, b) => a.length - b.length)
                  .slice(slize(props.points.length), props.points.length)
                  .map((point, index) => <li key={`${point}_${index}`}>{point}</li>)}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataCard;
