import * as React from 'react';
import { IntroductionPage } from '../../../newReport/components/IntroductionPage/IntroductionPage';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IProjectRating } from '@feedr/types';

interface WebIntroductionSectionProps {
  ratings: IProjectRating[];
}

const useStyle = makeStyles((theme: Theme) => ({
  section: {
    paddingLeft: 0,
    marginTop: -30,
  },
  description: {
    fontSize: 16,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
  },
  boxClassName: {
    fontSize: 16,
  },
}));

export const WebIntroductionSection: React.FC<WebIntroductionSectionProps> = props => {
  const style = useStyle();
  return (
    <IntroductionPage
      descriptionClassName={style.description}
      headerClassName={style.header}
      sectionClassName={style.section}
      boxClassName={style.boxClassName}
      showWebHeader={true}
      ratings={props.ratings}
    />
  );
};
