import { init, RematchRootState } from '@rematch/core';
import * as models from './models';
import { FirebaseReducer, firebaseReducer, FirestoreReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { IUiState } from './models/ui';
import { IPinState } from './models/pin';

export { firebase, rrfConfig } from './reducers';

export const store = init({
  models,

  redux: {
    reducers: {
      firebase: firebaseReducer,
      firestore: firestoreReducer,
    },
  },
});

export type TStoreWithoutReducers = typeof store;

export interface IStore extends TStoreWithoutReducers {
  firebase: FirebaseReducer.Reducer;
  firestore: FirestoreReducer.Reducer;
  pinState: IPinState;
  uiState: IUiState; // ? Don't why `TStoreWithoutReducers` ain't working
}

export type TDispatch = typeof store.dispatch;
export type TRootState = RematchRootState<typeof models>;
