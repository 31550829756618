import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { CSSProperties } from 'react';

export interface LegendItemProps {
  name?: string;
  color: string;
  className?: string;
  styleSheet?: CSSProperties;
  classNameValue?: string;
  classNameTitle?: string;
  value?: string | number;
}

const useStyle = makeStyles((theme: Theme) => ({
  legendContainer: {},
  legend: {
    width: 30,
    height: 30,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  name: {
    paddingLeft: 5,
    fontSize: 20,
  },
  value: {
    color: `white`,
    filter: `drop-shadow(1px 3px 5px rgba(0,0,0,0.25))`,
  },
}));

export const LegendItem: React.FC<LegendItemProps> = props => {
  const {
    name,
    color,
    className,
    classNameValue,
    value,
    styleSheet,
    classNameTitle,
  } = props;
  const style = useStyle();
  return (
    <div
      className={`${style.legendContainer} d-flex justify-content-center align-items-center`}
    >
      <div
        className={`${style.legend} ${className} d-flex align-items-center justify-content-center`}
        style={{
          background: color,
          ...styleSheet,
        }}
      >
        {value && <div className={`${style.value} ${classNameValue}`}>{value}</div>}
      </div>
      {name && <div className={`${style.name} ${classNameTitle}`}>{name}</div>}
    </div>
  );
};
