import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { LegendItem, LegendItemProps } from './LegendItem/LegendItem';
import { StringParam, useQueryParam } from 'use-query-params';

interface LegendProps {
  data: LegendItemProps[];
  legendTextClassName?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  legend: {
    width: 125,
    fontSize: 16,
  },
}));

export const Legend: React.FC<LegendProps> = props => {
  const style = useStyle();
  const [language] = useQueryParam('language', StringParam);
  const { data, legendTextClassName } = props;
  return (
    <div className={`d-flex align-items-center flex-shrink-1`}>
      {data.map((item, key) => (
        <div className={`px-2`} key={`legend-${key}`}>
          <LegendItem
            name={item.name}
            color={item.color}
            classNameValue={`${legendTextClassName}`}
            classNameTitle={`${legendTextClassName} ${language !== 'en' && style.legend}`}
          />
        </div>
      ))}
    </div>
  );
};
