export const DevelopmentEnvironment = {
  production: false,

  // for local development
  // appURL: 'http://localhost:4200',
  // apiURL: 'http://localhost:5001/feedr-7763d/us-central1',

  // for deployment
  appURL: 'https://feedr-dev.accendo.com.my',
  webAppURL: 'https://feedr-7763d.web.app',
  apiURL: 'https://us-central1-feedr-7763d.cloudfunctions.net',

  apiKey: 'AIzaSyC1sxV4w3U6xLW8kqaVvE7GOp30w2_w-Qo',
  authDomain: 'feedr-7763d.firebaseapp.com',
  databaseURL: 'https://feedr-7763d.firebaseio.com',
  projectId: 'feedr-7763d',
  storageBucket: 'feedr-7763d.appspot.com',
  messagingSenderId: '265253814633',
  appId: '1:265253814633:web:a523950ac919d62ffefdf8',
  measurementId: 'G-FN1CJ5F5BC',
  mailerPort: 587,
  mailerHost: 'smtp.mailgun.org',
};
