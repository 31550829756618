import React, { useState } from 'react';

import { Button, Card, CardContent, Chip, IconButton, Box } from '@material-ui/core';
import { CardHeader } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ReportTable from './ReportTable';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@page': {
      size: 'auto',
      margin: '0.4in 0',
    },
    box: {
      marginBottom: 6,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: 6,
    },

    cards: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      width: 533,
      background: '#F8F8F8',
      border: '1px solid #D9D9D9',
      borderRadius: 10,
    },
    cardHeader: {
      textAlign: 'center',
      color: 'white',
      height: 28,
      font: 'normal normal bold 16px/20px Muli',
      letterSpacing: 0.8,
      '& .MuiTypography-h5': {
        font: 'normal normal bold 16px/20px Muli',
      },
      textTransform: 'uppercase',
    },
    cardContent: {
      padding: 6,
      '&:last-child': {
        paddingBottom: 9,
      },
    },
    dangerText: {
      color: theme.palette.error.dark,
    },

    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    dialogContentRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    dialogContentPin: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    quill: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '& div.ql-toolbar': {
        flexShrink: 0,
      },
      '& div.ql-container': {
        flexGrow: 1,
        '& .ql-editor p': {
          marginBottom: theme.spacing(2), // <p> has default margin, we show it in editor too
        },
      },
    },
  }),
);
const TableContainer: React.FC<{
  cardTitle: string;
  data: any;
  headerColor: string;
  leftHeader: string;
  jobSpecific?: any;
}> = props => {
  const classes = useStyles({});

  return (
    <Box className={`${classes.box}`}>
      <Card className={classes.cards}>
        <CardHeader
          className={`${classes.cardHeader}`}
          style={{ backgroundColor: props.headerColor }}
          title={props.cardTitle}
        />
        <CardContent className={`${classes.cardContent}`}>
          <ReportTable
            data={props.data}
            leftHeader={props.leftHeader}
            jobSpecific={props.jobSpecific}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default TableContainer;
