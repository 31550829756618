import React, { Suspense, lazy } from 'react';
import {
  globalHistory,
  Redirect,
  RouteComponentProps,
  Router,
  Link,
} from '@reach/router';

import LoadingComp from '../components/Loader/LoadingComp';
import { useSelector } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import Redirector from '../components/Redirector';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import ErrorBoundary from '../components/ErrorBoundary';
import { ConfirmationServiceProvider } from '../components/Confirmation/service';
import FinishView from './raterHome/FinishView';
import EmptyPage from './auth/EmptyPage';
import { isLoaded } from 'react-redux-firebase';
import ProjectRouteForClientAdmin from './auth/ProjectRouteForClientAdmin';
import RouteByToken from './auth/RouteByToken';
import { PrintPersonNewReport } from './secure/projects/project/people/reports/newReport/PrintPersonNewReport';
import NewReportWeb from './secure/projects/project/people/reports/newReportWeb/NewReportWeb';
import AxiataReport from './secure/projects/project/people/reports/axiata/AxiataReport';

// ! All Routes Files must Go HERE !

// * Pages Lazy Loading
// ? Locations
// ? /
const HomePage = lazy(() => import('./home')); // ? /

// ? /auth
const AuthPage = lazy(() => import('./auth'));
const Login = lazy(() => import('./auth/Login')); // ? /auth/
// const Register = lazy(() => import('./auth/Register')); // ? /auth/register
// const InviteLandingPage = lazy(() => import('./auth/InviteLandingPage'));

// ? /rator
const RaterPage = lazy(() => import('./raterHome'));

const OthersRatingView = lazy(() => import('./raterHome/raterView/OthersRatingView'));
const SelfRatingView = lazy(() => import('./raterHome/raterView/SelfRatingView'));
const ProfileView = lazy(() => import('./raterHome/raterView/ProfileView'));
// ? /secure
const SecurePage = lazy(() => {
  // TODO Add Auth validation
  return import('./secure');
});

const Middleware = lazy(() => import('./auth/Middleware'));

// ? /secure/projects
const Projects = lazy(() => import('./secure/projects'));

// ? /secure/projects
const CreateProjectName = lazy(() =>
  import('./secure/projects/create/CreateProjectName'),
);

// ? /secure/projects/:projectId
const ProjectsHome = lazy(() => import('./secure/projects/home/ProjectsHome'));

const ProjectPage = lazy(() => import('./secure/projects/project/ProjectPage'));

const CompetencyHome = lazy(() => import('./secure/projects/project/competency'));
const CompetencyList = lazy(() => import('./secure/projects/project/competency/List'));
const EditCompetency = lazy(() =>
  import('./secure/projects/project/competency/EditCompetency'),
);
const NewCompetency = lazy(() =>
  import('./secure/projects/project/competency/NewCompetency'),
);

// const ProjectSettings = lazy(() => import('./secure/projects/project/settings'));
const RatingSettings = lazy(() => import('./secure/projects/project/rating'));
const ManageProjectPeople = lazy(() => import('./secure/projects/project/people'));
const OtherProjectSettings = lazy(() => import('./secure/projects/project/other'));
const ProjectDetailsSettings = lazy(() => import('./secure/projects/project/details'));
const EvaluationSettings = lazy(() => import('./secure/projects/project/evaluation'));
const ProjectDashboard = lazy(() => import('./secure/projects/project/dashboard'));

const People = lazy(() => import('./secure/people'));
const PersonReport = lazy(() => import('./secure/projects/project/people/reports'));
const PeopleList = lazy(() => import('./secure/people/List'));
const PeopleDetail = lazy(() => import('./secure/people/Detail'));

// * Descriptions Home
const Descriptions = lazy(() => import('./secure/descriptions'));
const DescriptionsHome = lazy(() => import('./secure/descriptions/home'));

// * Description Tag Home
const DescriptionTags = lazy(() => import('./secure/descriptionTags'));
const DescriptionTagsHome = lazy(() => import('./secure/descriptionTags/home'));

// * Pages Lazy Loading

const Routes: React.FC = () => {
  const auth = useSelector<any, any>(state => state.firebase.auth);
  const profile = useSelector<any, any>(state => state.firebase.profile);
  const isSuperAdmin =
    auth.uid && auth?.providerData?.some(x => x.providerId === 'password');

  return (
    <Suspense fallback={<LoadingComp />}>
      <ErrorBoundary>
        <QueryParamProvider reachHistory={globalHistory}>
          <ConfirmationServiceProvider>
            <Router>
              {/* <HomePage path={'/'} /> */}
              <Redirect from={'/'} to={`/auth/login`} noThrow />
              <RaterPage path={'/survey/:id'}>
                {/* <Redirect from={'/'} to={`/rator`} noThrow /> */}
                <OthersRatingView path={'/others'} />
                <SelfRatingView path={'/self'} />
                <ProfileView path={'/profile'} />
              </RaterPage>
              <FinishView path={'/survey/:id/finish'} />

              <Middleware path={'/middleware'} />
              <AuthPage path={'/auth'}>
                <Redirect from={'/'} to={`/auth/login`} noThrow />
                <Login path={'login'} />
                {/*<InviteLandingPage path={'invite'} />*/}
              </AuthPage>
              <Redirector path={'/authentication'} to={'/'} />

              {/* exact path to exclude client admin check on other /secure routes */}
              {/*<ProjectRouteForClientAdmin*/}
              {/*  path={'/secure/projects/:projectId/people/:rateeId/reports'}*/}
              {/*>*/}
              {/*  <PersonReport path={'/'} />*/}
              {/*</ProjectRouteForClientAdmin>*/}
              {/*newer route*/}
              <ProjectRouteForClientAdmin
                path={'/secure/projects/:projectId/people/:rateeId/reports'}
                disableLoading={true}
              >
                <NewReportWeb path={'/'} />
              </ProjectRouteForClientAdmin>
              <ProjectRouteForClientAdmin path={'/secure/projects/:projectId/dashboard'}>
                <ProjectDashboard path={'/'} />
              </ProjectRouteForClientAdmin>

              {/* to download report using token */}
              <RouteByToken
                path={'/secure/projects/:projectId/people/:rateeId/reports/print'}
              >
                <PrintPersonNewReport path={'/'} />
              </RouteByToken>

              <RouteByToken
                path={'/secure/projects/:projectId/people/:rateeId/reports/printAxiata'}
              >
                <AxiataReport path={'/'} />
              </RouteByToken>

              {isSuperAdmin ? (
                <SecurePage path={'/secure'}>
                  <Redirect from={'/'} to={'/secure/projects'} noThrow />

                  <Projects path={'/projects'}>
                    <ProjectsHome path={'/'} />
                    <CreateProjectName path={'/create'} />
                    <ProjectPage path={':projectId'}>
                      {/*<ProjectOverview path={'/'} />*/}
                      {/*<ProjectPeople path={'/people'} />*/}

                      {/*<ProjectSettings default path={'/settings'}>*/}
                      <Redirector
                        path={'/'}
                        to={`details`}
                        // noThrow
                      />
                      <ProjectDetailsSettings path={'details'} />
                      <RatingSettings path={'/rating'} />
                      <ManageProjectPeople path={'/people'} />

                      <EvaluationSettings path={'/evaluation'} />

                      <OtherProjectSettings path={'/other'} />
                      <CompetencyHome path={'/competencies'}>
                        <CompetencyList path={'/'} />
                        <NewCompetency path={'/new'} />
                        <EditCompetency path={':competencyId'} />
                      </CompetencyHome>
                      {/*</ProjectSettings>*/}
                    </ProjectPage>
                  </Projects>

                  <People path={'/people'}>
                    <PeopleList path={'/'} />
                    <PeopleDetail path={'/detail'} />
                  </People>

                  <Descriptions path={'/descriptions'}>
                    <DescriptionsHome path={'/'} />
                  </Descriptions>
                  <DescriptionTags path={'/description-tags'}>
                    <DescriptionTagsHome path={'/'} />
                  </DescriptionTags>
                </SecurePage>
              ) : (
                <EmptyPage path={'/secure'} isAuthLoaded={isLoaded(profile)}>
                  <Redirect from={'/*'} to={'/auth/login'} />
                  {/*<Redirector path={'/*'} to={'/auth/login'} />*/}
                </EmptyPage>
              )}
            </Router>
            <CustomizedSnackbars />
          </ConfirmationServiceProvider>
        </QueryParamProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export default Routes;
