import i18next from 'i18next';
import { TRANSLATIONS_EN } from './locales/en/translations';
import { TRANSLATIONS_TR } from './locales/tr/translations';
import { TRANSLATIONS_VI } from './locales/vi/translations';

const i18nInit = i18next.init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    tr: {
      translation: TRANSLATIONS_TR,
    },
    vi: {
      translation: TRANSLATIONS_VI,
    },
  },
});

export { i18next as i18n, i18nInit };
