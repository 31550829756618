import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import { store, firebase, rrfConfig, IStore } from './store';
import Routes from './pages/Routes';
import { materialTheme, globalCSS } from './theme';
import { Global } from '@emotion/core';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../../../node_modules/bootstrap/dist/js/bootstrap.bundle';

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const App: React.FC = () => {
  return (
    <Provider store={store as IStore}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Global styles={globalCSS} />
        <ThemeProvider theme={materialTheme}>
          <Routes />
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
