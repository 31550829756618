import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { HeaderLabel } from '../Header/HeaderLabel';
import { ChatBubbleLineSvg } from '../../assets/ChatBubbleLineSvg';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { Separator } from '../Separator/Separator';
import { CompetencyCircleItem, CompetencyCircleItemProps } from './CompetencyCircleItem';
import { i18n } from '@feedr/helpers';

export interface ProgressData {
  value: number;
  leftText: string;
  color: string;
}

interface CompetencyItemProps {
  title?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  circleTitleClassName?: string;
  description?: string;
  progressData: ProgressData[];
  circleData: CompetencyCircleItemProps[];
  showWebHeader?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  competencyItem: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  title: {
    // paddingLeft: 40,
    fontWeight: 600,
    pageBreakInside: 'avoid',
  },
  section: {
    // paddingLeft: 40,
    paddingBottom: 10,
    minHeight: 100,
  },
  overallHeader: {
    paddingBottom: 10,
  },
  overallTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  progressBarSection: {
    paddingLeft: 20,
  },
  avoidBreakInside: {
    pageBreakInside: 'avoid',
  },
}));

export const CompetencyItem: React.FC<CompetencyItemProps> = props => {
  const {
    title = `-`,
    description = `-`,
    progressData,
    circleData,
    titleClassName,
    descriptionClassName,
    circleTitleClassName,
  } = props;
  const style = useStyles({});
  const longestTextWidth = progressData.reduce(
    (max, obj) => Math.max(max, obj.leftText.length),
    0,
  );

  return (
    <div className={`${style.competencyItem}`}>
      <div>
        <div className={` ${style.avoidBreakInside}`}>
          <HeaderLabel
            title={title}
            description={description}
            titleClassName={`${style.title} ${titleClassName}`}
            descriptionClassName={`pl-0  ${descriptionClassName}`}
          />
          <div className={`${style.section}`}>
            <HeaderLabel
              title={i18n.t('report.overall_competency_rating')}
              icon={<ChatBubbleLineSvg />}
              titleClassName={style.overallTitle}
              className={style.overallHeader}
            />
            <div className={style.progressBarSection}>
              {progressData.map((item, key) => {
                return (
                  <ProgressBar
                    key={`progress-data-${key}`}
                    value={item.value}
                    leftText={item.leftText}
                    rightText={item.value > 0 ? item.value.toString() : null}
                    color={item.color}
                    longestText={longestTextWidth}
                    showBold={key === 0}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className={`${style.section} ${style.avoidBreakInside}`}>
          <HeaderLabel
            title={i18n.t('report.competency_rating_by_item')}
            titleClassName={style.overallTitle}
            className={`${style.overallHeader} pt-4 pb-0 `}
          />
          <Separator />
          <div className={`${style.avoidBreakInside}`}>
            <CompetencyCircleItem
              {...circleData[0]}
              className={style.avoidBreakInside}
              titleClassName={circleTitleClassName}
            />
            <Separator />
          </div>
        </div>
        {circleData.slice(1)?.map((item, key) => {
          return (
            <div
              className={`${style.section} ${style.avoidBreakInside}`}
              key={`circle-data-${key}`}
            >
              <CompetencyCircleItem
                {...item}
                className={style.avoidBreakInside}
                titleClassName={circleTitleClassName}
              />
              <Separator />
            </div>
          );
        })}
      </div>
    </div>
  );
};
