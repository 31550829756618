import * as React from 'react';
import { Header, HeaderProps } from './Header';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

interface HeaderLabelProps extends HeaderProps {}

const useStyle = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 28,
    fontWeight: 500,
  },
}));

export const HeaderLabel: React.FC<HeaderLabelProps> = props => {
  const { titleClassName, ...otherProps } = props;

  const style = useStyle();
  return <Header {...otherProps} titleClassName={`${style.title} ${titleClassName}`} />;
};
