import { createMuiTheme } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';

const materialTheme = createMuiTheme({
  palette: {
    primary: { main: '#4468AD' },
    secondary: { main: '#D69021', contrastText: '#ffffff' },
    background: {
      default: '#E4EBF2',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

export default materialTheme;
