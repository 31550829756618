import React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant: 'warning' | 'info' | 'success' | 'error';
  title: string;
  description: string | JSX.Element;
  acceptText?: string;
  cancelText?: string;
  noCancel?: boolean;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  warning: {
    backgroundColor: amber[700],
    '&:hover': {
      backgroundColor: amber[700],
    },
  },
  paper: {
    overflow: 'inherit',
    minWidth: 400,
  },
  iconContainer: {
    width: 120,
    height: 120,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '-75px auto 0 auto',
    borderRadius: 60,
    boxShadow:
      '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 2px rgba(0,0,0,0.12)',
  },
  icon: {
    fontSize: 80,
    color: 'white',
  },
  iconVariant: {
    opacity: 0.9,
    margin: 'auto',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleText: {
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 20,
  },
  primaryButton: {
    color: 'white',
  },
}));

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  acceptText = 'Ok',
  cancelText = 'Cancel',
  noCancel = false,
}) => {
  const classes = useStyles({});

  if (!open) {
    return null;
  }

  const Icon = variantIcon[variant];
  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <DialogTitle id="alert-dialog-title">
        <div className={classes.title}>
          <div className={clsx(classes[variant], classes.iconContainer)}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
          </div>
          <Typography className={classes.titleText}>{title}</Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!noCancel && (
          <Button onClick={onClose} autoFocus>
            {cancelText}
          </Button>
        )}
        <Button
          onClick={onSubmit}
          color={'primary'}
          variant={'contained'}
          className={clsx(classes[variant], classes.primaryButton)}
        >
          {acceptText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
