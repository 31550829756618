import * as React from 'react';
import { LegendItem, LegendItemProps } from '../LegendItem/LegendItem';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

interface StackLegendItemsProps {
  data: LegendItemProps[];
  title: string;
  className: string;
  legendTextClassName?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  stackLegendItems: {
    height: 80,
  },
  legend: {
    width: 45,
    minHeight: 40,
    opacity: 0.95,
  },
  value: {
    fontSize: 20,
    fontWeight: 700,
  },
  title: {
    fontSize: 20,
    width: 120,
    fontWeight: 500,
    textAlign: 'center',
    paddingTop: 10,
  },
}));

export const StackLegendItems: React.FC<StackLegendItemsProps> = props => {
  const style = useStyle();
  const { data, title, className, legendTextClassName } = props;
  return (
    <div className={`d-flex flex-column ${className}`}>
      <div className={`${style.stackLegendItems} d-flex align-items-end`}>
        {data.map((item, key) => (
          <div key={`stack-legend-item-${key}`}>
            <LegendItem
              value={item.value || `-`}
              color={item.color}
              className={`${style.legend}`}
              classNameValue={`${style.value} ${legendTextClassName}`}
              classNameTitle={`${legendTextClassName}`}
              styleSheet={{
                height: 30 + Number(item.value) * 10,
                marginLeft: -((key + 1) * 2),
                zIndex: data.length - (key + 1),
              }}
            />
          </div>
        ))}
      </div>
      <div className={`${style.title} ${legendTextClassName}`}>{title}</div>
    </div>
  );
};
