import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useQueryParam } from 'use-query-params';

interface IRedirectorProps extends RouteComponentProps {
  to: string;
}
const Redirector: React.FC<IRedirectorProps> = props => {
  const [redirect] = useQueryParam('redirect');

  useEffect(() => {
    if (redirect && typeof redirect === 'string') {
      window.location.href = redirect;
    } else {
      props.navigate(props.to, { replace: true });
    }
  }, [props]);
  return null;
};

export default Redirector;
