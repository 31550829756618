import React from 'react';
import {
  Button,
  Container,
  Theme,
  Typography,
  Box,
  TextareaAutosize,
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from '@reach/router';
import {
  CheckCircleOutline,
  CancelOutlined,
  SentimentVeryDissatisfied,
  SentimentSatisfied,
  SentimentDissatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { Rating, IconContainerProps } from '@material-ui/lab';
import { IPlatformFeedback } from '@feedr/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
  },
  container: {
    display: 'flex',
    backgroundColor: theme.palette.grey[50],
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconClosed: {
    fontSize: '120px',
    color: theme.palette.error.main,
    margin: theme.spacing(1, 0),
  },
  iconCompleted: {
    fontSize: '120px',
    color: green[500],
    marginBottom: theme.spacing(2),
  },
  mt: {
    marginTop: theme.spacing(2),
  },
  questionTextArea: {
    width: '90%',
    minHeight: 50,
    padding: theme.spacing(1, 2),
    ...theme.typography.body1,
  },
}));

const customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
  1: {
    icon: <SentimentVeryDissatisfied fontSize={'large'} />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfied fontSize={'large'} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfied fontSize={'large'} />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAlt fontSize={'large'} />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfied fontSize={'large'} />,
    label: 'Very Satisfied',
  },
};

const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
};

const FinishView: React.FC<RouteComponentProps<{
  id: string;
  isProjectClosed: boolean;
  isStatusClosed: boolean;
  isNewSubmit: boolean;
  simplifyLink?: string;
  redirectUrl?: string;
  showPlatformFeedbackForm: boolean;
  addPlatformFeedback: (satisfaction: number, comment: string) => Promise<void>;
  platformFeedback?: IPlatformFeedback;
  location: any;
}>> = props => {
  const classes = useStyles({});
  const {
    isProjectClosed,
    isStatusClosed,
    isNewSubmit,
    showPlatformFeedbackForm,
    addPlatformFeedback,
    platformFeedback,
  } = props;
  const simplifyLink = props.location?.state?.simplifyLink || props.simplifyLink;
  const redirectUrl = props.location?.state?.redirectUrl || props.redirectUrl;

  const [satisfaction, setSatisfaction] = React.useState(null);
  const [satisfactionHover, setSatisfactionHover] = React.useState(-1);
  const [comment, setComment] = React.useState('');
  const satisfactionLabel =
    customIcons[satisfactionHover === -1 ? satisfaction : satisfactionHover]?.label;
  const isPlatformFeedbackDisabled = !!platformFeedback;

  const platformFeedbackContainer = (
    <Card className={classes.mt}>
      <CardContent>
        <Box component="fieldset" borderColor={'transparent'} mt={1}>
          <Typography component="legend" gutterBottom>
            How would you rate your experience with this 360 platform?
          </Typography>
          <Rating
            name="customized-icons"
            value={satisfaction}
            IconContainerComponent={IconContainer}
            onChange={(event, value) => setSatisfaction(value)}
            onChangeActive={(event, value) => setSatisfactionHover(value)}
          />
          <Box mb={1} color={satisfactionLabel ? 'inherit' : 'transparent'}>
            {satisfactionLabel || 'none'}
          </Box>
        </Box>
        <Box component="fieldset" borderColor={'transparent'} textAlign={'left'}>
          <Typography component="legend" gutterBottom>
            Other Comments:
          </Typography>
          <TextareaAutosize
            className={classes.questionTextArea}
            value={comment}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setComment(event.target.value)
            }
          />
        </Box>
      </CardContent>
    </Card>
  );

  const redirectUrlContainer = (
    <Button
      variant={'contained'}
      size={'large'}
      color={'primary'}
      className={classes.mt}
      onClick={async () => {
        if (
          showPlatformFeedbackForm &&
          !isPlatformFeedbackDisabled &&
          (satisfaction || comment)
        ) {
          await addPlatformFeedback(satisfaction, comment);
        }
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      }}
    >
      {redirectUrl ? 'Continue' : 'Submit Feedback'}
    </Button>
  );

  const simplifyLinkContainer = (
    <>
      <Typography variant={'h6'} gutterBottom className={classes.mt}>
        Please click the button below to provide us your feedback and input, for us to
        further improve the product.
      </Typography>
      <Button
        variant={'contained'}
        size={'large'}
        color={'primary'}
        className={classes.mt}
        onClick={() => (window.location.href = simplifyLink)}
      >
        Share Your Feedback
      </Button>
    </>
  );

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        {isProjectClosed && !isStatusClosed ? (
          <>
            <CancelOutlined className={classes.iconClosed} />
            <Typography variant={'h3'} gutterBottom>
              You've Missed It
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              Sorry, the survey is already closed. Kindly contact the relevant personnel
              for further information.
            </Typography>
          </>
        ) : (
          <>
            <CheckCircleOutline className={classes.iconCompleted} />
            <Typography variant={'h3'} gutterBottom>
              {isNewSubmit ? 'Thank You' : 'Completed'}
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              {isNewSubmit
                ? 'Your responses have been submitted successfully. Thank you for your time and input.'
                : 'Awesome! Looks like you have submitted all your responses. Thank you.'}
            </Typography>
            {simplifyLink ? simplifyLinkContainer : ''}
            {showPlatformFeedbackForm && !isPlatformFeedbackDisabled
              ? platformFeedbackContainer
              : ''}
            {redirectUrl || (showPlatformFeedbackForm && !isPlatformFeedbackDisabled)
              ? redirectUrlContainer
              : ''}
          </>
        )}
      </Container>
    </div>
  );
};

export default FinishView;
