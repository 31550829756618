import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { IProject, IProjectRating } from '@feedr/types';
import { useEffect, useRef, useState } from 'react';
import { IStore } from '../../../../../../../../../store';

const useStyle = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    width: 244.5,
    flexDirection: 'column',
    justifyContent: `center`,
    alignItems: `center`,
  },
  centerLine: {
    height: 2,
    backgroundColor: `#8e8e8e`,
    width: `99%`,
  },
  stepsLine: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: `100%`,
    marginTop: 18,
    paddingBottom: 8,
  },
  separatorLineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: `100%`,
  },
  separatorLine: {
    height: 13,
    width: 2,
    backgroundColor: `#8e8e8e`,
    marginTop: -7,
  },
  label: {
    color: `#8e8e8e`,
  },
  selfScoreIcon: {
    position: 'relative',
    width: 0,
    height: 0,
    borderLeft: `10px solid transparent`,
    borderRight: `10px solid transparent`,
    borderTop: `15px solid #4468AD`,
    top: -10,
  },
  otherScoreIcon: {
    position: 'relative',
    width: 0,
    height: 0,
    borderLeft: `10px solid transparent`,
    borderRight: `10px solid transparent`,
    borderBottom: `15px solid #D69023`,
    top: 5,
  },
}));

interface CompareScaleLineProps {
  projectId: string;
  selfScore: number | string;
  otherScore: number | string;
}

export const CompareScaleLine: React.FC<CompareScaleLineProps> = props => {
  const { projectId, selfScore, otherScore } = props;

  const centerLineDivRef = useRef<HTMLDivElement>();
  const [fullWidth, setFullWidth] = useState<number>(0);
  const [selfScoreForScale, setSelfScoreForScale] = useState<number>(0);
  const [otherScoreForScale, setOtherScoreForScale] = useState<number>(0);

  const project = useSelector<IStore, IProject | undefined>(
    state => state.firestore.ordered[`projects/${projectId}`]?.[0],
  );
  // dont include N/A in scale line
  const ratings: IProjectRating[] = Object.values(project?.ratings ?? {}).slice(
    Number(project.enableNA),
  );

  const style = useStyle({});

  useEffect(() => {
    const fullWidth = 244.5;
    const onePoint = fullWidth / ((ratings.length - 1) * 10);
    const selfScoreForScale = (Number(selfScore) - 1) * 10 * onePoint;
    const otherScoreForScale = (Number(otherScore) - 1) * 10 * onePoint;
    setFullWidth(fullWidth);
    setSelfScoreForScale(selfScoreForScale);
    setOtherScoreForScale(otherScoreForScale);
  }, []);

  return (
    <div className={style.container}>
      {Number(selfScore) !== 0 && (
        <div
          className={style.selfScoreIcon}
          style={{
            left: -(fullWidth / 2) + selfScoreForScale,
          }}
        />
      )}

      <div className={style.centerLine} ref={centerLineDivRef} />
      <div className={style.separatorLineContainer}>
        {ratings.map((item, i) => (
          <div className={style.separatorLine} key={i} />
        ))}
      </div>
      {Number(otherScore) !== 0 && (
        <div
          className={style.otherScoreIcon}
          style={{
            left: -(fullWidth / 2) + otherScoreForScale,
          }}
        />
      )}

      <div className={style.stepsLine}>
        {ratings.map((item, i) => (
          <div className={style.label} key={i}>
            {i + 1}
          </div>
        ))}
      </div>
    </div>
  );
};
