import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { HighlightBox } from '../../../newReport/components/HighlightBox/HighlightBox';
import Thumbnail from '../../../../../../../../../components/Common/Thumbnail';
import { WebHeader } from '../WebHeader/WebHeader';
import { UserSvg } from '../../../newReport/assets/UserSvg';

interface CandidateProfileBoxProps {
  name: string;
  email: string;
  image: string;
}
const useStyle = makeStyles((theme: Theme) => ({
  candidateProfileBox: {
    flexGrow: 1,
    width: 300,
  },
  profilePicture: {
    width: 125,
    height: 125,
  },
  blurBox: {
    boxShadow: '1px 5px 11px 0px #00000012',
  },
  name: {
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 600,
    paddingTop: 20,
  },
  email: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 400,
  },
}));

export const CandidateProfileBox: React.FC<CandidateProfileBoxProps> = props => {
  const { name, email, image } = props;
  const style = useStyle();

  const renderCandidateProfile = () => {
    return (
      <div className={`d-flex flex-column align-items-center justify-content-center`}>
        <Thumbnail className={style.profilePicture} src={image} name={name} />
        <div className={style.name}>{name}</div>
        <div className={style.email}>{email}</div>
      </div>
    );
  };

  return (
    <div className={`d-flex flex-column ${style.candidateProfileBox}`}>
      <WebHeader
        icon={<UserSvg />}
        title={`Candidate Profile`}
        description={`Details of the candidate`}
      />
      <HighlightBox
        classNameBlurBox={style.blurBox}
        additionalData={renderCandidateProfile()}
      />
    </div>
  );
};
