import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { ReportHighlightDataStructure } from '../ReportHighlightPage/stores/reportHighlightDataStore';
import * as _ from 'lodash';

interface HighlightBoxProps extends ReportHighlightDataStructure {
  classNameBlurBox?: string;
  additionalData?: any;
}

const useStyle = makeStyles((theme: Theme) => ({
  highlightBox: {
    position: `relative`,
    padding: '20px 20px',
    border: `1px solid #CADEDC`,
    borderRadius: 5,
    zIndex: 2,
    background: 'white',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: `#7E7E7E`,
    fontStyle: `italic`,
  },
  items: {
    paddingTop: 10,
    color: `#4468AD`,
    fontSize: 18,
    fontWeight: 700,
  },
  dataOrder: {
    width: 20,
  },
  blurBox: {
    height: `100%`,
    width: `100%`,
    position: `absolute`,
    zIndex: 1,
    background: `rgba(183, 183, 183, 0.4)`,
    filter: `blur(3px)`,
    top: 0,
    left: 0,
  },
}));

export const HighlightBox: React.FC<HighlightBoxProps> = props => {
  const style = useStyle();
  const { title, description, data, classNameBlurBox, additionalData } = props;
  return (
    <div className={`w-100 h-100 position-relative`}>
      <div className={`w-100 h-100 ${style.highlightBox}`}>
        {additionalData ? (
          additionalData
        ) : (
          <>
            <div className={style.title}>{title}</div>
            <div className={style.description}>{description}</div>
            <div className={style.items}>
              {data?.map((item, key) => {
                return (
                  <div className={`d-flex`} key={`highlight-box-${key}`}>
                    <div className={style.dataOrder}>{key + 1}.</div>
                    <span>{item}</span>
                  </div>
                );
              })}
              {_.isEmpty(data) && (
                <div className={`d-flex`}>
                  <span>-</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className={`${style.blurBox} ${classNameBlurBox}`} />
    </div>
  );
};
