import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { Header } from '../Header/Header';
import { RocketSvg } from '../../assets/RocketSvg';
import { HeaderLabel } from '../Header/HeaderLabel';
import { BoxText } from '../BoxText/BoxText';
import { WebHeader } from '../../../newReportWeb/components/WebHeader/WebHeader';
import RatingOptionGuide from '../../../../../../../../raterHome/raterView/components/RatingOptionGuide';
import { IProjectRating } from '@feedr/types';
import { i18n } from '@feedr/helpers';

interface IntroductionPageProps {
  descriptionClassName?: string;
  headerClassName?: string;
  sectionClassName?: string;
  boxClassName?: string;
  showWebHeader?: boolean;
  ratings?: IProjectRating[];
}

const useStyle = makeStyles((theme: Theme) => ({
  introductionPage: {
    pageBreakAfter: 'always',
  },
  description: {
    fontWeight: 400,
    fontSize: 20,
  },
  contentSection: {
    paddingRight: 42,
    paddingLeft: 42,
  },
  section: {
    paddingTop: 30,
  },
}));

export const IntroductionPage: React.FC<IntroductionPageProps> = props => {
  const {
    showWebHeader = false,
    descriptionClassName,
    headerClassName,
    sectionClassName,
    boxClassName,
  } = props;
  const style = useStyle();
  const options = Object.entries(props.ratings ?? {}).map(([i, elem]) => ({
    ...elem,
    id: i.toString(),
  }));

  return (
    <div className={`w-100 h-100 ${style.introductionPage}`}>
      {showWebHeader ? (
        <WebHeader
          icon={<RocketSvg />}
          title={i18n.t('report.introduction')}
          description={`Brief information about the report`}
        />
      ) : (
        <Header icon={<RocketSvg />} title={i18n.t('report.introduction')} />
      )}
      <div className={`${style.contentSection} ${sectionClassName}`}>
        <HeaderLabel
          title={i18n.t('report.about_the_report')}
          className={`${style.section}`}
          titleClassName={` ${headerClassName}`}
        >
          <div className={`${style.description} ${descriptionClassName}`}>
            {i18n.t('report.about_the_report_p1')}
            <br />
            <br />
            <strong>{i18n.t('report.about_the_report_p2')}</strong>
            {`, ${i18n.t('report.about_the_report_p3')}`}
          </div>
        </HeaderLabel>

        <HeaderLabel
          title={i18n.t('report.how_to_read_this_report')}
          className={`${style.section}`}
          titleClassName={` ${headerClassName}`}
        >
          <div className={`${style.description} ${descriptionClassName}`}>
            {i18n.t('report.how_to_read_this_report_p1')}
            <br />
            <br />
            {i18n.t('report.how_to_read_this_report_p2')}
            <div className={`w-100 h-100`}>
              <BoxText className={`${boxClassName}`}>
                <strong>{`${i18n.t('report.self')}: `}</strong>
                {i18n.t('report.how_to_read_this_report_self')}
              </BoxText>
              <BoxText className={`${boxClassName}`}>
                <strong>{`${i18n.t('report.all_raters')}: `}</strong>
                {i18n.t('report.how_to_read_this_report_all_raters')}
              </BoxText>
              <BoxText className={`${boxClassName}`}>
                <strong>{`${i18n.t('report.rater_average')}: `}</strong>
                {i18n.t('report.how_to_read_this_report_rater_average')}
              </BoxText>
              <BoxText className={`${boxClassName}`}>
                <strong>{`${i18n.t('report.group_average')}: `}</strong>
                {i18n.t('report.how_to_read_this_report_group_average')}
              </BoxText>
              <BoxText className={`${boxClassName}`}>
                <strong>{`${i18n.t('report.group_rater_average')}: `}</strong>
                {i18n.t('report.how_to_read_this_report_group_rater_average')}
              </BoxText>
            </div>
          </div>
        </HeaderLabel>

        {options.length > 0 && (
          <HeaderLabel
            title={i18n.t('report.rating_guide')}
            className={`${style.section}`}
            titleClassName={`${headerClassName}`}
          >
            <RatingOptionGuide options={options} />
          </HeaderLabel>
        )}
      </div>
    </div>
  );
};
