import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface CircleNumberProps {}

const useStyles = makeStyles((theme: Theme) => ({
  circleNumber: {
    height: 25,
    width: 25,
    background: `#4466B0`,
    alignItems: `baseline`,
    borderRadius: 50,
    color: `white`,
  },
}));

export const CircleNumber: React.FC<CircleNumberProps> = props => {
  const style = useStyles({});

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${style.circleNumber}`}
    >
      {props.children}
    </div>
  );
};
