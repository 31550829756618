import * as React from 'react';

interface ChatBubbleLineSvgProps {}

export const ChatBubbleLineSvg: React.FC<ChatBubbleLineSvgProps> = props => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 35.934 36.274"
      >
        <g id="Group_6547" data-name="Group 6547" transform="translate(0)">
          <path
            id="Path_4534"
            data-name="Path 4534"
            d="M940.492,294.448h1.5v7.826l8.8-7.826h16.65a4.5,4.5,0,0,0,4.492-4.492V270.492A4.5,4.5,0,0,0,967.443,266H940.492A4.5,4.5,0,0,0,936,270.492v19.465A4.5,4.5,0,0,0,940.492,294.448Zm-1.5-23.956a1.5,1.5,0,0,1,1.5-1.5h26.951a1.5,1.5,0,0,1,1.5,1.5v19.465a1.5,1.5,0,0,1-1.5,1.5H949.655l-4.672,4.152v-4.152h-4.492a1.5,1.5,0,0,1-1.5-1.5Z"
            transform="translate(-936 -266)"
            fill="#d69021"
          />
          <rect
            id="Rectangle_4739"
            data-name="Rectangle 4739"
            width="3.492"
            height="9.602"
            transform="translate(7.132 13.094)"
            fill="#d69021"
          />
          <rect
            id="Rectangle_4740"
            data-name="Rectangle 4740"
            width="3.492"
            height="4.365"
            transform="translate(13.242 18.331)"
            fill="#d69021"
          />
          <rect
            id="Rectangle_4741"
            data-name="Rectangle 4741"
            width="3.492"
            height="16.585"
            transform="translate(19.353 6.11)"
            fill="#d69021"
          />
          <rect
            id="Rectangle_4742"
            data-name="Rectangle 4742"
            width="2.619"
            height="12.221"
            transform="translate(25.463 10.475)"
            fill="#d69021"
          />
        </g>
      </svg>
    </div>
  );
};
