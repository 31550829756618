import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

export interface HeaderProps {
  icon?: Element | React.ReactNode;
  title?: string;
  description?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  className?: string;
  iconClassName?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  header: {
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
    fontSize: 35,
    paddingBottom: 20,
  },
  icon: {
    paddingRight: 10,
    height: 55,
  },
  title: {
    fontWeight: 700,
  },
  description: {
    fontWeight: 400,
    fontSize: 22,
    // paddingLeft: 40,
  },
  contentSection: {},
}));

export const Header: React.FC<HeaderProps> = props => {
  const {
    icon,
    title,
    description,
    titleClassName,
    className,
    descriptionClassName,
    iconClassName,
  } = props;
  const style = useStyle();
  return (
    <div className={`d-flex flex-column align-items-start ${style.header} ${className}`}>
      <div className={`d-flex align-items-center`}>
        {icon && <div className={`${style.icon} ${iconClassName}`}>{icon}</div>}
        <div className={`${style.title} ${titleClassName}`}>{title}</div>
      </div>
      <div className={`d-flex ${style.contentSection}`}>
        {description && (
          <div className={`${style.description} ${descriptionClassName}`}>
            {description}
          </div>
        )}
        <div>{props.children}</div>
      </div>
    </div>
  );
};
