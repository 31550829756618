import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Theme, Typography } from '@material-ui/core';
import { Header } from '../Header/Header';
import { SeparatorSvg } from '../../assets/SeparatorSvg';
import { ICompetencyScore, IRoleScore } from '@feedr/types';
import * as _ from 'lodash';
import { CompareListItem } from '../CompareListItem/CompareListItem';
import { Separator } from '../Separator/Separator';
import { WebHeader } from '../../../newReportWeb/components/WebHeader/WebHeader';
import { i18n } from '@feedr/helpers';

export interface ComparisonPageProps {
  projectId: string;
  scores: ICompetencyScore[];
  showWebHeader?: boolean;
}

interface ComparePageScoreData {
  id: string | number;
  name: string;
  orderIndex: number;
  score: IRoleScore;
  description: string;
  descriptionOrderIndex: number;
}

interface ItemAttributesData {
  title: string;
  itemAttribute: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  comparisonPage: {},
  title: {
    fontSize: 22,
    fontWeight: 700,
    color: `#4468AD`,
    paddingTop: 15,
  },
  pageBreakAfter: {
    pageBreakAfter: 'always',
  },
  avoidPageBreak: {
    pageBreakInside: 'avoid',
  },
  webText: {
    fontSize: 18,
  },
}));

export const ComparisonPage: React.FC<ComparisonPageProps> = props => {
  const style = useStyle();

  const { scores, projectId, showWebHeader = false } = props;

  const itemAttributes: ItemAttributesData[] = [
    {
      title: `${i18n.t('report.manager')}`,
      itemAttribute: `managersAverage`,
    },
    {
      title: `${i18n.t('report.peers')}`,
      itemAttribute: `peersAverage`,
    },
    {
      title: `${i18n.t('report.direct_report')}`,
      itemAttribute: `directReportsAverage`,
    },
    {
      title: `${i18n.t('report.others')}`,
      itemAttribute: `othersAverage`,
    },
  ];

  const massagedScores: ComparePageScoreData[] = [];
  scores
    .map(score => {
      const item = { id: score.id, name: score.name, orderIndex: score.orderIndex };
      score.descriptionScores.map((description, idx) => {
        massagedScores.push({
          ...item,
          score: description.score,
          description: description.detail,
          descriptionOrderIndex: idx,
        });
      });
    })
    .sort(
      (a: any, b: any) =>
        a.orderIndex - b.orderIndex || a.descriptionOrderIndex - b.descriptionOrderIndex,
    );

  const showSection = (data: any[], scoreAttribute: string) => {
    const mapped = data.map(item => item.score[scoreAttribute] && item).filter(i => i);
    return !_.isEmpty(mapped);
  };

  const renderDataContent = (title: string, itemAttribute: string) =>
    showSection(massagedScores, itemAttribute) &&
    massagedScores.map((item, idx) => {
      return (
        <div key={idx} className={style.avoidPageBreak}>
          {idx === 0 && (
            <div>
              <div className={style.title}>{`${i18n.t('report.self')} - ${title}`}</div>
              <Separator />
            </div>
          )}
          <div>
            <CompareListItem
              projectId={projectId}
              heading={item.name}
              description={JSON.parse(item.description)?.blocks[0].text.trim()}
              selfScore={item?.score?.selfScore}
              otherScore={item?.score[itemAttribute]}
              otherScoreName={title}
              className={idx === 0 && `border-top-0`}
              classNameText={showWebHeader && style.webText}
            />
          </div>
        </div>
      );
    });

  const renderView = () => {
    return (
      <>
        {itemAttributes.map((item, key) => {
          return (
            <div key={`item-attributes-${key}`}>
              {renderDataContent(item.title, item.itemAttribute)}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={`${style.comparisonPage} `}>
      {showWebHeader ? (
        <WebHeader
          icon={<SeparatorSvg size={30} />}
          title={i18n.t('report.comparison')}
          description={i18n.t('report.comparison_description')}
        />
      ) : (
        <Header
          icon={<SeparatorSvg />}
          title={i18n.t('report.comparison')}
          description={i18n.t('report.comparison_description')}
        />
      )}
      <div>{renderView()}</div>
    </div>
  );
};
