import * as React from 'react';

interface SeparatorSvgProps {
  size?: number;
}

export const SeparatorSvg: React.FC<SeparatorSvgProps> = props => {
  const { size = 45 } = props;
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 45 38"
      >
        <g id="Group_11059" data-name="Group 11059" transform="translate(-125 -99.941)">
          <g
            id="Rectangle_6257"
            data-name="Rectangle 6257"
            transform="translate(125 103.941)"
            fill="#fff"
            stroke="#d69023"
            strokeWidth="3"
          >
            <rect width="15" height="30" rx="2" stroke="none" />
            <rect x="1.5" y="1.5" width="12" height="27" rx="0.5" fill="none" />
          </g>
          <g
            id="Rectangle_6260"
            data-name="Rectangle 6260"
            transform="translate(155 103.941)"
            fill="#fff"
            stroke="#d69023"
            strokeWidth="3"
          >
            <rect width="15" height="30" rx="2" stroke="none" />
            <rect x="1.5" y="1.5" width="12" height="27" rx="0.5" fill="none" />
          </g>
          <g
            id="Rectangle_6261"
            data-name="Rectangle 6261"
            transform="translate(145 99.941)"
            fill="#fff"
            stroke="#d69023"
            strokeWidth="3"
          >
            <rect width="5" height="38" rx="2" stroke="none" />
            <rect x="1.5" y="1.5" width="2" height="35" rx="0.5" fill="none" />
          </g>
        </g>
      </svg>
    </div>
  );
};
