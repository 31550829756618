export const scales = [2, 5, 10];
export const maxScale = scales[scales.length - 1];

const heatmapScaleTwo = ['#607d8b', '#e91e63', '#4caf50'];
const heatmapScaleFive = [
  '#607d8b',
  '#e91e63',
  '#ff9800',
  '#ffc107',
  '#8bc34a',
  '#4caf50',
];
const heatmapScaleTen = [
  '#607d8b',
  '#e91e63',
  '#e91e63',
  '#ff9800',
  '#ff9800',
  '#ffc107',
  '#ffc107',
  '#8bc34a',
  '#8bc34a',
  '#4caf50',
  '#4caf50',
];

const defaultLabels = {
  2: ['N/A', '1', '2'],
  5: ['N/A', '1', '2', '3', '4', '5'],
  10: ['N/A', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
};

// based on react-color circle options
const heatmaps = {
  2: heatmapScaleTwo,
  5: heatmapScaleFive,
  10: heatmapScaleTen,
};

// initiate ratings with key as score
export const getEmptyRatings = scale =>
  [...Array(maxScale + 1)]?.map((x, i) => ({
    label: defaultLabels[scale][i] || '',
    description: '',
    color: {
      hex: heatmaps[scale][i] || '',
    },
  }));
