import { useFirestoreConnect } from 'react-redux-firebase';
import { IStore } from '../../../../../../store';
import {
  ICompetencyScore,
  IProjectPersonInfo,
  IProjectQuestion,
  IQuestionResponse,
  IUser,
} from '@feedr/types';
import { useSelector } from 'react-redux';
import useReportState from './useReportState';
import { getReportHighlightData } from './newReport/components/ReportHighlightPage/stores/reportHighlightDataStore';

interface IPersonData extends IProjectPersonInfo {
  position?: string;
  opco?: string;
  nomineeCount?: number;
}

interface IAxiataCompetencyScore {
  name: string;
  scores: {
    self: number | null;
    overall: number | null;
    immediateSupervisor: number | null;
    peers: number | null;
    directReports: number | null;
    others: number | null;
  };
}

interface IAxiataReportData {
  name: string;
  position: string;
  opco: string;
  raterDetails: {
    invited: number;
    completed: {
      total: number;
      immediateSupervisor: number;
      peers: number;
      directReports: number;
      others: number;
    };
  };
  quadrants: {
    cultureFit: IAxiataCompetencyScore[];
    leading: IAxiataCompetencyScore[];
    amplifiers: IAxiataCompetencyScore[];
    skills: IAxiataCompetencyScore[];
    jobSpecific: IAxiataCompetencyScore[];
  };
  reportHighlight: {
    highestRated: string[];
    lowestRated: string[];
    hiddenPotential: string[];
    oversight: string[];
  };
  questions: {
    title: string;
    feedbacks: string[];
  }[];
  isAllLoaded: boolean;
}

type TAxiataReportState = (rateeId: string, projectId: string) => IAxiataReportData;

const useAxiataReportState: TAxiataReportState = (rateeId, projectId) => {
  useFirestoreConnect(() => [{ collection: 'users', doc: rateeId }]);
  const {
    person,
    scores,
    ratingScale,
    questions,
    nomineesByRole,
    isAllLoaded,
  } = useSelector<IStore, any>(state => {
    const user = (state.firestore.ordered.users as IUser[])?.find(x => x.id === rateeId);

    return useReportState(user?.email ?? '', projectId);
  });
  const email = person?.email ?? '';

  const { personInfo, nomineesByRoleCompletedCount } = useSelector<IStore, any>(state => {
    const nominees =
      state.firestore.ordered[`projects/${projectId}/ratee/${email}/nominees`] ?? [];
    const nomineesExcludeSelf = nominees.filter(nominee => nominee.role !== 'Self');
    const personInfoDoc =
      state.firestore.ordered[`projects/${projectId}/people-info/${email}`]?.[0];
    const personInfo = {
      ...personInfoDoc,
      nomineeCount: nomineesExcludeSelf.length,
    };

    const peopleInfo = state.firestore.ordered[
      `projects/${projectId}/people-info`
    ] as IProjectPersonInfo[];
    const getCompletedCount = (role: string) =>
      (nomineesByRole?.[role] ?? []).filter(email => {
        const status = peopleInfo?.find(x => x.id === email)?.status || '';
        const isHidden = nominees.find(x => x.email === email)?.isHidden;
        return status === 'CLOSED' && !isHidden;
      }).length;

    const completedByRole = {
      immediateSupervisor: getCompletedCount('Manager'),
      peers: getCompletedCount('Peer'),
      directReports: getCompletedCount('Direct Report'),
      others: getCompletedCount('Others'),
    };
    const nomineesByRoleCompletedCount = {
      total:
        completedByRole.immediateSupervisor +
        completedByRole.peers +
        completedByRole.directReports +
        completedByRole.others,
      ...completedByRole,
    };
    return { personInfo, nomineesByRoleCompletedCount };
  });

  const quadrants = {
    cultureFit: ['Uncompromising Integrity', 'Exceptional Performance'],
    leading: [
      'Strategic Accountability',
      'Customer Centric',
      'Coalition Building',
      'People First',
      'Agile Leadership',
      'Results Driven',
    ],
    amplifiers: ['Aspiration', 'Motivation', 'Perseverance'],
    skills: [
      'Digital Mindset',
      'Data Analytics',
      'Business And Market Savvy',
      'Governance',
    ],
    jobSpecific: ['Job Specific Competencies'],
  };

  const getQuadrantData = (titles: string[]) =>
    titles.map(title => {
      const competency: ICompetencyScore = (scores ?? []).find(
        competencyScore => competencyScore.name == title,
      );
      const score = competency?.score;
      return {
        name: title == 'Business And Market Savvy' ? 'Business & Market Savvy' : title,
        scores: {
          self: score?.selfScore || null,
          overall: score?.raterAverage || null,
          immediateSupervisor: score?.managersAverage || null,
          peers: score?.peersAverage || null,
          directReports: score?.directReportsAverage || null,
          others: score?.othersAverage || null,
        },
      };
    });

  const reportHighlightData = getReportHighlightData(scores, ratingScale, true);

  const questionTitles = [
    'What are the qualities that makes the individual effective in current role?',
    'What are the behaviors that this individual should start demonstrating or do more of?',
    'What are the behaviors that this individual should stop demonstrating or do less of?',
  ];

  return {
    name: person?.name || '',
    position: personInfo?.position || '',
    opco: personInfo?.opco || '',
    raterDetails: {
      invited: personInfo?.nomineeCount || 0,
      completed: nomineesByRoleCompletedCount,
    },
    quadrants: {
      cultureFit: getQuadrantData(quadrants.cultureFit),
      leading: getQuadrantData(quadrants.leading),
      amplifiers: getQuadrantData(quadrants.amplifiers),
      skills: getQuadrantData(quadrants.skills),
      jobSpecific: getQuadrantData(quadrants.jobSpecific),
    },
    reportHighlight: {
      highestRated: reportHighlightData.highlyRatedCompetency.data,
      lowestRated: reportHighlightData.lowestRatedCompetency.data,
      hiddenPotential: reportHighlightData.hiddenPotential.data,
      oversight: reportHighlightData.oversight.data,
    },
    questions: questionTitles.map(title => {
      const question = questions.find((q: IProjectQuestion) => q.title == title);
      return {
        title: question?.title || '',
        feedbacks: (question?.feedbacks || []).map(item => item.answer),
      };
    }),
    isAllLoaded,
  };
};

export default useAxiataReportState;
