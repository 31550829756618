import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';
import { Theme, Typography, Grid, Avatar } from '@material-ui/core';
import { IRatingOptionsItem } from './RatingOptions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: 100,
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
    },
  },
  topRow: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    '& .MuiAvatar-root': {
      marginRight: theme.spacing(2),
    },
  },
}));

interface IRatingOptionGuide extends RouteComponentProps {
  options: IRatingOptionsItem[];
}

const RatingOptionGuide: React.FC<IRatingOptionGuide> = props => {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {props.options.map(option => (
          <Grid item xs={12} md={6} spacing={2} key={option.id}>
            <div className={classes.paper}>
              <div className={classes.topRow}>
                <Avatar style={{ backgroundColor: option.color.hex }}>
                  {option.id === '0' ? 'N/A' : option.id}
                </Avatar>
                <Typography variant={'h5'} align={'left'}>
                  {option.label}
                </Typography>
              </div>
              <div>
                <Typography variant={'body1'} align="left">
                  {option.description}
                </Typography>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RatingOptionGuide;
