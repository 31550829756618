import React, { useState } from 'react';
import * as _ from 'lodash';

import {
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core';
import { CardHeader } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { roundToFixedDecimal } from 'apps/feedr-react/src/app/utils/utilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borders: { borderColor: 'black', borderWidth: '1px', borderStyle: 'solid' },
    table: {
      width: 520,
      '&>tbody>tr>th, &>tbody>tr>td': {
        borderColor: 'black',
        borderWidth: '1px',
        borderStyle: 'solid',
      },
    },
    groupCell: {
      backgroundColor: '#395369',
      color: 'white',
      height: 21,
      width: 56,
      textAlign: 'center',
      font: 'normal normal normal 11px/16px Muli',
    },
    groupCellLight: {
      backgroundColor: '#587995',
      color: 'white',
      height: 21,
      width: 56,
      textAlign: 'center',
      font: 'normal normal normal 11px/16px Muli',
    },
    leftHeaderTitle: {
      backgroundColor: '#003562',
      color: 'white',
      height: 66,
      paddingLeft: 8,
      width: 167,
      font: 'normal normal bold 16px/18px Muli',
    },
    averageTitle: {
      textAlign: 'center',
      backgroundColor: '#003562',
      color: 'white',
      height: 30,
      width: 350,
      font: 'normal normal bold 14px/18px Muli',
    },
    competenciesTableHeader: {
      font: 'normal normal bold 12px/15px Muli',
      paddingLeft: 8,
      height: 33,
    },
    scoreDataCell: {
      font: 'normal normal normal 11px/16px Muli',
      height: 32,
      width: 56,
      textAlign: 'center',
    },
    jobSpecificTitle: {
      backgroundColor: '#C20C40',
      textAlign: 'center',
      color: 'white',
      font: 'normal normal bold 14px/18px Muli',
      letterSpacing: 0.7,
      height: 24,
    },
    jobSpecificTable: {
      marginTop: 8,
      width: 520,
    },
    leftHeaderJSC: {
      backgroundColor: '#F8F8F8',
      font: 'normal normal bold 12px/15px Muli',
      letterSpacing: 0.5,
      color: '#000000',
      textAlign: 'left',
      width: 166,
      paddingLeft: 8,
    },
  }),
);
const ReportTable: React.FC<{
  data: any;
  leftHeader: string;
  jobSpecific?: any;
}> = props => {
  const classes = useStyles({});
  const handleScoreStyle = score => {
    if (_.isNil(score) || _.isNaN(score)) return {};
    return {
      backgroundColor: score >= 4 ? '#A6D897' : score <= 2 ? '#FE9D9D' : '#EDEDED',
    };
  };

  const [competencies, setCompetencies] = useState([]);
  const [jobSpecifics, setJobSpecifics] = useState([]);
  const [initialized, setInitialized] = useState(false);

  if (props.data != null && !initialized) {
    const _competencies = [];
    Object.values(props.data).map((competency: any) => {
      const scores = [];
      scores.push(
        competency.scores.self ? roundToFixedDecimal(competency.scores.self) : '-',
      );
      scores.push(
        competency.scores.overall ? roundToFixedDecimal(competency.scores.overall) : '-',
      );
      scores.push(
        competency.scores.immediateSupervisor
          ? roundToFixedDecimal(competency.scores.immediateSupervisor)
          : '-',
      );
      scores.push(
        competency.scores.peers ? roundToFixedDecimal(competency.scores.peers) : '-',
      );
      scores.push(
        competency.scores.directReports
          ? roundToFixedDecimal(competency.scores.directReports)
          : '-',
      );
      scores.push(
        competency.scores.others ? roundToFixedDecimal(competency.scores.others) : '-',
      );

      const c = {
        name: competency.name,
        scores: scores,
      };
      _competencies.push(c);
    });

    if (props.jobSpecific != null) {
      const _jobSpecifics = [];
      Object.values(props.jobSpecific).map((competency: any) => {
        const jobSpecificScores = [];
        jobSpecificScores.push(
          competency.scores.self ? roundToFixedDecimal(competency.scores.self) : '-',
        );
        jobSpecificScores.push(
          competency.scores.overall
            ? roundToFixedDecimal(competency.scores.overall)
            : '-',
        );
        jobSpecificScores.push(
          competency.scores.immediateSupervisor
            ? roundToFixedDecimal(competency.scores.immediateSupervisor)
            : '-',
        );
        jobSpecificScores.push(
          competency.scores.peers ? roundToFixedDecimal(competency.scores.peers) : '-',
        );
        jobSpecificScores.push(
          competency.scores.directReports
            ? roundToFixedDecimal(competency.scores.directReports)
            : '-',
        );
        jobSpecificScores.push(
          competency.scores.others ? roundToFixedDecimal(competency.scores.others) : '-',
        );

        const c = {
          name: competency.name,
          scores: jobSpecificScores,
        };
        _jobSpecifics.push(c);
      });
      setJobSpecifics(_jobSpecifics);
    }
    setCompetencies(_competencies);
    setInitialized(true);
  }
  return (
    <>
      <table className={`${classes.borders} ${classes.table}`}>
        <tbody>
          <tr>
            <th rowSpan={2} className={`${classes.leftHeaderTitle}`}>
              {props.leftHeader}
            </th>
            <th colSpan={6} className={`${classes.averageTitle}`} scope="colgroup">
              Average Score by Category
            </th>
          </tr>
          <tr>
            <td scope="col" className={`${classes.groupCellLight}`}>
              Self
            </td>
            <td scope="col" className={`${classes.groupCellLight}`}>
              Overall
            </td>
            <td scope="col" className={`${classes.groupCell}`}>
              IS
            </td>
            <td scope="col" className={`${classes.groupCell}`}>
              Peers
            </td>
            <td scope="col" className={`${classes.groupCell}`}>
              DR
            </td>
            <td scope="col" className={`${classes.groupCell}`}>
              Others
            </td>
          </tr>
          {competencies.map((competency: any) => (
            <tr key={competency.name}>
              <th scope="row" className={classes.competenciesTableHeader}>
                {competency.name}
              </th>
              {competency.scores.map((score, index) => (
                <React.Fragment key={`${score}_${index}`}>
                  <td style={handleScoreStyle(score)} className={classes.scoreDataCell}>
                    {score}
                  </td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {props.jobSpecific && (
        <div>
          <table
            className={`${classes.borders} ${classes.table} ${classes.jobSpecificTable}`}
          >
            <tbody>
              <tr>
                <th colSpan={8} className={`${classes.jobSpecificTitle}`}>
                  JOB SPECIFIC COMPETENCIES
                </th>
              </tr>
              <tr>
                <th rowSpan={3} className={`${classes.leftHeaderJSC}`}>
                  Job Specific <br /> Competencies
                </th>
                <th colSpan={6} className={`${classes.averageTitle}`} scope="colgroup">
                  Average Score by Category
                </th>
              </tr>
              <tr>
                <td scope="col" className={`${classes.groupCellLight}`}>
                  Self
                </td>
                <td scope="col" className={`${classes.groupCellLight}`}>
                  Overall
                </td>
                <td scope="col" className={`${classes.groupCell}`}>
                  IS
                </td>
                <td scope="col" className={`${classes.groupCell}`}>
                  Peers
                </td>
                <td scope="col" className={`${classes.groupCell}`}>
                  DR
                </td>
                <td scope="col" className={`${classes.groupCell}`}>
                  Others
                </td>
              </tr>
              {jobSpecifics.map((competency, index) => (
                <tr key={`${competency.name}_${index}`}>
                  {competency.scores.map((score, index) => (
                    <td
                      key={`${score}_${index}`}
                      style={handleScoreStyle(score)}
                      className={classes.scoreDataCell}
                    >
                      {score}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/*{ props.data.speceficCompetencies && (
        <table className={`${classes.borders} ${classes.table}`}>
          <tr>
            <th rowSpan={2} className={`${classes.leftHeaderTitle}`}>{props.leftHeader}</th>
            <th colSpan={6} className={`${classes.averageTitle}`} scope="colgroup">Average Score by Category</th>
          </tr>
          <tr>
            <td scope="col" className={`${classes.groupCellLight}`}>Self</td>
            <td scope="col" className={`${classes.groupCellLight}`}>Overall</td>
            <td scope="col" className={`${classes.groupCell}`}>IS</td>
            <td scope="col" className={`${classes.groupCell}`}>Peers</td>
            <td scope="col" className={`${classes.groupCell}`}>DR</td>
            <td scope="col" className={`${classes.groupCell}`}>Others</td>
          </tr>
          {
            props.data && props.data.speceficCompetencies && props.data.speceficCompetencies.map(competency => (
              <tr>
                <th scope="row" className={classes.competenciesTableHeader}>{competency.competencyName}</th>
                {competency.groupsScores.map(score => (
                  <>
                    <td style={handleScoreStyle(score)} className={classes.scoreDataCell}>{score}</td>
                  </>
                ))}
              </tr>
            ))
          }
        </table>
      )
      }*/}
    </>
  );
};

export default ReportTable;
