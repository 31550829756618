import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import TableContainer from './components/TableContainer';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DataCardsContainer from './components/DataCardsContainer';
import SummaryDataContainer from './components/SummaryDataContainer';
import { useRef } from 'react';
import { IStore } from 'apps/feedr-react/src/app/store';
import { IUser } from '@feedr/types';
import useAxiataReportState from '../useAxiataReportState';
import QuestionCard from './components/QuestionCard';
import WebFont from 'webfontloader';
import TopHeaderInfo from './components/TopHeaderInfo';

interface PrintAxiataPersonNewReportProps {
  projectId: string;
  rateeId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@page': {
      size: 'landscape',
      margin: '0.4in 0',
    },
    '#printDiv': {
      '& *': {
        fontFamily: `Muli`,
      },
    },
    container: {
      display: 'flex',
      gap: '7px',
      '&>.col': {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
      },
      '&>div': {},
    },

    questionsContainer: {
      display: 'flex',
      gap: '10px',
      marginBottom: 6,
    },
  }),
);

const AxiataReport: React.FC<RouteComponentProps<
  PrintAxiataPersonNewReportProps
>> = props => {
  const classes = useStyles({});
  const data = useAxiataReportState(props.rateeId, props.projectId);
  const { isAllLoaded } = data;
  const [isFontsLoaded, setFontsLoaded] = React.useState(false);

  React.useLayoutEffect(() => {}, [isAllLoaded, isFontsLoaded]);

  React.useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Muli:n4,n7,i4,i7'], // normal, bold, italic, bold italic
        urls: ['https://fonts.cdnfonts.com/css/muli'],
      },
      fontactive: () => {
        setFontsLoaded(true);
      },
    });
  }, []);

  const isQuestionsHaveFeedback = questions => {
    if (questions) {
      return questions.some(q => {
        return q.feedbacks.some(f => {
          return f.length;
        });
      });
    }
    return false;
  };

  const handleQuestions = (questions: any) => {
    const _questions = questions.map(q => {
      return {
        title: q.title,
        feedbacks: q.feedbacks.slice(),
      };
    });
    let ok = true;
    let counter = 0;
    let allQuestions = [];
    while (ok) {
      const okays = [false, false, false];
      const newQuestions = _questions.map((question, index) => {
        let count = 0;
        question.feedbacks.map(f => (count += f.length));
        let nq = [];
        const maxLimit = counter === 0 ? 900 : 1100;
        if (count > maxLimit || (question.feedbacks.length > 3 && count > 500)) {
          okays[index] = true;
          let keepSplicing = true;
          while (keepSplicing && question.feedbacks.length > 0) {
            let totalSubSum = 0;
            nq.map(f => (totalSubSum += f.length));
            if (
              totalSubSum + question.feedbacks[0].length > maxLimit ||
              (nq.length > 3 && totalSubSum > 500)
            )
              keepSplicing = false;
            else {
              nq = [...nq, ...question.feedbacks.splice(0, 1)];
              let c = 0;
              nq.map(f => (c += f.length));
              keepSplicing = c < maxLimit;
            }
          }
        } else {
          okays[index] = false;
          nq = question.feedbacks.splice(0, question.feedbacks.length);
        }
        return {
          title: question.title,
          feedbacks: nq,
        };
      });
      let _continue = false;
      okays.forEach(o => (_continue = _continue || o));
      ok = _continue;
      counter += 1;
      allQuestions.push(newQuestions);
    }
    return Object.values(allQuestions);
  };
  const printPageRef = useRef<HTMLDivElement>();
  return (
    <>
      {isAllLoaded && isFontsLoaded && (
        <div ref={printPageRef} id={'printDiv'}>
          <TopHeaderInfo
            name={data.name}
            position={data.position}
            opco={data.opco}
            date={''}
          />
          <div className={`${classes.container}`} style={{ pageBreakAfter: 'always' }}>
            <div>
              <TableContainer
                data={data.quadrants.cultureFit}
                headerColor={'#F39225'}
                cardTitle={'CULTURE FIT'}
                leftHeader={'Core Values'}
              />
              <TableContainer
                data={data.quadrants.leading}
                headerColor={'#164396'}
                cardTitle={'Leading'}
                leftHeader={'Competencies'}
              />
              <TableContainer
                data={data.quadrants.amplifiers}
                headerColor={'#B3D334'}
                cardTitle={'AMPLIFIERS'}
                leftHeader={''}
              />
            </div>
            <div>
              <TableContainer
                data={data.quadrants.skills}
                jobSpecific={data.quadrants.jobSpecific}
                headerColor={'#884877'}
                cardTitle={'SKILLS'}
                leftHeader={'Competencies'}
              />
              <DataCardsContainer data={data.reportHighlight} />
            </div>
            <div>
              <SummaryDataContainer data={data.raterDetails} />
            </div>
          </div>
          {handleQuestions(data.questions).map(
            (questions, questionsIndex) =>
              (questionsIndex === 0 || isQuestionsHaveFeedback(questions)) && (
                <React.Fragment
                  key={`question_${new Date().getTime()}_${questionsIndex}`}
                >
                  <TopHeaderInfo
                    name={data.name}
                    position={data.position}
                    opco={data.opco}
                    date={''}
                  />
                  <div
                    className={`${classes.questionsContainer}`}
                    style={{ pageBreakAfter: 'always' }}
                  >
                    {questions.map((question: any, index) => (
                      <QuestionCard
                        key={`${question.title}_${new Date().getTime()}`}
                        index={index}
                        questionTitle={question.title}
                        questionFeedbacks={question.feedbacks}
                        showHeader={questionsIndex === 0}
                      />
                    ))}
                  </div>
                </React.Fragment>
              ),
          )}
        </div>
      )}
    </>
  );
};

export default AxiataReport;
