import React from 'react';
import { RouteComponentProps } from '@reach/router';
import ErrorBoundary from '../../components/ErrorBoundary';
import { useFirebase } from 'react-redux-firebase';
import { Typography } from '@material-ui/core';
import LoadingPage from '../../components/Loader/LoadingPage';
import { useQueryParam, StringParam } from 'use-query-params';
import { useEffectAsync } from '../../utils/utilities';

const RouteByToken: React.FC<RouteComponentProps> = props => {
  const firebase = useFirebase();
  const [token] = useQueryParam('token', StringParam);
  const [isAuthAttempt, setIsAuthAttempt] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  useEffectAsync(async () => {
    firebase
      .auth()
      .signInWithCustomToken(token)
      .then(() => {
        setIsAuthenticated(true);
      })
      .catch(error => {
        setIsAuthenticated(false);
      })
      .finally(() => {
        setIsAuthAttempt(true);
      });
  }, [token]);

  return isAuthAttempt ? (
    isAuthenticated ? (
      <ErrorBoundary>{props.children}</ErrorBoundary>
    ) : (
      // need id to indicate page finished loading for print report
      <div id={'printDiv'}>
        <Typography variant={'h4'}>Invalid Token</Typography>
      </div>
    )
  ) : (
    <LoadingPage msg={'Loading...'} />
  );
};

export default RouteByToken;
