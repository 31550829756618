import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

interface SeparatorProps {}

const useStyle = makeStyles((theme: Theme) => ({
  separator: {
    height: 1,
    background: `#7E7E7E`,
    margin: `10px 0`,
    opacity: 0.35,
  },
}));

export const Separator: React.FC<SeparatorProps> = props => {
  const style = useStyle();
  return <div className={`${style.separator} w-100`} />;
};
