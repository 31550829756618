import * as React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface ProgressBarProps {
  value: number;
  leftText: string;
  rightText: string;
  color: string;
  showBold?: boolean;
  longestText?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  progressBarMain: {
    display: 'flex',
    alignItems: 'center',
    padding: `4px 0`,
    fontSize: 18,
  },
  progressMidSection: {
    margin: '0 5px',
  },
  progressBar: {
    borderRadius: 0,
    backgroundColor: `unset`,
    height: 15,
  },
  progressLeftSection: {
    textAlign: 'right',
  },
  progressRightSection: {
    margin: '0 5px',
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  progressTextLeft: {
    paddingLeft: 5,
  },
  progressFontStyle: {
    fontWeight: 700,
  },
  circleProgress: {
    width: 20,
  },
}));

export const ProgressBar: React.FC<ProgressBarProps> = props => {
  const width = (props.value / 5) * 300;

  const style = useStyles({});

  return (
    <div className={style.progressBarMain}>
      <div className={style.progressLeftSection}>
        <div
          className={props.showBold ? style.progressFontStyle : ''}
          style={{ width: props.longestText * 10 }}
        >
          {`${props.leftText}`}
        </div>
      </div>
      <div className={style.progressRightSection}>
        <div className={style.progressMidSection}> : </div>
        <div
          className={style.progressBar}
          style={{
            width: width,
            backgroundColor: props.color,
          }}
        />
        <div
          className={`${style.progressTextLeft} ${props.showBold &&
            style.progressFontStyle}`}
        >
          {props.rightText || '-'}
        </div>
      </div>
    </div>
  );
};
