/**
 * Round number to precision decimal places towards the next odd value.
 * @param value
 * @param precision
 */
export const round = (value: number, precision = 0) => {
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
};

export const lowerCaseAndTrimString = (str: string) => str.toLowerCase().trim();

/** reference: https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-120.php */
export const toSnakeCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_');
