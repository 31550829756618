export const TRANSLATIONS_TR = {
  answer: '[tr] Answer',
  average: 'Ortalama',
  breakdown_based_on_ratee: '(tr) Breakdown Based on Ratee',
  breakdown_based_on_rater: '(tr) Breakdown Based on Rater',
  candidate: 'Aday',
  comment: '[tr] Comment',
  competency: 'Yetkinlik',
  count: '[tr] Count',
  definitions: {
    ratees: '[tr] Individuals that receive ratings',
    raters: '[tr] Individuals that provide ratings',
    not_started: '[tr] Invited and has not started',
    in_progress: '[tr] In progress of completing the survey',
    completed: '[tr] Has completed but yet to submit the survey',
    submitted: '[tr] Has completed and submitted the survey',
    not_applicable: '[tr] Not applicable',
    unknown: '[tr] Relationship is not close enough to give ratings',
    remark: '[tr] *This can be applied across this excel',
    self: '[tr] Self ratings',
    manager: '[tr] Ratings provided by the Manager',
    peer: '[tr] Ratings provided by the Peer',
    direct_report: '[tr] Ratings provided by the Direct Report',
    others: '[tr] Ratings provided by the Others',
    rater_average:
      '[tr] The ratings of all your feedback providers (Excluding your self ratings) averaged into a single score',
    group_rater_average:
      '[tr] The ratings of all feedback providers within the project (Excluding your self ratings) averaged into a single score',
    group_average:
      '[tr] The ratings of all feedback providers within the project (Including your self ratings) averaged into a single score',
  },
  description: 'Tanım',
  details: 'Detaylar',
  email: 'E-posta',
  end_date: 'Bitiş Tarihi',
  individual: 'Kişi',
  last_updated: 'Son Güncellenme Tarihi',
  legends: 'Açıklamalar',
  name: 'Ad',
  no: 'No',
  number_of_ratees: '[tr] Number of Ratees',
  number_of_raters: '[tr] Number of Raters',
  number_of_submitted_raters: '[tr] Number of Submitted Raters',
  opinions: '(tr) Opinions',
  order: '[tr] Order',
  overall_rating_completion: '(tr) Overall Rating Completion',
  percentage: 'Yüzde',
  project: 'Proje',
  project_statuses: {
    ACTIVE: 'AKTİF',
    INACTIVE: 'AKTİF DEĞİL',
  },
  question: '[tr] Question',
  ratee: '[tr] Ratee',
  ratee_of: "[tr] Ratee's",
  ratees: '[tr] Ratees',
  rater: '[tr] Rater',
  rater_of: "[tr] Rater's",
  raters: '[tr] Raters',
  rating: 'Değerlendirme',
  role: 'Pozisyon',
  roles: {
    Self: '[tr] Self',
    Manager: 'Yönetici',
    Peer: '[tr] Peer',
    'Direct Report': 'Doğrudan Bağlı Çalışan',
    Others: 'Diğerleri',
    Unknown: '[tr] Unknown',
    'Rater Average': '[tr] Rater Average',
    'Group Rater Average': '[tr] Group Rater Average',
    'Group Average': '[tr] Group Average',
  },
  scores: 'Puanlarını',
  start_date: 'Başlama Tarihi',
  status: 'Durum',
  submission_rate: '[tr] Submission Rate',
  submission_summary: '(tr) Submission Summary',
  summary_based_on_ratee: '(tr) Summary Based on Ratee',
  summary_based_on_rater: '(tr) Summary Based on Rater',
  survey: '[tr] Survey',
  survey_statuses: {
    Submitted: '[tr] Submitted',
    Completed: 'Tamamlandı',
    'In Progress': 'Devam ediyor',
    'Not Started': '[tr] Not Started',
    'N/A': 'N/A',
    Unknown: '[tr] Unknown',
  },
  total_participants: '[tr] Total Participants',
};
