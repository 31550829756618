import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { HeaderLabel } from '../Header/HeaderLabel';
import { CircleNumber } from '../CircleNumber/CircleNumber';
import { CircleBoxNumber } from '../CircleNumber/CircleBoxNumber';

export interface CircleData {
  title: string;
  color: string;
  value: number | string;
}

export interface CompetencyCircleItemProps {
  numberIndex?: number;
  title?: string;
  circleData?: CircleData[];
  className?: string;
  titleClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  competencyCircleItem: {},
  overallHeader: {
    paddingBottom: 10,
  },
  overallTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  circleSection: {
    padding: `0 30px`,
    paddingTop: 15,
    paddingBottom: 15,
  },
}));

export const CompetencyCircleItem: React.FC<CompetencyCircleItemProps> = props => {
  const style = useStyles({});
  const { numberIndex = 1, title = `-`, circleData, className, titleClassName } = props;

  return (
    <div className={`${style.competencyCircleItem} ${className}`}>
      <div className={`d-flex flex-row align-items-baseline`}>
        <div className={`pr-2`}>
          <CircleNumber>{numberIndex}</CircleNumber>
        </div>
        <HeaderLabel
          title={title}
          titleClassName={`${style.overallTitle} ${titleClassName}`}
          className={`${style.overallHeader} pt-0 pb-0`}
        />
      </div>
      <div className={`d-flex flex-row justify-content-between ${style.circleSection}`}>
        {circleData?.map((item, key) => {
          return (
            <CircleBoxNumber
              key={`circle-data-${key}`}
              title={item.title || `-`}
              color={item.color}
              value={item.value || `-`}
            />
          );
        })}
      </div>
    </div>
  );
};
