import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  CompetencyBreakdownPage,
  CompetencyBreakdownPageProps,
} from '../../../newReport/components/CompetencyBreakdownPage/CompetencyBreakdownPage';

interface WebCompetencyBreakdownSectionProps extends CompetencyBreakdownPageProps {}

const useStyle = makeStyles((theme: Theme) => ({
  description: {
    fontSize: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
  },
  circleTitle: {
    fontSize: 18,
  },
}));

export const WebCompetencyBreakdownSection: React.FC<WebCompetencyBreakdownSectionProps> = props => {
  const style = useStyle();

  return (
    <CompetencyBreakdownPage
      {...props}
      showWebHeader={true}
      titleClassName={style.title}
      descriptionClassName={style.description}
      circleTitleClassName={style.circleTitle}
    />
  );
};
