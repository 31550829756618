import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';

export interface ImageHeaderProps {
  title?: string;
  imageLink?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  imageHeader: {
    fontSize: 20,
    fontWeight: 600,
  },
  img: {
    height: 40,
    width: 40,
    borderRadius: 40,
    objectFit: `cover`,
  },
}));

export const ImageHeader: React.FC<ImageHeaderProps> = props => {
  const { title, imageLink } = props;
  const style = useStyle();

  return (
    <div className={`d-flex h-100 w-100 align-items-center ${style.imageHeader}`}>
      {imageLink && <img className={style.img} src={imageLink} />}
      <div className={`pl-3`}>{title}</div>
    </div>
  );
};
