import { ICompetencyScore } from '@feedr/types';
import * as _ from 'lodash';
import { roundToFixedDecimal } from '../../../../../../../../../../utils/utilities';
import { i18n } from '@feedr/helpers';

export interface ReportHighlightDataStructure {
  title?: string;
  description?: string;
  data?: string[];
}

export interface ReportHighlightData {
  highlyRatedCompetency: ReportHighlightDataStructure;
  oversight: ReportHighlightDataStructure;
  outPerformOthers: ReportHighlightDataStructure;
  lowestRatedCompetency: ReportHighlightDataStructure;
  hiddenPotential: ReportHighlightDataStructure;
  catchUpOn: ReportHighlightDataStructure;
}

export const getReportHighlightData = (
  scores: ICompetencyScore[],
  ratingScale: number,
  showAll?: boolean,
): ReportHighlightData => {
  const minKeyStrength = ratingScale === 5 ? 4 : ratingScale === 10 ? 8 : 0;
  const maxDevelopment = ratingScale === 5 ? 2 : ratingScale === 10 ? 4 : 0;
  const minGap = ratingScale === 5 ? 0.7 : ratingScale === 10 ? 1.4 : 0;
  const minGroupGap = ratingScale === 5 ? 0.4 : ratingScale === 10 ? 0.8 : 0;

  const roundedScores = scores.map(x => ({
    ...x,
    score: Object.entries(x.score).reduce((scoreList, [scoreType, score]) => {
      scoreList[scoreType] = roundToFixedDecimal(score);
      return scoreList;
    }, {}),
  })) as ICompetencyScore[];
  const reversedScores = roundedScores.slice().reverse(); // make a copy of scores and reverse

  const commonFn = (sorted: ICompetencyScore[]) => {
    if (showAll) {
      return sorted.map(competencyScore => competencyScore.name);
    }

    // ? Only need max 3 items
    return sorted.slice(0, 3).map(competencyScore => competencyScore.name);
  };

  // highest rated
  const getHighlyRatedCompetency = () => {
    // (1) did self rating
    if (roundedScores.length > 0 && !roundedScores[0].score.selfScore) {
      return [];
    }
    // (6) if same score, return the competency by order index
    const sorted = _.sortBy(reversedScores, 'score.rateeAverage')
      // (2) ratee average >= min key strength
      .filter(item => item.score.rateeAverage >= minKeyStrength)
      // (3) self >= min key strength
      .filter(item => item.score.selfScore >= minKeyStrength)
      // (4) rater average >= min key strength
      .filter(item => item.score.raterAverage >= minKeyStrength)
      // (5) sort by ratee average descending
      .reverse();
    return commonFn(sorted);
  };

  // lowest rated
  const getLowestRatedCompetency = () => {
    // (1) did self rating
    if (roundedScores.length > 0 && !roundedScores[0].score.selfScore) {
      return [];
    }
    // (6) if same score, return the competency by order index -- no need to reverse cause scores are sorted ascending
    let sorted = _.sortBy(roundedScores, 'score.rateeAverage')
      // (2) ratee average <= max development
      .filter(item => item.score.rateeAverage <= maxDevelopment);
    // (3) sort by ratee average ascending
    return commonFn(sorted);
  };

  // oversight
  const getOversight = () => {
    // (1) did self rating
    if (roundedScores.length > 0 && !roundedScores[0].score.selfScore) {
      return [];
    }
    // (4) if same score, return the competency by order index
    const diff = reversedScores.map((item: any) => {
      item.blindDiff = item.score?.selfScore - item.score?.raterAverage;
      return item;
    });
    const sorted = _.sortBy(diff, 'blindDiff')
      // (2) self - rater average >= min gap
      .filter((item: any) => item.blindDiff >= minGap)
      // (3) sort by gap descending
      .reverse();
    return commonFn(sorted);
  };

  // hidden potential
  const getHiddenPotential = () => {
    // (1) did self rating
    if (roundedScores.length > 0 && !roundedScores[0].score.selfScore) {
      return [];
    }
    // (4) if same score, return the competency by order index
    const diff = reversedScores.map((item: any) => {
      item.growthDiff = item.score?.raterAverage - item.score?.selfScore;
      return item;
    });
    const sorted = _.sortBy(diff, 'growthDiff')
      // (2) rater average - self >= min gap
      .filter((item: any) => item.growthDiff >= minGap)
      // (3) sort by gap descending
      .reverse();
    return commonFn(sorted);
  };

  const getOutPerformOthers = () => {
    // (3) if same score, return the competency by order index
    const diff = reversedScores.map((item: any) => {
      item.outDiff = item.score?.rateeAverage - item.score?.groupAverage;
      return item;
    });
    const sorted = _.sortBy(diff, 'outDiff')
      // (1) rater average - group rater average >= min group gap
      .filter((item: any) => item.outDiff >= minGroupGap)
      // (2) sort by gap descending
      .reverse();
    return commonFn(sorted);
  };

  const getCatchUpOn = () => {
    // (3) if same score, return the competency by order index
    const diff = reversedScores.map((item: any) => {
      item.diff = item.score?.groupAverage - item.score?.rateeAverage;
      return item;
    });
    const sorted = _.sortBy(diff, 'diff')
      // (1) group rater average - rater average >= min group gap
      .filter((item: any) => item.diff >= minGroupGap)
      // (2) sort by gap descending
      .reverse();
    return commonFn(sorted);
  };

  return {
    highlyRatedCompetency: {
      title: `${i18n.t('report.report_highlights_highest_rated_competency')}`,
      description: `${i18n.t(
        'report.report_highlights_highest_rated_competency_description',
      )}`,
      data: getHighlyRatedCompetency(),
    },
    oversight: {
      title: `${i18n.t('report.report_highlights_oversight')}`,
      description: `${i18n.t('report.report_highlights_oversight_description')}`,
      data: getOversight(),
    },
    outPerformOthers: {
      title: `${i18n.t('report.report_highlights_outperforms_others')}`,
      description: `${i18n.t('report.report_highlights_outperforms_others_description')}`,
      data: getOutPerformOthers(),
    },
    lowestRatedCompetency: {
      title: `${i18n.t('report.report_highlights_lowest_rated_competency')}`,
      description: `${i18n.t(
        'report.report_highlights_lowest_rated_competency_description',
      )}`,
      data: getLowestRatedCompetency(),
    },
    hiddenPotential: {
      title: `${i18n.t('report.report_highlights_hidden_potential')}`,
      description: `${i18n.t('report.report_highlights_hidden_potential_description')}`,
      data: getHiddenPotential(),
    },
    catchUpOn: {
      title: `${i18n.t('report.report_highlights_catch_up_on')}`,
      description: `${i18n.t('report.report_highlights_catch_up_on_description')}`,
      data: getCatchUpOn(),
    },
  };
};
