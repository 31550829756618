import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DataCard from './DataCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsContainer: {
      display: 'flex',
      width: 533,
      flexWrap: 'wrap',
      gap: '5px',
    },
  }),
);
const DataCardsContainer: React.FC<{
  data: any;
}> = props => {
  const classes = useStyles({});

  const replaceStringIfMatchInArray = (arr, stringToFind, replaceWithString) => {
    return arr.map(a => (a === stringToFind ? replaceWithString : a));
  };

  const hardCodedStringsReplacement = [
    {
      stringToFind: 'Business And Market Savvy',
      stringToReplace: 'Business & Market Savvy',
    },
  ];

  const handleHardCodedReplacement = (arr, hardCodedStringsReplacement) => {
    let newArr = arr.slice();
    hardCodedStringsReplacement.forEach(hc => {
      newArr = replaceStringIfMatchInArray(newArr, hc.stringToFind, hc.stringToReplace);
    });
    return newArr;
  };

  return (
    <div className={classes.cardsContainer}>
      <DataCard
        cardTitle={'HIGHEST RATED COMPETENCY'}
        cardSubtitle={'Both self and others rated as highest (≥ 4 out of 5)'}
        points={handleHardCodedReplacement(
          props.data.highestRated,
          hardCodedStringsReplacement,
        )}
      />
      <DataCard
        cardTitle={'LOWEST RATED COMPETENCY'}
        cardSubtitle={'Both self and others rated as lowest (≤ 2 out of 5)'}
        points={handleHardCodedReplacement(
          props.data.lowestRated,
          hardCodedStringsReplacement,
        )}
      />
      <DataCard
        cardTitle={'HIDDEN POTENTIAL'}
        cardSubtitle={'Others rated higher than self with a gap of 0.7 or more'}
        points={handleHardCodedReplacement(
          props.data.hiddenPotential,
          hardCodedStringsReplacement,
        )}
      />
      <DataCard
        cardTitle={'OVERSIGHT'}
        cardSubtitle={'Self rated higher than others with a gap of 0.7 or more'}
        // points={['Business Acumen']}
        points={handleHardCodedReplacement(
          props.data.oversight,
          hardCodedStringsReplacement,
        )}
      />
    </div>
  );
};

export default DataCardsContainer;
