import * as React from 'react';
import { Header } from '../../../newReport/components/Header/Header';
import { BulbSvg } from '../../../newReport/assets/BulbSvg';
import { HighlightBox } from '../../../newReport/components/HighlightBox/HighlightBox';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { ReportHighlightData } from '../../../newReport/components/ReportHighlightPage/stores/reportHighlightDataStore';
import { ICompetencyScore } from '@feedr/types';
import { WebHeader } from '../WebHeader/WebHeader';

interface WebReportHighlightsProps {
  data: ReportHighlightData;
}

const useStyle = makeStyles((theme: Theme) => ({
  reportHighlightPage: {
    pageBreakAfter: 'always',
  },
  reportHighlightSection: {},
  summarySection: {},
  gridBox: {
    display: `grid`,
    gridTemplateColumns: `repeat(3, 1fr)`,
    gridTemplateRows: `repeat(2, 1fr)`,
    gridColumnGap: `10px`,
    gridRowGap: `10px`,
  },
  section: {
    paddingTop: 30,
  },
  legendItemsContainer: {
    pageBreakInside: 'avoid',
  },
  blurBox: {
    boxShadow: '1px 5px 11px 0px #00000012',
  },
}));

export const WebReportHighlightsSection: React.FC<WebReportHighlightsProps> = props => {
  const { data } = props;
  const style = useStyle();

  return (
    <div className={style.reportHighlightSection}>
      <WebHeader
        icon={<BulbSvg />}
        title={`Report Highlights`}
        description={`This section shows the key takeaways from the feedback gathered`}
      />
      <div className={style.gridBox}>
        {data &&
          _.map(data, (data, key) => {
            return (
              <HighlightBox
                key={`highlight-box-${key}`}
                title={data.title}
                description={data.description}
                data={data.data}
                classNameBlurBox={style.blurBox}
              />
            );
          })}
      </div>
    </div>
  );
};
