import * as React from 'react';

interface RocketSvgProps {}

export const RocketSvg: React.FC<RocketSvgProps> = props => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 46.091 46.008"
      >
        <g id="international" transform="translate(0.582 0.507)">
          <g id="Group_11013" data-name="Group 11013" transform="translate(0.001 0)">
            <g id="Group_11012" data-name="Group 11012" transform="translate(0 0)">
              <path
                id="Path_8325"
                data-name="Path 8325"
                d="M44.745.257a.879.879,0,0,0-.821-.235L27.633,3.81a.879.879,0,0,0-.423.235l-8.674,8.674-8.563,0h0a.879.879,0,0,0-.621.257L.259,22.065a.879.879,0,0,0,0,1.243L4.64,27.69,1.308,37.6a.879.879,0,0,0,.212.9l2.443,2.443L6.5,43.483a.879.879,0,0,0,.9.212l9.907-3.332,4.381,4.381a.879.879,0,0,0,1.243,0l9.093-9.093a.879.879,0,0,0,.257-.622l0-8.563,8.674-8.674a.879.879,0,0,0,.235-.422L44.98,1.078A.879.879,0,0,0,44.745.257ZM2.123,22.687l8.214-8.214,6.443,0L5.346,25.91ZM7.362,41.855,5.827,40.32l4.167-4.167a.879.879,0,0,0-1.243-1.243L4.584,39.077,3.148,37.64l2.88-8.563,9.9,9.9Zm14.953,1.024-3.223-3.223L30.527,28.223l0,6.443ZM17.85,38.414l-2.925-2.925L31.6,18.81a.879.879,0,1,0-1.243-1.243L13.682,34.246,6.589,27.153,27.832,5.909l2.925,2.925L13.5,26.092a.879.879,0,1,0,1.243,1.243L32,10.077l7.093,7.093ZM39.839,15.429,29.573,5.164l6.679-1.553,5.139,5.139Zm2.022-8.7L38.269,3.142l4.679-1.088Z"
                transform="translate(-0.001 0)"
                fill="#d69023"
                stroke="#d69023"
                strokeWidth="1"
              />
            </g>
          </g>
          <g
            id="Group_11015"
            data-name="Group 11015"
            transform="translate(32.412 15.001)"
          >
            <g id="Group_11014" data-name="Group 11014">
              <path
                id="Path_8326"
                data-name="Path 8326"
                d="M370.246,170.929a.878.878,0,1,0,.258.621A.884.884,0,0,0,370.246,170.929Z"
                transform="translate(-368.746 -170.671)"
                fill="#d69023"
                stroke="#d69023"
                strokeWidth="1"
              />
            </g>
          </g>
          <g
            id="Group_11017"
            data-name="Group 11017"
            transform="translate(11.074 28.003)"
          >
            <g id="Group_11016" data-name="Group 11016">
              <path
                id="Path_8327"
                data-name="Path 8327"
                d="M127.476,318.858a.878.878,0,1,0,.258.621A.886.886,0,0,0,127.476,318.858Z"
                transform="translate(-125.976 -318.6)"
                fill="#d69023"
                stroke="#d69023"
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
