import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { ImageHeader, ImageHeaderProps } from '../ImageHeader/ImageHeader';
import { Box, Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { FeedrLogo } from '../../../../../../../../../components/SvgIcons/FeedrLogo';

interface WebReportHeaderProps extends ImageHeaderProps {
  buttonProps?: ButtonProps;
  buttonLoading?: boolean;
}

const useStyle = makeStyles((theme: Theme) => ({
  webReportHeader: {
    position: 'sticky',
    zIndex: 1000,
    top: 0,
    background: 'white',
    width: `100%`,
    height: 60,
    padding: `20px 30px`,
    boxShadow: `0px 0px 17px 3px #bbbbbb`,
  },
  rightSection: {
    width: 360,
  },
  poweredBy: {
    fontSize: 14,
    fontWeight: 400,
  },
  button: {
    outline: 'none !important',
    minWidth: 125,
    minHeight: 30,
  },
}));

export const WebReportHeader: React.FC<WebReportHeaderProps> = props => {
  const { buttonProps, buttonLoading, ...otherProps } = props;
  const style = useStyle();

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${style.webReportHeader}`}
    >
      <ImageHeader {...otherProps} />
      <div
        className={`d-flex align-items-center justify-content-between ${style.rightSection}`}
      >
        <div className={`d-flex align-items-center`}>
          <div className={`${style.poweredBy}  pr-2`}>Powered by</div>
          <FeedrLogo width={55} />
        </div>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          className={style.button}
          disabled={buttonLoading}
          {...buttonProps}
        >
          {buttonLoading ? <CircularProgress size={16} /> : 'Download PDF'}
        </Button>
      </div>
    </div>
  );
};
