import * as React from 'react';

interface ReportSvgProps {}

export const ReportSvg: React.FC<ReportSvgProps> = props => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 36.926 48.477"
      >
        <g id="report" transform="translate(-61)">
          <path
            id="Path_8328"
            data-name="Path 8328"
            d="M96.506,0H62.42A1.42,1.42,0,0,0,61,1.42V47.057a1.42,1.42,0,0,0,1.42,1.42H96.506a1.42,1.42,0,0,0,1.42-1.42V1.42A1.42,1.42,0,0,0,96.506,0ZM73.782,2.84H85.144v1.42a1.394,1.394,0,0,1-1.362,1.42H75.144a1.394,1.394,0,0,1-1.362-1.42V2.84Zm21.3,42.8H63.84V2.84h7.1v1.42a4.237,4.237,0,0,0,4.2,4.261h8.639a4.237,4.237,0,0,0,4.2-4.261V2.84h7.1Z"
            fill="#d69021"
          />
          <path
            id="Path_8329"
            data-name="Path 8329"
            d="M142.3,123.211a1.42,1.42,0,0,0-1.42,1.42v5.214h-2.84v-3.009a1.42,1.42,0,1,0-2.84,0v3.009H132.36v-8.427a1.42,1.42,0,1,0-2.84,0v8.427h-2.84v-1.6a1.42,1.42,0,0,0-2.84,0v1.6h-1.42a1.42,1.42,0,1,0,0,2.84h22.724a1.42,1.42,0,1,0,0-2.84h-1.421v-5.214A1.42,1.42,0,0,0,142.3,123.211Z"
            transform="translate(-54.317 -108.636)"
            fill="#d69021"
          />
          <path
            id="Path_8330"
            data-name="Path 8330"
            d="M145.143,284H122.419a1.42,1.42,0,1,0,0,2.84h22.723a1.42,1.42,0,1,0,0-2.84Z"
            transform="translate(-54.318 -257.11)"
            fill="#d69021"
          />
          <path
            id="Path_8331"
            data-name="Path 8331"
            d="M145.143,344H122.419a1.42,1.42,0,0,0,0,2.84h22.723a1.42,1.42,0,0,0,0-2.84Z"
            transform="translate(-54.318 -311.43)"
            fill="#d69021"
          />
          <path
            id="Path_8332"
            data-name="Path 8332"
            d="M132.36,404h-9.941a1.42,1.42,0,0,0,0,2.84h9.941a1.42,1.42,0,1,0,0-2.84Z"
            transform="translate(-54.318 -365.749)"
            fill="#d69021"
          />
        </g>
      </svg>
    </div>
  );
};
