import { RouteComponentProps, Link } from '@reach/router';
import { useFirebase } from 'react-redux-firebase';
import { useEffectAsync } from '../../utils/utilities';
import React from 'react';
import LoadingPage from '../../components/Loader/LoadingPage';

interface IEmptyPage {
  isAuthLoaded: boolean;
}

const EmptyPage: React.FC<RouteComponentProps<IEmptyPage>> = ({ isAuthLoaded }) => {
  const firebase = useFirebase();
  useEffectAsync(async () => {
    // already tried attempt firebase auth and is not authenticated
    if (isAuthLoaded) {
      await firebase.logout();
    }
  }, [isAuthLoaded]);

  return isAuthLoaded ? (
    <h2>
      You need to <Link to={'/auth/login'}>login</Link>
    </h2>
  ) : (
    <LoadingPage msg={'Loading...'} />
  );
};

export default EmptyPage;
