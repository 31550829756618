import * as React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

interface CircleBoxNumberProps {
  value: number | string;
  title: string;
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  circleBoxNumber: {
    height: 60,
    width: 60,
    border: `3px solid #4466B0`,
    borderRadius: 50,
    color: `#4466B0`,
    fontSize: 22,
    fontWeight: 600,
  },
  title: {
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontWeight: 600,
    fontSize: 16,
    textAlign: 'center',
    maxWidth: 130,
  },
}));

export const CircleBoxNumber: React.FC<CircleBoxNumberProps> = props => {
  const theme = useTheme();
  const { title = `-`, value = `-`, color = theme.palette.grey[400] } = props;
  const style = useStyles({});

  return (
    <div
      className={`d-flex flex-column align-items-center align-self-start justify-content-center`}
    >
      <div
        className={`d-flex align-items-center justify-content-center ${style.circleBoxNumber}`}
        style={{
          borderColor: color,
          color: color,
        }}
      >
        {value}
      </div>
      <div className={style.title}>{title}</div>
    </div>
  );
};
