import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { LegendItem } from '../Legend/LegendItem/LegendItem';
import { i18n } from '@feedr/helpers';

export interface CompetencyTableData {
  rank?: string | number;
  competency?: string;
  description?: string;
  descriptionOrderIndex?: number;
  competencyOrderIndex?: number;
  selfScore?: string | number;
  raterAverage?: string | number;
  raterAverageRounded?: string | number;
  raterAverageColor?: string;
}

interface CompetencyTableProps {
  data: CompetencyTableData[];
  className?: string;
}

const useStyle = makeStyles((theme: Theme) => ({
  competencyTable: {},
  table: {
    fontSize: 20,
  },
  tbody: {},
  td: {
    padding: `20px 0px`,
  },
  tr: {
    borderTop: `1px solid #C1C1C1`,
    borderBottom: `1px solid #C1C1C1`,
    padding: `30px`,
  },
  thead: {
    fontWeight: 500,
    color: `#7E7E7E`,
    height: 50,
    lineHeight: 1.25,
  },
  rank: {
    width: `10%`,
    padding: `0 15px`,
  },
  competency: {
    width: `20%`,
  },
  description: {
    width: `40%`,
  },
  selfScore: {
    width: `15%`,
  },
  raterAvg: {
    width: `15%`,
  },
  avoidPageBreak: {
    pageBreakInside: 'avoid',
  },
  legend: {
    width: 50,
    height: 50,
  },
  legendValue: {
    filter: 'none',
  },
}));

export const CompetencyTable: React.FC<CompetencyTableProps> = props => {
  const style = useStyle();
  const { data, className } = props;
  return (
    <div className={`${style.competencyTable} w-100 h-100`}>
      <table className={`${style.table} ${className} w-100 h-100`}>
        <thead className={`${style.thead}`}>
          <tr>
            <td className={`${style.td} ${style.rank} text-center`}>{`${i18n.t(
              'report.rank',
            )}`}</td>
            <td className={`${style.td} ${style.competency}`}>{`${i18n.t(
              'report.competency',
            )}`}</td>
            <td className={`${style.td} ${style.description}`}>{`${i18n.t(
              'report.description',
            )}`}</td>
            <td className={`${style.td} ${style.selfScore} text-center`}>{`${i18n.t(
              'report.self_score',
            )}`}</td>
            <td className={`${style.td} ${style.raterAvg} text-center`}>{`${i18n.t(
              'report.rater_average',
            )}`}</td>
          </tr>
        </thead>
        <tbody className={`${style.tbody}`}>
          {data.map((item, key) => {
            return (
              <tr className={`${style.tr}`} key={`ct-data-${key}`}>
                <td className={`${style.td} text-center`}>{item.rank}</td>
                <td className={`${style.td} pr-4`}>{item.competency}</td>
                <td className={`${style.td}`}>{item.description}</td>
                <td className={`${style.td} text-center`}>{item.selfScore}</td>
                <td className={`${style.td} text-center`}>
                  <LegendItem
                    color={item.raterAverageColor}
                    value={item.raterAverageRounded}
                    className={style.legend}
                    classNameValue={style.legendValue}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
