export const TRANSLATIONS_VI = {
  report: {
    about_the_report: 'Tổng quan',
    about_the_report_p1:
      'Phản hồi trong báo cáo này được thu thập từ các bên với những vai trò công việc khác nhau, giúp bạn nhận biết và cân nhắc điểm mạnh và điểm cần cải thiện. Để sử dụng báo cáo này hiệu quả, bạn nên xác định những chủ đề và xu hướng được thể hiện trong nội dung chi tiết. Phần “Điểm nổi bật” tóm tắt điểm mạnh, cơ hội cải thiện và những thông tin khác. Thông tin chi tiết ở phần sau báo cáo sẽ giúp bạn hiểu hơn về điểm số của mình.',
    about_the_report_p2: 'Hãy lưu ý rằng',
    about_the_report_p3:
      'mỗi cá nhân đều có những thế mạnh và cơ hội phát triển riêng. Hãy sử dụng báo cáo này để hiểu thêm các điểm bạn có thể cải thiện để góp phần nâng cao hiệu quả công việc.',
    all_raters: 'Tất cả đánh giá',
    comparison: 'So sánh',
    comparison_description: 'Khác biệt điểm số đánh giá giữa bản thân và đồng nghiệp',
    competency: 'Năng lực',
    competency_breakdown: 'Nội dung chi tiết các năng lực',
    competency_breakdown_description:
      'Phần này chia nhỏ các đánh giá năng lực theo người đánh giá, giúp nhanh chóng xác định sự khác biệt giữa đánh giá của bạn và người khác',
    competency_rating_by_item: 'Đánh giá năng lực theo hạng mục',
    description: 'Miêu tả',
    direct_report: 'Đồng nghiệp cùng cấp',
    direct_reports: 'Nhân viên báo cáo trực tiếp',
    group: 'Nhóm',
    group_average: 'Điểm trung bình trên cùng nhóm',
    group_rater_average: 'Điểm trung bình từ người đánh giá khác trên cùng nhóm',
    highest_to_lowest_competency_item_ratings:
      'Xếp hạng hạng mục năng lực cao nhất đến thấp nhất',
    highest_to_lowest_competency_item_ratings_description:
      'Phần này xếp hạng điểm số của bạn từ cao đến thấp dựa trên điểm số đánh giá bạn nhận được trong mỗi câu. Bạn có thể so sánh điểm tự đánh giá của mình với điểm mà người khác đã đánh giá cho bạn.',
    how_to_read_this_report: 'Cách đọc báo cáo',
    how_to_read_this_report_all_raters:
      'toàn bộ kết quả đánh giá dành cho bạn (Bao gồm kết quả tự đánh giá và kết quả từ các người phản hồi khác)',
    how_to_read_this_report_group_average:
      'điểm trung bình tất cả kết quả đánh giá của nhóm người có cùng yêu cầu cấp bậc năng lực với bạn (bao gồm điểm bạn tự đánh giá)',
    how_to_read_this_report_group_rater_average:
      'điểm trung bình kết quả đánh giá từ các bên của nhóm người có cùng yêu cầu cấp bậc năng lực với bạn (không bao gồm điểm bạn tự đánh giá)',
    how_to_read_this_report_p1:
      'Thang điểm đánh giá nằm trong khoảng từ 1 (thấp nhất) đến 5 (cao nhất) và được làm tròn đến 1 chữ số thập phân sau khi được bình quân từ các kết quả đánh giá.',
    how_to_read_this_report_p2:
      'Dưới đây là các thuật ngữ được sử dụng trong báo cáo này:',
    how_to_read_this_report_rater_average:
      'bình quân kết quả đánh giá của tất cả các người phản hồi khác (không bao gồm kết quả tự đánh giá) dành cho bạn',
    how_to_read_this_report_self: 'kết quả bạn tự đánh giá',
    introduction: 'Giới thiệu',
    manager: 'Quản lý trực tiếp',
    managers: 'Quản lý trực tiếp',
    no_feedback_was_given: 'Không nhận được phản hồi.',
    others: 'Đối tượng khác',
    overall_competency_rating: 'Đánh giá tổng thể',
    peers: 'Đồng nghiệp cùng cấp',
    rank: 'Xếp hạng',
    rater_average: 'Điểm trung bình người đánh giá',
    raters_want_you_to_know: 'Ý kiến chia sẻ từ người đánh giá',
    raters_want_you_to_know_description:
      'Phần này cung cấp thông tin phản hồi định tính và thông tin chuyên sâu từ những người đánh giá',
    rating_guide: 'Hướng dẫn đánh giá',
    rating_overview: 'Đánh giá tổng thể',
    report_highlights: 'ĐIỂM NỔI BẬT',
    report_highlights_catch_up_on: 'Năng lực có thể cải thiện',
    report_highlights_catch_up_on_description:
      'Năng lực mà bạn được đánh giá thấp hơn mức trung bình của nhóm có cùng yêu cầu cấp bậc năng lực với bạn (ví dụ: điểm trung bình của nhóm là 4, điểm trung bình của bạn là 3.6)',
    report_highlights_description: 'Những kết luận chính được rút ra từ các phản hồi:',
    report_highlights_hidden_potential: 'Điểm tiềm ẩn',
    report_highlights_hidden_potential_description:
      'Những năng lực bạn tự đánh giá thấp hơn với đánh giá từ người khác (ví dụ: bạn đánh giá 3.3, người khác đánh giá 4)',
    report_highlights_highest_rated_competency: 'Năng lực được đánh giá cao nhất',
    report_highlights_highest_rated_competency_description:
      'Những năng lực được đánh giá cao ( ≥4/5 điểm )',
    report_highlights_lowest_rated_competency: 'Năng lực bị đánh giá thấp nhất',
    report_highlights_lowest_rated_competency_description:
      'Năng lực được đánh giá thấp (≤2 trên 5)',
    report_highlights_outperforms_others: 'Năng lực vượt trội',
    report_highlights_outperforms_others_description:
      'Năng lực bạn được đánh giá cao hơn mức trung bình của nhóm có cùng yêu cầu cấp bậc năng lực với bạn (ví dụ: điểm trung bình của nhóm là 3, điểm trung bình của bạn là 3,4)',
    report_highlights_oversight: 'Điểm chênh lệch',
    report_highlights_oversight_description:
      'Năng lực bạn tự đánh giá cao hơn so với đánh giá từ người khác (ví dụ: bạn đánh giá 4,người khác đánh giá bạn 3,3)',
    results_valid_for_12_months_p1: 'Kết quả báo cáo này có ',
    results_valid_for_12_months_p2: 'giá trị sử dụng trong 12 tháng.',
    self: 'Điểm tự đánh giá',
    self_score: 'Tự đánh giá',
    summary: 'Tóm tắt',
    summary_description: 'Tóm tắt đánh giá tổng thể trên tất cả năng lực',
  },
};
