import * as React from 'react';

interface FeedrLogoProps {
  width?: number;
}

export const FeedrLogo: React.FC<FeedrLogoProps> = props => {
  const { width = 80 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 1367.352 796.684"
    >
      <g data-name="Group 10986" transform="translate(-437.547 -386.175)">
        <g data-name="Group 1" transform="translate(438 386.175)">
          <g data-name="Group 10985" transform="translate(29.475)">
            <g data-name="Group 10977" transform="translate(675.354)">
              <path
                fill="#d68f23"
                d="M118.488 222.654c4-4.022 17.9-23.042 21.944-35.737 2.091-6.574 5.409-16.295 7.267-22.906 6.243-22.193 13.464-40.606 18.431-63.1 3.7-16.774 4.47-34.2 8.335-50.93a136.408 136.408 0 0113.221-34.791c7.205-13.073 23.755-20.144 36.761-11.173 9.065 6.255 11.6 15.578 12.378 25.515 1.8 22.9 1.193 28.365-2.189 50.707-2.5 16.507-4.41 33.114-6.079 49.728-.4 4 .785 8.319 1.965 12.276 2.215 7.416 7.094 8.974 12.448 3.238 5.959-6.384 11.676-13.373 15.814-21.01C273.875 96.621 281.855 84.529 297.5 57c8.651-15.226 19.089-29.671 34.562-39.47 11.23-7.11 21.567-6.421 32.068 1.277 10.1 7.4 11.725 17.849 10.265 28.988-2.016 15.37-8.964 29.04-16.459 42.365-14.922 26.531-18.519 38.878-32.969 65.662-4.166 7.721-8.064 16.459-3.8 28.159 6.678-3.529 13.791-5.83 19.012-10.329 14.182-12.218 15.654-11.56 29.487-24.193 17.392-15.883 31.942-34.99 53.4-46.3 7.715-4.065 17.247-5.914 25.4-1.1 9.7 5.729 12.06 13.32 11.578 24.332-.578 13.252-6.8 24.517-14.637 34.872-20.477 27.054-69.805 74.426-74.316 79.87-2.445 2.948-3.48 8.009-3.112 11.926.168 1.788 5.117 3.737 8.17 4.465a17.78 17.78 0 008.756-.591c15.634-4.64 17.983-4.12 33.5-9.147 11.659-3.776 23.133-8.12 34.747-12.04 7.795-2.632 20.443.257 24.115 5.24 4.924 6.684 3.951 15.853-1.659 24.816-8.043 12.85-21.516 18.475-33.646 25.638-20.038 11.835-41.334 21.659-60.736 34.406-11.251 7.393-20.677 18.135-29.451 28.6-19.214 22.929-39.891 44.275-64.483 61.311-28.722 19.895-60.666 29.588-95.748 24.377-33.475-4.971-63.023-18.264-87.2-43-24.15-24.713-38.823-54.9-51.949-86.019-10.948-25.958-20.95-52.318-31.237-78.553-6.073-15.483-12.1-30.99-17.7-46.649a61.376 61.376 0 01-3.377-16.541c-1.325-20.9 13.708-28.946 31.018-30.84 23.094-1.413 35.694 12.923 46.353 31.988 9.087 16.257 11.447 21.669 18.8 38.846 1.458 3.394 8.739 22.427 22.236 13.288z"
                data-name="Path 8302"
                transform="matrix(.985 .174 -.174 .985 188.929 0)"
              ></path>
              <path
                fill="#2c4e8f"
                stroke="#2c4e8f"
                strokeWidth="7"
                d="M303.11 52.6c-3.5 15.728-7.1 30.946-10.188 46.269-1.217 6.041-5.427 4.825-9.131 4.7a213.038 213.038 0 00-78.755 12.045c-60.3 21.266-104.284 61.023-131.41 118.762-19.042 40.53-24.411 83.359-17.534 127.694 10.859 70 47.45 123.022 107.419 159.516 41.929 25.516 88.111 34.5 137.079 30a216.8 216.8 0 0090.326-29.017c37.892-22.1 67.076-52.968 87.266-92.117 1.159-2.249 65.708-14.21 56.46-5.546-8.3 29.153-30.779 78.281-83.722 120.511-103.911 82.885-250.077 79.913-349.932-3.149C-.024 458.241-25.264 323.376 25.293 211.558 77.456 96.186 212.366 37.673 303.11 52.6z"
                data-name="Path 8303"
              ></path>
            </g>
            <g fill="#2c4e8f" data-name="Group 10978" transform="translate(0 37.378)">
              <path
                d="M-406.757 766.256a38.637 38.637 0 0138.639-38.636h47.24c57.251 0 78.515-47.439 78.515-85.058 0-57.254-54.8-83.425-108.776-83.425-34.694 0-69.914 15.717-88.043 44.639a38.146 38.146 0 01-41.324 16.515c-25.335-6.126-37.758-35-24.539-57.466 32.418-55.081 93.163-82.2 153.906-82.2 92.419 0 189.745 50.708 190.563 161.94 0 42.027-9.28 85.478-44.514 113.686a6.834 6.834 0 00.638 11.014c45.574 30.275 65.912 74.78 65.14 128.024-.815 82.6-69.519 166.03-206.92 166.03-93.27 0-149.2-41.128-176.4-95.35-13.211-26.33 2.74-57.973 31.725-63.216a31.548 31.548 0 0134.585 18.866c15.035 35.234 50.975 62.818 110.088 62.818 90.783 0 133.312-49.89 129.223-97.326-3.271-33.533-29.443-81.787-103.869-81.787h-46.815a39.066 39.066 0 01-39.064-39.064z"
                data-name="Path 8304"
                transform="translate(527.326 -480.622)"
              ></path>
              <path
                d="M-120.248 480.922c28.484 0 45.094 32.153 28.612 55.386L-192.2 678.031c42.529-14.724 98.962-12.268 139.037 9.812C9.817 723.831 41.716 790.9 41.716 857.146c0 98.144-69.519 196.288-208.559 196.288-138.222 0-208.559-98.144-208.559-196.288 0-40.076 13.086-80.972 35.171-114.5L-173.2 508.211a65.023 65.023 0 0152.952-27.289zM-292.8 857.146c0 57.25 41.714 112.866 125.954 112.866 85.058 0 125.951-55.615 125.951-112.866s-40.893-114.5-125.951-114.5c-84.237-.001-125.954 57.254-125.954 114.5z"
                data-name="Path 8305"
                transform="translate(699.616 -480.104)"
              ></path>
            </g>
          </g>
          <text
            fill="#212121"
            data-name="Everyday Feedback"
            fontFamily="Chalkboard"
            fontSize="105"
            letterSpacing=".25em"
            transform="translate(663.547 766.684)"
          >
            <tspan x="-663.936" y="0">
              Everyday Feedback
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};
