import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFitText } from './useFitText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      '& *': {
        fontFamily: `Muli, sans-serif !important`,
      },
      width: 433,
      height: 662,
      background: '#F8F8F8 0% 0% no-repeat padding-box',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    cardHeader: {
      height: 140,
      textAlign: 'center',
      font: 'normal normal 900 24px/30px Muli',
      color: '#FFFFFF',
      padding: 24,
      borderRadius: '10px 10px 0px 0px',
    },
    hideHeader: {
      borderRadius: '10px 10px 0px 0px',
      height: 15,
    },
    cardTitle: {
      color: '#346490',
      font: 'normal normal bold 14px/18px Muli',
      letterSpacing: '0px',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    cardSubTitle: {
      color: '#647F98',
      font: 'italic normal normal 8px/10px Muli',
      letterSpacing: '0px',
      textAlign: 'center',
    },
    cardBody: {
      backgroundColor: '#F4F8FC',
      color: '#164396',
      border: '1px solid #D9D9D9',
      flexGrow: 1,
      height: 521,
      padding: 24,
      borderRadius: '0px 0px 10px 10px',
      // font: 'normal normal normal 10px/13px Muli',
      fontFamily: 'Muli',
      '&>ul': {
        marginBottom: 0,
        listStyle: 'none',
        paddingLeft: 0,
      },
      '&>ul>li': {
        paddingLeft: 20,
        font: 'normal normal normal 15px/22px Muli',
        // fontFamily: 'Muli',
        color: '#2B2B2B',
        position: 'relative',
        '&:first-letter': {
          textTransform: 'capitalize',
        },
      },
      '&>ul>li:not(:last-child)': {
        paddingBottom: 19,
      },
      '&>ul>li::before': {
        left: 0,
        position: 'absolute',
        content: '"●"',
      },
    },
  }),
);
const QuestionCard: React.FC<{
  questionTitle: string;
  questionFeedbacks: string[];
  index: number;
  showHeader: boolean;
}> = props => {
  const colors = ['#A6D897', '#F7CF9B', '#FE9D9D'];
  const classes = useStyles({});

  return (
    <div className={classes.card}>
      <div
        className={props.showHeader ? classes.cardHeader : classes.hideHeader}
        style={{ backgroundColor: colors[props.index] }}
      >
        {props.showHeader && props.questionTitle}
      </div>
      <div className={classes.cardBody}>
        {props.questionFeedbacks && props.questionFeedbacks.length > 0 ? (
          <>
            <div
              style={{
                font: 'normal normal bold 18px/23px Muli',
                color: '#2B2B2B',
                paddingBottom: 10,
              }}
            >
              Comments:
            </div>
            <ul>
              {props.questionFeedbacks.map((feedback, index) => (
                <li key={`feedback_${index}`}>
                  {feedback.split('\n').map((f, i) => (
                    <div key={`f_${index}_${i}`}>{f}</div>
                  ))}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default QuestionCard;
