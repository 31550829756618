import axios from 'axios';
import { apiURL } from '../store/reducers/firebase';
import { useConfirmation } from '../components/Confirmation/service';

export const downloadPersonReport = async (config: {
  token: string;
  rateeId: string;
  projectId: string;
  fileName: string;
}) => {
  const { token, fileName, projectId, rateeId } = config;

  await axios
    .request({
      url: `${apiURL}/downloadPersonReport`,
      method: 'POST',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        rateeId,
        projectId,
      },
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
};
