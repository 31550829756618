import * as React from 'react';
import { Header } from '../Header/Header';
import { ReportSvg } from '../../assets/ReportSvg';
import { HeaderLabel } from '../Header/HeaderLabel';
import { GraphSvg } from '../../assets/GraphSvg';
import { Legend } from '../Legend/Legend';
import { Separator } from '../Separator/Separator';
import { StackLegendItems } from '../Legend/StackLegendItems/StackLegendItems';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from 'react-autosuggest';
import { ICompetencyScore } from '@feedr/types';
import { LegendItemProps } from '../Legend/LegendItem/LegendItem';
import { roundToFixedDecimal } from '../../../../../../../../../utils/utilities';
import { IntroductionPage } from '../IntroductionPage/IntroductionPage';
import { WebHeader } from '../../../newReportWeb/components/WebHeader/WebHeader';
import { i18n } from '@feedr/helpers';

export interface SummarySectionProps {
  competenciesWithScore: ICompetencyScore[];
  showWebHeader?: boolean;
  headerTextClassName?: string;
  legendTextClassName?: string;
  sectionClassName?: string;
}

interface CompetenciesWithScoreData {
  title: string;
  selfScore: string | number;
  raterAverage: string | number;
  groupAverage: string | number;
}

const useStyle = makeStyles((theme: Theme) => ({
  reportHighlightPage: {
    pageBreakAfter: 'always',
  },
  reportHighlightSection: {},
  summarySection: {},
  section: {
    paddingTop: 30,
  },
  legendItemsContainer: {
    pageBreakInside: 'avoid',
  },
}));

export const SummarySection: React.FC<SummarySectionProps> = props => {
  const style = useStyle();
  const {
    showWebHeader = false,
    competenciesWithScore,
    headerTextClassName,
    legendTextClassName,
    sectionClassName,
  } = props;

  const chartsLegendItems: CompetenciesWithScoreData[] = competenciesWithScore?.map(
    cws => ({
      title: cws.name,
      selfScore: roundToFixedDecimal(cws.score.selfScore),
      raterAverage: roundToFixedDecimal(cws.score.raterAverage),
      groupAverage: roundToFixedDecimal(cws.score.groupAverage),
    }),
  );

  const legendData: LegendItemProps[] = [
    {
      name: `${i18n.t('report.self')}`,
      color: `#5A7AB6`,
    },
    {
      name: `${i18n.t('report.rater_average')}`,
      color: '#D69023',
    },
    {
      name: `${i18n.t('report.group_average')}`,
      color: `#B9B9B9`,
    },
  ];

  return (
    <div
      className={`${style.reportHighlightSection} ${style.section} ${style.legendItemsContainer} ${sectionClassName}`}
    >
      {showWebHeader ? (
        <WebHeader
          icon={<ReportSvg />}
          title={i18n.t('report.summary')}
          description={i18n.t('report.summary_description')}
        />
      ) : (
        <Header
          icon={<ReportSvg />}
          title={i18n.t('report.summary')}
          description={i18n.t('report.summary_description')}
          className={`pb-4 pt-3`}
        />
      )}
      <div className={`d-flex align-items-center`}>
        <HeaderLabel
          icon={<GraphSvg />}
          title={i18n.t('report.rating_overview')}
          className={`pb-0 w-100`}
          titleClassName={`${headerTextClassName}`}
        />
        <Legend data={legendData} legendTextClassName={`${legendTextClassName}`} />
      </div>
      <Separator />
      <div className={`pt-3 d-flex flex-row flex-wrap justify-content-start`}>
        {chartsLegendItems?.map((item, key) => {
          return (
            <StackLegendItems
              key={`stack-legend-item-${key}`}
              className={`px-3 py-2`}
              title={item.title}
              data={[
                {
                  value: item.selfScore,
                  color: `#5A7AB6`,
                },
                {
                  value: item.raterAverage,
                  color: '#D69023',
                },
                {
                  value: item.groupAverage,
                  color: `#B9B9B9`,
                },
              ]}
              legendTextClassName={`${legendTextClassName}`}
            />
          );
        })}
      </div>
    </div>
  );
};
