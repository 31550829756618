import * as React from 'react';

interface ListSvgProps {
  size?: number;
}

export const ListSvg: React.FC<ListSvgProps> = props => {
  const { size = 25 } = props;
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}`}
        height={`${size}`}
        viewBox="0 0 46 44"
      >
        <g id="Group_11059" data-name="Group 11059" transform="translate(-125 -95.941)">
          <g
            id="Rectangle_6257"
            data-name="Rectangle 6257"
            transform="translate(125 95.941)"
            fill="#fff"
            stroke="#d69023"
            strokeWidth="3"
          >
            <rect width="46" height="12" rx="2" stroke="none" />
            <rect x="1.5" y="1.5" width="43" height="9" rx="0.5" fill="none" />
          </g>
          <g
            id="Rectangle_6258"
            data-name="Rectangle 6258"
            transform="translate(125 111.941)"
            fill="#fff"
            stroke="#d69023"
            strokeWidth="3"
          >
            <rect width="38" height="12" rx="2" stroke="none" />
            <rect x="1.5" y="1.5" width="35" height="9" rx="0.5" fill="none" />
          </g>
          <g
            id="Rectangle_6259"
            data-name="Rectangle 6259"
            transform="translate(125 127.941)"
            fill="#fff"
            stroke="#d69023"
            strokeWidth="3"
          >
            <rect width="29" height="12" rx="2" stroke="none" />
            <rect x="1.5" y="1.5" width="26" height="9" rx="0.5" fill="none" />
          </g>
        </g>
      </svg>
    </div>
  );
};
