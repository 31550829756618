import * as React from 'react';

interface ProfileLogoProps {
  width?: number;
}

export const ProfileLogo: React.FC<ProfileLogoProps> = props => {
  const { width = 80 } = props;
  return (
    <svg
      /*xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 1367.352 796.684"*/
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g id="Group_14620" data-name="Group 14620" transform="translate(-35 -23)">
        <g
          id="Rectangle_8944"
          data-name="Rectangle 8944"
          transform="translate(35 23)"
          fill="#f0f0f0"
          stroke="#164396"
          strokeWidth="2"
        >
          <rect width="48" height="48" rx="24" stroke="none" />
          <rect x="1" y="1" width="46" height="46" rx="23" fill="none" />
        </g>
        <g id="Group_14619" data-name="Group 14619" transform="translate(44.413 37.391)">
          <g id="users-group_1_" data-name="users-group (1)" transform="translate(7.817)">
            <path
              id="Path_9859"
              data-name="Path 9859"
              d="M118.058,223.957a4.084,4.084,0,1,0-4.085-4.084A4.084,4.084,0,0,0,118.058,223.957Zm1.732.278h-3.465a5.236,5.236,0,0,0-5.229,5.23V233.7l.011.066.292.091a23.816,23.816,0,0,0,7.11,1.147,14.592,14.592,0,0,0,6.208-1.166l.273-.138h.029v-4.238A5.234,5.234,0,0,0,119.79,224.235Z"
              transform="translate(-111.096 -215.789)"
              fill="#164396"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
