import React from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import { IQuestionResponse } from '@feedr/types';
import { OpinionHeader } from './OpinionHeader';

const useStyles = makeStyles((theme: Theme) => ({
  numbering: {
    height: '2rem',
    width: '2rem',
    borderRadius: '2rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
  },
  feedbackRoot: {
    paddingLeft: 30,
    paddingTop: 5,
  },
  feedbackBox: {
    // boxShadow: '0 10px 30px rgba(0,0,0, 0.2)',
    overflow: 'visible',
  },
  feedbackTitle: {
    backgroundImage: 'linear-gradient(to right, #0c2971, #286591, #4466b0)',
    color: theme.palette.primary.contrastText,
    padding: '0.75rem 1rem',
    borderRadius: '10px 10px 0 0',
  },
  feedbackBody: {
    padding: '0.75rem 1.5rem',
    border: '1px solid rgba(0,0,0, 0.2)',
    borderTop: 'none',
    borderRadius: '0 0 10px 10px',
  },
  dialogTrail: {
    position: 'absolute',
  },
  trail1: {
    height: '0.5rem',
    width: '0.5rem',
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
    // boxShadow: '0 5px 20px rgba(0,0,0, 0.2)',
  },
  trail2: {
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.75,
    margin: '3px 0 0 2px',
    // boxShadow: '0 5px 20px rgba(0,0,0, 0.2)',
  },
  trail3: {
    height: '1rem',
    width: '1rem',
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    margin: '-2px 0 0 12px',
    // boxShadow: '0 5px 20px rgba(0,0,0, 0.2)',
  },
  unbreakable: {
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
  },
}));

interface IOpinionsProps {
  question: IQuestionResponse;
  number: number;
  hideHeader?: boolean;
}

const Opinions: React.FC<IOpinionsProps> = ({ question, number, hideHeader }) => {
  const classes = useStyles({});

  return (
    <>
      {question.feedbacks?.length !== 0 &&
        question.feedbacks.map((feedback, idx) => (
          <Box
            key={idx}
            className={classes.unbreakable}
            mt={idx === 0 && number !== 1 ? 3 : 0}
          >
            {number === 1 && idx === 0 && !hideHeader && <OpinionHeader />}
            {idx === 0 && (
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <div className={classes.numbering}>{number}</div>
                <Typography>{question.title}</Typography>
              </Box>
            )}
            <Box position={'relative'} ml={1} mt={1}>
              <div className={classes.dialogTrail}>
                <div className={classes.trail1} />
                <div className={classes.trail2} />
                <div className={classes.trail3} />
              </div>
              <div className={classes.feedbackRoot}>
                <div className={classes.feedbackBox}>
                  <div className={classes.feedbackTitle}>Anonymous</div>
                  <div className={classes.feedbackBody}>{feedback.answer}</div>
                </div>
              </div>
            </Box>
          </Box>
        ))}
    </>
  );
};

export default Opinions;
