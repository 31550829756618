import {
  ICompetencyScore,
  IDescriptionScore,
  IFeedbackRating,
  IGroupResult,
  IProjectCompetency,
  IProjectDescription,
  IMinRecordsRequired,
} from '@feedr/types';
import * as _ from 'lodash';

type TScoreCalculator = (
  /**
   *  Array returned from firestore
   */
  flatFeedbacks: IFeedbackRating[],
  /**
   * email of the report owner
   */
  rateeEmail: string,

  competencies: IProjectCompetency[],

  descriptions: IProjectDescription[],

  nomineesByRole: {
    [role: string]: string[];
  },

  groupResult?: IGroupResult,
  minRecordsRequired?: IMinRecordsRequired[],
) => ICompetencyScore[] | null;

/**
 * * To Get:
 *
 *    * Descriptions
 *    - Self Score              - get self rated score
 *    - Managers Average        - get average score from "managers" for this ratee
 *    - Peers Average           - get average score from "peers" for this ratee
 *    - Direct Reports Average  - get average score from "direct reports" for this ratee
 *    - Others Average          - get average score from "others" for this ratee
 *    - Rater Average           - get average score from all raters (excluding self ratings) for this ratee
 *    - Ratee Average           - get average score for this ratee
 *    - Group Average           - get average score throughout the project
 *    - Group Rater Average     - get average score (excluding self ratings) throughout the project
 *
 *    * Competencies
 *    Same as above but the average of all descriptions
 *
 * * Any changes in scoring mechanism need to re-deploy cloud functions:
 *    - Group result trigger: calculateProjectScores, onPeopleInfoWrite
 *    - Talentpulse integration: addJourneyCandidates, sendCandidateCompletedWebhook, registerJourneyCandidate, getCandidateResult
 */
export const competencyScoreCalculator: TScoreCalculator = (
  flatFeedbacks,
  rateeEmail,
  competencies,
  descriptions,
  nomineesByRole,
  groupResult,
  minRecordsRequired,
) => {
  if (!flatFeedbacks) {
    return [];
  }

  return (
    competencies
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map(competency =>
        // ? Grouping descriptions under it's respective competencies
        ({
          ...competency,
          descriptionScores: competency.descriptionIds

            // ? Find the description
            .map(id => descriptions.find(description => description.id === id))
            .filter(description => description)

            // ? Adding scores to each competencies
            .map(description => {
              const feedbacks = flatFeedbacks.filter(
                feedback =>
                  feedback.descriptionId === description.id &&
                  feedback.competencyId === competency.id &&
                  feedback.rating !== '0', // invalidate N/A scores
              );
              const raterFeedbacks = feedbacks.filter(
                feedback => feedback.rater !== rateeEmail,
              );
              // const groupedData = _.groupBy(feedbacks, 'rating');
              // const avg = averageRatingCalculator(groupedData);
              const getMinByRole = (arr: IMinRecordsRequired[], role: string) => {
                if (arr && arr.length > 0) {
                  return arr.find(a => a.role === role).min;
                }
                return null;
              };
              return {
                ...description,

                /** old report design */
                // stats: {
                //   max: avg.maxValue,
                //   mean: avg.mean,
                //   frequency: avg.frequency,
                // },

                score: {
                  selfScore:
                    Number(
                      feedbacks.find(feedback => feedback.rater === rateeEmail)?.rating,
                    ) ?? null,
                  managersAverage: getAverageByRaters(
                    feedbacks,
                    nomineesByRole['Manager'],
                    getMinByRole(minRecordsRequired, 'Manager') ?? 1,
                  ),
                  peersAverage: getAverageByRaters(
                    feedbacks,
                    nomineesByRole['Peer'],
                    getMinByRole(minRecordsRequired, 'Peer') ?? 2,
                  ),
                  directReportsAverage: getAverageByRaters(
                    feedbacks,
                    nomineesByRole['Direct Report'],
                    getMinByRole(minRecordsRequired, 'Direct Report') ?? 2,
                  ),
                  othersAverage: getAverageByRaters(
                    feedbacks,
                    nomineesByRole['Others'],
                    getMinByRole(minRecordsRequired, 'Others') ?? 2,
                  ),
                  raterAverage: getGroupAverage(
                    raterFeedbacks,
                    getMinByRole(
                      minRecordsRequired,
                      'Rater / Ratee / Group Rater / Group Ratee',
                    ) ?? 2,
                  ),
                  rateeAverage: getGroupAverage(
                    feedbacks,
                    getMinByRole(
                      minRecordsRequired,
                      'Rater / Ratee / Group Rater / Group Ratee',
                    ) ?? 2,
                  ),
                  groupAverage:
                    groupResult?.competencies?.[competency.id]?.descriptions?.[
                      description.id
                    ]?.groupAverage || 0,
                  groupRaterAverage:
                    groupResult?.competencies?.[competency.id]?.descriptions?.[
                      description.id
                    ]?.groupRaterAverage || 0,
                },
                comments: feedbacks
                  .filter(feedback => feedback?.comment && feedback.comment !== '')
                  .map(feedback => ({
                    comment: feedback.comment,
                    commenter: feedback.rater,
                  })),
              };
            }),
        }),
      )

      // ? Then calculating the average score of descriptions and storing it under competencies
      .map(competency => ({
        ...competency,
        score: {
          selfScore: getCompetencyAverage(competency.descriptionScores, 'selfScore'),
          managersAverage: getCompetencyAverage(
            competency.descriptionScores,
            'managersAverage',
          ),
          peersAverage: getCompetencyAverage(
            competency.descriptionScores,
            'peersAverage',
          ),
          directReportsAverage: getCompetencyAverage(
            competency.descriptionScores,
            'directReportsAverage',
          ),
          othersAverage: getCompetencyAverage(
            competency.descriptionScores,
            'othersAverage',
          ),
          raterAverage: getCompetencyAverage(
            competency.descriptionScores,
            'raterAverage',
          ),
          rateeAverage: getCompetencyAverage(
            competency.descriptionScores,
            'rateeAverage',
          ),
          groupAverage: getCompetencyAverage(
            competency.descriptionScores,
            'groupAverage',
          ),
          groupRaterAverage: getCompetencyAverage(
            competency.descriptionScores,
            'groupRaterAverage',
          ),
        },
      }))
  );
};

const getAverage = (feedbacks: IFeedbackRating[]) => {
  return feedbacks.reduce((acc, cur) => acc + Number(cur.rating), 0) / feedbacks.length;
};

const getAverageByRaters = (
  feedbacks: IFeedbackRating[],
  raters: string[],
  minRecords = 2,
) => {
  if ((raters?.length ?? 0) === 0) {
    return null;
  }
  const filtered = feedbacks.filter(feedback => raters.indexOf(feedback.rater) > -1);
  if (filtered.length < minRecords) {
    return null;
  }

  return getAverage(filtered);
};

export const getGroupAverage = (feedbacks: IFeedbackRating[], minRecords = 2) => {
  if (feedbacks.length < minRecords) {
    return null;
  }

  return getAverage(feedbacks);
};

const getCompetencyAverage = (
  descriptions: IDescriptionScore[],
  category:
    | 'selfScore'
    | 'managersAverage'
    | 'peersAverage'
    | 'directReportsAverage'
    | 'othersAverage'
    | 'raterAverage'
    | 'rateeAverage'
    | 'groupAverage'
    | 'groupRaterAverage',
) => {
  const filteredDescriptions = descriptions.filter(
    description => description.score[category],
  );
  return (
    filteredDescriptions.reduce((acc, cur) => acc + Number(cur.score[category] || 0), 0) /
    filteredDescriptions.length
  );
};

const averageRatingCalculator = groupedData => {
  let total = 0;
  let length = 0;
  let maxValue = 0;
  let frequency = {};
  _.map(groupedData, (d, k) => {
    const subLength = d.length;
    length = length + subLength;
    total = total + Number(k) * subLength;
    if (d.length > maxValue) {
      maxValue = subLength;
    }
    frequency[k] = subLength;
  });
  const mean = Number((total / length).toFixed(2));
  return { mean, maxValue, frequency };
};
