import { createModel } from '@rematch/core';

export interface IPinState {
  projectId: string;
  passed: boolean;
}

export const pinState = createModel<IPinState>({
  state: {
    projectId: '',
    passed: true,
  },

  reducers: {
    setPin: (state, payload = {}) => ({
      ...state,
      ...payload,
    }),
  },
});
