import * as React from 'react';
import { IProjectPerson } from '@feedr/types';
import { i18n } from '@feedr/helpers';

interface ICoverageProps {
  title: string;
  person: IProjectPerson;
}

const splitTextByLength = (text: string, length: number) =>
  text.split(' ').reduce(
    (acc, cur) => {
      if (acc[acc.length - 1].length + cur.length > length) {
        acc[acc.length] = cur;
      } else {
        acc[acc.length - 1] += ` ${cur}`;
      }
      ``;
      return acc;
    },
    [''],
  );

export const CoverPage: React.FC<ICoverageProps> = props => {
  return (
    <div
      style={{ breakAfter: 'always', fontFamily: `'Quicksand', sans-serif !important` }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100vw"
        height="100vh"
        viewBox="0 0 1920.257 2720"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_1724"
              data-name="Rectangle 1724"
              width="100%"
              height="100%"
              fill="none"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            y1="0.084"
            x2="0.968"
            y2="0.786"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#4466b0" />
            <stop offset="0.483" stopColor="#60541e" />
            <stop offset="1" stopColor="#d79200" />
          </linearGradient>
        </defs>
        <g id="Group_7253" data-name="Group 7253" transform="translate(0 0)">
          <g id="Group_7251" data-name="Group 7251" transform="translate(0 0)">
            <g
              id="Group_3307"
              data-name="Group 3307"
              transform="translate(0 0)"
              clipPath="url(#clip-path)"
            >
              <path
                id="Path_5263"
                data-name="Path 5263"
                d="M0,0H1670.257V2343H0Z"
                fill="#fff"
              />
              <g
                id="Group_3297"
                data-name="Group 3297"
                transform="translate(611.074 1917.398)"
              >
                <path
                  id="Path_5104"
                  data-name="Path 5104"
                  d="M345.7,1910.06a218.543,218.543,0,0,1-155.055-64.229c-85.632-85.629-85.632-224.475,0-310.1l777.12-777.12c85.638-85.64,224.475-85.648,310.11,0,85.632,85.629,85.632,224.475,0,310.1l-777.123,777.12A218.612,218.612,0,0,1,345.7,1910.06Z"
                  transform="translate(-126.425 -694.376)"
                  fill="#d69021"
                />
              </g>
              <g
                id="Group_3298"
                data-name="Group 3298"
                transform="translate(1347.171 374.337)"
              >
                <path
                  id="Path_5105"
                  data-name="Path 5105"
                  d="M615.54,1344.4a218.551,218.551,0,0,1-155.055-64.229c-85.629-85.626-85.629-224.472,0-310.1l777.123-777.118c85.635-85.643,224.474-85.651,310.109,0,85.632,85.627,85.632,224.472,0,310.1L770.595,1280.175A218.612,218.612,0,0,1,615.54,1344.4Z"
                  transform="translate(-396.263 -128.721)"
                  fill="#d69021"
                />
              </g>
              <g
                id="Group_3299"
                data-name="Group 3299"
                transform="translate(1305.418 -352.488)"
              >
                <path
                  id="Path_5106"
                  data-name="Path 5106"
                  d="M600.236,1077.963a218.543,218.543,0,0,1-155.055-64.229c-85.632-85.627-85.632-224.472,0-310.1L1222.3-73.485c85.637-85.643,224.475-85.648,310.109,0,85.632,85.629,85.632,224.475,0,310.1L755.288,1013.734A218.607,218.607,0,0,1,600.236,1077.963Z"
                  transform="translate(-380.958 137.719)"
                  fill="#dbdbdb"
                />
              </g>
              <g
                id="Group_3300"
                data-name="Group 3300"
                transform="translate(-877.167 1590.02)"
              >
                <path
                  id="Path_5107"
                  data-name="Path 5107"
                  d="M-110.778,1790.048a218.542,218.542,0,0,1-155.055-64.229c-85.632-85.626-85.632-224.472,0-310.1L511.291,638.6c85.635-85.643,224.472-85.648,310.107,0,85.632,85.627,85.632,224.472,0,310.1L44.277,1725.819A218.618,218.618,0,0,1-110.778,1790.048Z"
                  transform="translate(330.056 -574.366)"
                  fill="#dbdbdb"
                />
              </g>
              <g
                id="Group_3301"
                data-name="Group 3301"
                transform="translate(1148.427 982.178)"
              >
                <path
                  id="Path_5108"
                  data-name="Path 5108"
                  d="M542.686,1567.224A218.544,218.544,0,0,1,387.632,1503c-85.632-85.629-85.632-224.475,0-310.1l777.123-777.12c85.635-85.64,224.472-85.648,310.107,0,85.632,85.629,85.632,224.475,0,310.1L697.741,1503A218.626,218.626,0,0,1,542.686,1567.224Z"
                  transform="translate(-323.408 -351.543)"
                  fill="#4468ad"
                />
              </g>
              <ellipse
                id="Ellipse_333"
                data-name="Ellipse 333"
                cx="151.652"
                cy="151.652"
                rx="151.652"
                ry="151.652"
                transform="translate(1133.03 1219.36)"
                fill="#ffd035"
              />
              <g
                id="Group_3302"
                data-name="Group 3302"
                transform="translate(-275.37 1467.834)"
              >
                <path
                  id="Path_5109"
                  data-name="Path 5109"
                  d="M109.829,1745.257a218.555,218.555,0,0,1-155.055-64.226c-85.632-85.629-85.632-224.475,0-310.1L731.9,593.809c85.635-85.643,224.472-85.648,310.107,0,85.632,85.629,85.632,224.475,0,310.1l-777.12,777.12A218.627,218.627,0,0,1,109.829,1745.257Z"
                  transform="translate(109.449 -529.575)"
                  fill="#4468ad"
                />
              </g>
              <ellipse
                id="Ellipse_334"
                data-name="Ellipse 334"
                cx="151.652"
                cy="151.652"
                rx="151.652"
                ry="151.652"
                transform="translate(490.375 1917.398)"
                fill="#d69021"
              />
            </g>
          </g>
          <text
            id="project_title"
            data-name="project_title"
            transform="translate(144.01 750.942)"
            fill="#d69021"
            fontSize="64"
            fontFamily={`'Quicksand', sans-serif !important`}
            fontWeight="700"
          >
            <tspan x="0" y="0">
              {props.title?.length > 28 ? (
                <>
                  {splitTextByLength(props.title, 28).map((x, i) => (
                    <tspan x="0" y={i * 100}>
                      {x}
                    </tspan>
                  ))}
                </>
              ) : (
                <tspan x="0" y="0">
                  {props.title}
                </tspan>
              )}
            </tspan>
          </text>
          <g id="Group_7252" data-name="Group 7252" transform="translate(0 227)">
            <text
              id="candidate_name"
              data-name="candidate_name"
              transform="translate(147.01 800.942)"
              fill="#4468ad"
              fontSize="100"
              fontFamily={`'Quicksand', sans-serif !important`}
              fontWeight="700"
            >
              {props.person?.name?.length > 28 ? (
                <>
                  {splitTextByLength(props.person.name, 28).map((x, i) => (
                    <tspan x="0" y={i * 100}>
                      {x}
                    </tspan>
                  ))}
                </>
              ) : (
                <tspan x="0" y="0">
                  {props.person?.name}
                </tspan>
              )}
            </text>
            <text
              id="report_validity"
              data-name="report_validity"
              transform="translate(144.344 1157.898)"
              fill="#696969"
              fontSize="19"
              fontFamily={`'Quicksand', sans-serif !important`}
            >
              <tspan x="0" y="0">
                {`${i18n.t('report.results_valid_for_12_months_p1')}`}
              </tspan>
              <tspan x="0" y="24">
                {`${i18n.t('report.results_valid_for_12_months_p2')}`}
              </tspan>
            </text>
          </g>
          <g
            id="Group_10986"
            data-name="Group 10986"
            transform="translate(-293 -220.175)"
          >
            <g id="Group_1" data-name="Group 1" transform="translate(437.611 386.175)">
              <g id="Group_10985" data-name="Group 10985" transform="translate(12.082 0)">
                <g
                  id="Group_10977"
                  data-name="Group 10977"
                  transform="translate(273.246)"
                >
                  <path
                    id="Path_8302"
                    data-name="Path 8302"
                    d="M47.94,90.085c1.618-1.627,7.242-9.323,8.878-14.459.846-2.66,2.188-6.593,2.94-9.268,2.526-8.979,5.447-16.429,7.457-25.53,1.5-6.787,1.809-13.837,3.372-20.606A55.19,55.19,0,0,1,75.937,6.146C78.852.857,85.548-2,90.81,1.625c3.668,2.531,4.693,6.3,5.008,10.323.728,9.265.483,11.476-.886,20.516-1.011,6.679-1.784,13.4-2.46,20.12a13.42,13.42,0,0,0,.8,4.967c.9,3,2.87,3.631,5.036,1.31a42.4,42.4,0,0,0,6.4-8.5c6.106-11.268,9.334-16.16,15.664-27.3,3.5-6.16,7.723-12,13.984-15.969,4.544-2.877,8.726-2.6,12.975.517,4.086,2.994,4.744,7.222,4.153,11.728-.816,6.219-3.627,11.749-6.659,17.141-6.037,10.734-7.493,15.73-13.339,26.566-1.686,3.124-3.263,6.659-1.537,11.393,2.7-1.428,5.58-2.359,7.692-4.179,5.738-4.943,6.333-4.677,11.93-9.788,7.037-6.426,12.924-14.157,21.605-18.733,3.121-1.645,6.978-2.393,10.277-.445,3.925,2.318,4.879,5.389,4.684,9.845-.234,5.362-2.751,9.919-5.922,14.109-8.285,10.946-28.243,30.112-30.068,32.315a7.13,7.13,0,0,0-1.259,4.825c.068.723,2.07,1.512,3.306,1.807a7.194,7.194,0,0,0,3.543-.239c6.325-1.877,7.276-1.667,13.554-3.7,4.717-1.528,9.359-3.285,14.058-4.871,3.154-1.065,8.271.1,9.757,2.12,1.992,2.7,1.6,6.414-.671,10.04-3.254,5.2-8.705,7.475-13.613,10.373-8.107,4.788-16.724,8.763-24.573,13.92-4.552,2.991-8.366,7.337-11.916,11.571-7.774,9.277-16.14,17.913-26.089,24.806-11.621,8.049-24.545,11.971-38.739,9.863-13.544-2.011-25.5-7.39-35.281-17.4S26.51,138.466,21.2,125.875c-4.429-10.5-8.476-21.168-12.638-31.782C6.1,87.829,3.665,81.555,1.4,75.219A24.832,24.832,0,0,1,.033,68.527C-.5,60.071,5.579,56.816,12.583,56.049c9.344-.572,14.442,5.229,18.754,12.942a128.2,128.2,0,0,1,7.606,15.717C39.533,86.082,42.479,93.782,47.94,90.085Z"
                    transform="matrix(0.985, 0.174, -0.174, 0.985, 76.439, 0)"
                    fill="#d68f23"
                  />
                  <path
                    id="Path_8303"
                    data-name="Path 8303"
                    d="M1122.7-440.851c-1.416,6.363-2.873,12.521-4.122,18.72-.492,2.444-2.2,1.952-3.695,1.9a86.192,86.192,0,0,0-31.864,4.873c-24.4,8.6-42.193,24.69-53.168,48.05-7.7,16.4-9.877,33.727-7.094,51.664,4.393,28.322,19.2,49.774,43.461,64.539,16.964,10.324,35.649,13.958,55.461,12.138a87.715,87.715,0,0,0,36.545-11.74,90.219,90.219,0,0,0,35.307-37.27c.469-.91,26.585-5.749,22.843-2.244-3.358,11.8-12.453,31.672-33.873,48.758a112.1,112.1,0,0,1-141.58-1.274c-40.867-34-51.079-88.562-30.623-133.8C1031.4-423.217,1085.987-446.891,1122.7-440.851Z"
                    transform="translate(-1000.065 462.133)"
                    fill="#2c4e8f"
                    stroke="#2c4e8f"
                    strokeWidth="7"
                  />
                </g>
                <g
                  id="Group_10978"
                  data-name="Group 10978"
                  transform="translate(0 15.124)"
                >
                  <path
                    id="Path_8304"
                    data-name="Path 8304"
                    d="M-478.546,596.19a15.632,15.632,0,0,1,15.633-15.632H-443.8c23.163,0,31.767-19.194,31.767-34.414,0-23.165-22.172-33.753-44.01-33.753-14.037,0-28.287,6.359-35.622,18.061a15.434,15.434,0,0,1-16.719,6.682h0a15.752,15.752,0,0,1-9.928-23.25c13.116-22.285,37.693-33.258,62.269-33.258,37.392,0,76.77,20.516,77.1,65.52,0,17-3.755,34.584-18.01,46a2.765,2.765,0,0,0-1,2.294,2.765,2.765,0,0,0,1.26,2.163c18.439,12.249,26.668,30.255,26.355,51.8-.33,33.419-28.127,67.175-83.719,67.175-37.736,0-60.366-16.64-71.37-38.578a17.881,17.881,0,0,1,12.836-25.577h0a12.764,12.764,0,0,1,13.993,7.633c6.083,14.255,20.624,25.416,44.541,25.416,36.73,0,53.937-20.185,52.283-39.378C-403.1,631.52-413.688,612-443.8,612h-18.941a15.806,15.806,0,0,1-15.805-15.8Z"
                    transform="translate(527.328 -480.625)"
                    fill="#2c4e8f"
                  />
                  <path
                    id="Path_8305"
                    data-name="Path 8305"
                    d="M-272.168,480.922h0a14.2,14.2,0,0,1,11.576,22.409l-40.688,57.34c17.207-5.957,40.039-4.964,56.254,3.97,25.481,14.561,38.387,41.7,38.387,68.5,0,39.708-28.127,79.417-84.382,79.417-55.924,0-84.382-39.708-84.382-79.417a85.75,85.75,0,0,1,14.23-46.326l67.58-94.851A26.308,26.308,0,0,1-272.168,480.922ZM-341.982,633.14c0,23.163,16.877,45.665,50.96,45.665,34.414,0,50.959-22.5,50.959-45.665s-16.545-46.326-50.959-46.326C-325.1,586.814-341.982,609.979-341.982,633.14Z"
                    transform="translate(506.578 -480.592)"
                    fill="#2c4e8f"
                  />
                </g>
              </g>
              <text
                id="Everyday_Feedback"
                data-name="Everyday Feedback"
                transform="translate(0 308.477)"
                fill="#212121"
                fontSize="41"
                fontFamily={`'Quicksand', sans-serif !important`}
                letterSpacing="0.25em"
              >
                <tspan x="0" y="0">
                  Everyday Feedback
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
