import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<{}, IErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // TODO log errors to firestore
    // logErrorToMyService(error, info);
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          // ? Can't use useStyles inside a class
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            margin: 16,
          }}
        >
          <Typography variant={'h6'}>Something went wrong.</Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
